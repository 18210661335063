import React,{Component} from 'react';
import {ArticleCard} from './ArticleCard';
import {ImgCard} from './ImgCard';
import {TimeLineCard} from './TimeLineCard';
import ReactLoading from 'react-loading';
import {Modal} from './Modal';
import SwiperCore,{Navigation,Pagination,Scrollbar,A11y} from 'swiper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Swiper,SwiperSlide} from 'swiper/react';
import NumberFormat from 'react-number-format';
import {DragDropContainer,DropTarget} from 'react-drag-drop-container';
import moment from 'moment';
import DatePicker from "react-datepicker";
import {Header,Detail} from "./ProgramViewer"
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/th';
import SunEditor,{buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {PlaceViewer} from './PlaceViewer';
import {CountryViewer} from './CountryViewer';
import {CardPackage} from './CardPackage';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// install Swiper components
SwiperCore.use([Navigation,Pagination,Scrollbar,A11y]);

var HtmlToReactParser = require('html-to-react').Parser;

var htmlInput = '<div><h1>Title</h1><p>A paragraph</p></div>';
var htmlToReactParser = new HtmlToReactParser();
var reactElement = htmlToReactParser.parse(htmlInput);
let lastScrollY = 0; 
let ticking = false;
moment().locale('th');
export class ProgramManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            stateAdd: 0,
            PageState: "L",
            tourlist: [],
            data: {},
            loading: true,
            navposition: 1,
            windowWidth: window.innerWidth,
            id: 0,
            timesdetail: [],
            meals: [],
            properties: [],
            prices: [],
            hotels: [],
            countries: [],
            propertiesList: [],
            prop_include: [],
            prop_uninclude: [],
            continentList: [],
            countryList: [],
            provinceList: [],
            provinceSearchList: [],
            airlineList: [],
            catagoryList: [],
            hotelList: [],
            countriesList: [],
            images: [],
            images2: [],
            mdTitle: "ยืนยัน",
            mdMessage: "ต้องการบันทึกใช้หรือไม่",
            mdIcon: "confirm",
            s_country_id: 0,
            s_province_id: 0,
            s_name: "",
            s_code: "",
            edit: {index: 999,subindex: 999}
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.SaveData = this.SaveData.bind(this);
        this._handleImageChange = this._handleImageChange.bind(this);
        this.offsetsDiv = this.offsetsDiv.bind(this);
        this.goTo = this.goTo.bind(this);
        this.GetData = this.GetData.bind(this);
        this.handle_drop = this.handle_drop.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll',this.handleScroll,true);
        window.addEventListener("resize",this.handleResize);
        //  const queryString = require('query-string');
        // const parsed = queryString.parse(window.location.search);
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id'); // bar
        this.setState({id: id});
        this.GetCountry();
        this.GetAirline();
        this.GetCatagory();
        this.GetProperties();
        // if (id) {
        //     this.GetData(id);
        //     this.GetImages(id);
        //     this.GetImages2(id);
        // }
        const {s_country_id,s_province_id,s_code,s_name} = this.state;
        this.GetDataList(s_code,s_name,s_country_id,s_province_id);

    }
    GetData(id) {
        window.scrollTo(0,0)
        this.setState({loading: true});
        fetch('api/ProgramById?id=' + id,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({data: json[0],coverdisplay:""},
                            () => {
                                if (json[0].countrY_ID > 0) {
                                    this.GetProvince(json[0].countrY_ID);
                                }

                                fetch('api/GetCountries?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                if (resp_json.length == 0) {
                                                    this.setState({
                                                        countries: [
                                                            {
                                                                id: 0,
                                                                continent: 0,
                                                                continent_name: "",
                                                                country: 0,
                                                                country_name: "",
                                                                province: 0,
                                                                province_name: ""
                                                            }
                                                        ]
                                                    });
                                                }
                                                else {
                                                    this.GetHotel(resp_json.filter(f => (!f.isdelete || f.isdelete == 0) && f.province == 0).map(m => m.country).join(','),resp_json.filter(f => (!f.isdelete || f.isdelete == 0) && f.province > 0).map(m => m.province).join(','));
                                                    this.setState({countries: resp_json});
                                                }
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });
                                fetch('api/GetTimesDetail?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({timesdetail: resp_json});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });

                                fetch('api/GetMeals?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({meals: resp_json});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });

                                fetch('api/GetHotels?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({hotels: resp_json});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });

                                fetch('api/GetProperties?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({properties: resp_json,prop_include: resp_json.filter(({include}) => include == 1),prop_uninclude: resp_json.filter(({include}) => include == 0)});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });

                                fetch('api/GetPrices?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {

                                                this.setState({prices: resp_json});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });
                                setTimeout(this.setState({loading: false}),3000);

                            }

                        );
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                        this.setState({loading: false});
                    });
                }
            }.bind(this))
            .catch(function (error) {
                this.setState({loading: false});
                console.log('Request failed',error);
            });
        // const response = await fetch('api/program');
        // const data = await response.json();
        // this.setState({ data: data, loading: false });
    }
    GetDataList(code,name,country_id,province_id) {
        this.setState({loading: true});
        fetch(`api/ProgramList?country_id=${country_id}&province_id=${province_id}&code=${code}&name=${name}`,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({tourlist: json,loading: false,PageState: "L"});
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetImages(id) {

        fetch('api/ProgramImages?id=' + id + '&type=1',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({images: json});
                    }.bind(this))
                }
                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });

    }
    GetImages2(id) {

        fetch('api/ProgramImages?id=' + id + '&type=2',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({images2: json});
                    }.bind(this))
                }
                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetContinent() {
        fetch('DataMng/GetDataLookup?LookupType=CONTINANT',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({continentList: json.data}
                        );

                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                        this.setState({loading: false});
                    });
                }
            }.bind(this))
            .catch(function (error) {
                this.setState({loading: false});
                console.log('Request failed',error);
            });
    }
    GetCountry() {
        fetch('api/GetCountryData',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({countryList: json});
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetAirline() {
        fetch('api/GetAirlinelist',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({airlineList: json});
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetCatagory() {
        fetch('api/GetTourCatagory',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({catagoryList: json});
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetProperties() {
        fetch('api/GetPropertiesList',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({propertiesList: json});
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetProvince(id,search) {
        fetch('api/GetProvincelist?countryid=' + id,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        if (search) {
                            this.setState({provinceSearchList: json});
                        }
                        else {
                            this.setState({provinceList: json});
                        }
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetHotel(id,province_id,district_id) {
        fetch(`api/GetHotelslistByCountry?country_id=${id}${province_id ? ('&province_id=' + province_id) : ''}&district_id=${district_id ? ('&district_id=' + district_id) : ''}`,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({hotelList: json});
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    componentWillUnmount() {
        window.removeEventListener('scroll',this.handleScroll);
        window.addEventListener("resize",this.handleResize);
    }
    offsetsDiv(el) {
        if (this.state.PageState != "L") {
            if (el) {
                var rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return {top: rect.top + scrollTop,left: rect.left + scrollLeft}
            }
            else {
                return {top: 0,left: 0}
            }
        }
    }
    handleResize = (e) => {
        this.setState({windowWidth: window.innerWidth});
    };
    handleChange(e) {
        var schema = {...this.state}
        var pList = e.currentTarget.name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        schema[pList[len - 1]] = e.currentTarget.value;
        this.setState(schema)

    }
    ChangeRate(name,value) {
        var schema = {...this.state}
        var pList = name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        schema[pList[len - 1]] = value;
        this.setState(schema)
    }
    OnChangeinTimeDetail(e,index) {

        var schema = {...this.state}
        var pList = e.currentTarget.name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        schema.timesdetail[index][pList[len - 1]] = e.currentTarget.value;
        schema.timesdetail[index].isupdate = 1;
        this.setState(schema)
    }
    OnChangeinDetail(e,index,subindex,t,n,desc,i,c_id,c_name,ct_id,ct_name,p_id,p_name,d_id,d_name) {
        var schema = {...this.state}
        if (!t || t == 1) {
            schema.timesdetail[index].timeDetails[subindex].detail = e;
            schema.timesdetail[index].isupdate = 1;
            schema.timesdetail[index].timeDetails[subindex].isupdate = 1;
            schema.timesdetail[index].timeDetails[subindex].type = 1;
        } else {
            schema.timesdetail[index].timeDetails[subindex].place = e;
            schema.timesdetail[index].timeDetails[subindex].continent = c_id;
            schema.timesdetail[index].timeDetails[subindex].continent_name = c_name;
            schema.timesdetail[index].timeDetails[subindex].country = ct_id;
            schema.timesdetail[index].timeDetails[subindex].country_nam = ct_name;
            schema.timesdetail[index].timeDetails[subindex].province = p_id;
            schema.timesdetail[index].timeDetails[subindex].province_name = p_name;
            schema.timesdetail[index].timeDetails[subindex].district = d_id;
            schema.timesdetail[index].timeDetails[subindex].district_name = d_name;
            schema.timesdetail[index].isupdate = 1;
            schema.timesdetail[index].timeDetails[subindex].isupdate = 1;
            schema.timesdetail[index].timeDetails[subindex].type = 2;
            schema.timesdetail[index].timeDetails[subindex].place_name = n;
            schema.timesdetail[index].timeDetails[subindex].place_desc = desc;
            schema.timesdetail[index].timeDetails[subindex].img = i;
        }
        this.setState(schema)
    }
    OnSetDetailType(index,subindex,t) {

        var schema = {...this.state}
        schema.timesdetail[index].timeDetails[subindex].type = t;
        this.setState(schema)
    }

    handle_drop(data,index,subindex) {
        if (data.index != index || data.subindex != subindex) {
            var schema = {...this.state}
            var d = schema.timesdetail[data.index].timeDetails[data.subindex];
            schema.timesdetail[data.index].timeDetails.splice(data.subindex,1);
            schema.timesdetail[index].timeDetails.splice(subindex,0,d);
            schema.timesdetail[data.index].isupdate = 1;
            for (var i = 0; i <= schema.timesdetail[data.index].timeDetails.length - 1; i++) {
                schema.timesdetail[data.index].timeDetails[i].index = i;
                schema.timesdetail[data.index].timeDetails[i].isupdate = 1;
            }
            if (data.index != index) {
                schema.timesdetail[index].isupdate = 1;
                for (var i = 0; i <= schema.timesdetail[index].timeDetails.length - 1; i++) {
                    schema.timesdetail[index].timeDetails[i].index = i;
                    schema.timesdetail[index].timeDetails[i].isupdate = 1;
                }
            }
            this.setState(schema);
            console.log(schema.timesdetail);
        }
    }
    OnChangeMeal(e,index) {

        var schema = {...this.state}
        var pList = e.currentTarget.name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        schema.meals[index][pList[len - 1]] = e.currentTarget.value;
        schema.meals[index].isupdate = 1;
        this.setState(schema)
    }
    OnChangeMealInDay(meal,index,v) {

        var schema = {...this.state}
        schema.meals[index][meal] = v;
        schema.meals[index].isupdate = 1;
        this.setState(schema)
    }
    OnChangeHotel(e,index) {
        var schema = {...this.state}
        if (e.currentTarget.name == "hotel_id") {
            schema.hotels[index].hotel_id = e.currentTarget.value;
            var targetindex = e.nativeEvent.target.selectedIndex;
            schema.hotels[index].name = e.nativeEvent.target[targetindex].text;
            schema.hotels[index].rate = schema.hotelList[targetindex - 1].rate;
        }
        else {
            schema.hotels[index][e.currentTarget.name] = e.currentTarget.value
        }

        schema.hotels[index].isupdate = 1;
        this.setState(schema)
    }
    OnChangeCountries(index,co,co_name,c,c_name,p,p_name,d,d_name) {
        var schema = {...this.state}

        schema.countries[index].continent = co;
        schema.countries[index].country = c;
        schema.countries[index].province = p;
        schema.countries[index].district = d;
        schema.countries[index].continent_name = co_name;
        schema.countries[index].country_name = c_name;
        schema.countries[index].province_name = p_name;
        schema.countries[index].district_name = d_name;
        schema.countries[index].isupdate = 1;
        console.log(schema.countries);
        this.setState(schema,this.GetHotel(schema.countries.filter(f => (!f.isdelete || f.isdelete == 0) && f.province == 0).map(m => m.country).join(','),schema.countries.filter(f => (!f.isdelete || f.isdelete == 0) && f.province > 0).map(m => m.province).join(',')))
    }
    OnChangePrice(e,index) {
        var schema = {...this.state}
        schema.prices[index][e.currentTarget.name] = e.currentTarget.value

        schema.prices[index].isupdate = 1;
        this.setState(schema)
    }
    OnChangeDatePrice(e,name,index) {
        var schema = {...this.state}
        schema.prices[index][name] = moment(e).format("yyyy-MM-DD");
        schema.prices[index].isupdate = 1;
        this.setState(schema)
    }
    handleChangeImgInfo(e,id,type) {
        var schema = {...this.state}
        schema[type == 1 ? "images" : "images2"][id][e.currentTarget.name] = e.currentTarget.value
        schema[type == 1 ? "images" : "images2"][id].isupdate = 1;
        this.setState(schema)
    }
    OnchangeProperties(id,name,icon) {
        var schema = {...this.state}
        let prop = schema.properties.find(f => f.prop_id == id)
        if (prop) {
            if (prop.include == 1) {
                schema.prop_include.splice(schema.prop_include.indexOf(prop),1);
                schema.prop_uninclude.push(prop);
                prop.include = 0;
                prop.isupdate = 1;
            }
            else if (prop.include == 3) {
                prop.include = 1;
                prop.isupdate = 1;
                schema.prop_include.push(prop);
            }
            else {
                if (prop.id == 0) {
                    schema.properties.splice(schema.properties.indexOf(prop),1);
                    schema.prop_uninclude.splice(schema.prop_uninclude.indexOf(prop),1);
                }
                else {
                    schema.properties[schema.properties.indexOf(prop)].include = 3;
                    schema.prop_uninclude.splice(schema.prop_uninclude.indexOf(prop),1);
                }
            }
        }
        else {
            schema.properties.push({id: 0,prop_id: id,name: name,icon: icon,include: 1})
            schema.prop_include.push({id: 0,prop_id: id,name: name,icon: icon,include: 1})
        }
        this.setState(schema)
    }
    OnSetDeleteDetail(index,subindex,v) {

        var schema = {...this.state}
        schema.timesdetail[index].timeDetails[subindex].isdelete = v;
        schema.timesdetail[index].isupdate = 1;
        this.setState(schema)
    }
    OnSetDeleteDay(index,v) {

        var schema = {...this.state}
        schema.timesdetail[index].isdelete = v;
        this.setState(schema)
    }
    handleChangeAirline(e) {
        var schema = {...this.state}
        schema.data.airL_ID = e.currentTarget.value;
        var index = e.nativeEvent.target.selectedIndex;
        schema.data.airL_NAME = e.nativeEvent.target[index].text;
        this.setState(schema)
    }
    handleChangeCatagory(e) {
        var schema = {...this.state}
        schema.data.touR_CAT_ID = e.currentTarget.value;
        var index = e.nativeEvent.target.selectedIndex;
        schema.data.touR_CAT_NAME = e.nativeEvent.target[index].text;
        this.setState(schema)
    }
    handleChangeProvince(e) {

        var schema = {...this.state}
        schema.data.provincE_ID = e.currentTarget.value;
        var index = e.nativeEvent.target.selectedIndex;
        schema.data.provincE_NAME = e.nativeEvent.target[index].text;
        this.setState(schema)
    }
    ChooseData(e,id) {
        e.preventDefault();
        this.GetData(id);
        this.GetImages(id);
        this.GetImages2(id);
        this.setState({PageState: "E",cover: false,preview: false})
    }
    NewData(e) {
        e.preventDefault();
        this.setState({
            stateAdd: 0,
            PageState: "N",
            cover: false,
            preview: false,
            data: {},
            // loading: true,
            navposition: 1,
            windowWidth: window.innerWidth,
            id: 0,
            timesdetail: [],
            countries: [{
                id: 0,
                continent: 0,
                continent_name: "",
                country: 0,
                country_name: "",
                province: 0,
                province_name: ""
            }],
            meals: [],
            properties: [],
            prices: [],
            hotels: [],
            prop_include: [],
            prop_uninclude: [],
            images: [],
            images2: []
        })
    }
    SaveProgram() {

        var schema = {...this.state};
        schema.popup = false;
        var data = JSON.stringify({
            preview: false,
            data: {
                id: schema.data.proG_ID,
                code: schema.data.proG_CODE,
                name: schema.data.proG_NAME,
                caption: schema.data.proG_CAPTION,
                season: schema.data.proG_SEASON,
                start_price: schema.data.proG_START_PRICE,
                country_id: schema.data.countrY_ID,
                province_id: schema.data.provincE_ID,
                place: schema.data.proG_PLACE,
                days: schema.data.proG_DAYS,
                night: schema.data.proG_NIGHT,
                month: schema.data.proG_MONTH,
                airline_id: schema.data.airL_ID,
                catagory: schema.data.touR_CAT_ID,
                total_meal: schema.data.proG_TOTAL_MEAL,
                total_hotel_rate: schema.data.proG_TOTAL_HOTEL_RATE,
                total_flight_rate: schema.data.proG_TOTAL_FLIGHT_RATE,
                total_place: schema.data.proG_TOTAL_PLACE,
                total_activity: schema.data.proG_TOTAL_ACTIVITY,
                total_shopping: schema.data.proG_TOTAL_SHOPPING,
                total_freeday: schema.data.proG_TOTAL_FREEDAYS,
                condition: schema.data.proG_CONDITION
            }
            ,meals: schema.meals,
            prices: schema.prices,
            properties: schema.properties,
            timesdetail: schema.timesdetail,
            hotels: schema.hotels,
            countries: schema.countries
        });
        fetch('api/ManagementProgram',{
            method: 'post',
            body: data,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {

                        if (json.id == 0) {
                            if (schema.coverpath) {
                                var formData = new FormData();
                                formData.append("image",schema.coverpath);
                                formData.append("id",json.desc);
                                formData.append("oldpath",schema.data.proG_COVER_IMG);
                                fetch('upload/Programcoverimage',{
                                    method: 'POST',
                                    body: formData,
                                    headers: {
                                    }
                                })
                                    .then(function (response) {
                                        if (response.ok) {
                                            this.GetData(json.desc);
                                        }
                                        else {
                                            response.text().then(function (text) {
                                                console.log('error ',text);
                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });
                            }
                            else {
                                this.GetData(json.desc);
                            }
                            if(schema.pdfFile){
                                var formData = new FormData();
                                formData.append("image",schema.pdfFile);
                                formData.append("id",json.desc);
                                formData.append("oldpath",schema.data.proG_PDF_FILE);
                                fetch('upload/ProgramPDFfile',{
                                    method: 'POST',
                                    body: formData,
                                    headers: {
                                    }
                                })
                                    .then(function (response) {
                                        if (response.ok) {
                                            this.GetData(json.desc);
                                        }
                                        else {
                                            response.text().then(function (text) {
                                                console.log('error ',text);
                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });
                            }
                            schema.data.proG_ID = json.desc;
                            this.setState(schema);
                            var i = 0;
                            var l = 0;
                            if (schema.images.filter(f => (f.id == 0 && (!f.isdelete || f.isdelete != 1)) || (f.isupdate == 1 && (!f.isdelete || f.isdelete != 1)) || f.isdelete == 1).length > 0) {
                                schema.images.map((img,index) => {
                                    l++;
                                    if (img.isdelete != 1) {
                                        i++;
                                    }
                                    var formData = new FormData();
                                    formData.append("image",img.path);
                                    formData.append("id",img.id);
                                    formData.append("type","1");
                                    formData.append("name",img.name);
                                    formData.append("desc",img.desc);
                                    formData.append("path",img.path);
                                    formData.append("isdelete",img.isdelete);
                                    formData.append("isupdate",1);
                                    formData.append("prog_id",json.desc);
                                    formData.append("index",i);
                                    formData.append("row",l);
                                    fetch('upload/Programimage',{
                                        method: 'POST',
                                        body: formData,
                                        headers: {
                                        }
                                    })
                                        .then(function (response) {
                                            if (response.ok) {

                                                response.json().then(function (j) {
                                                    if (j.row == schema.images.length) {
                                                        this.GetImages(json.desc);
                                                    }
                                                }.bind(this));
                                            }
                                            else {
                                                response.text().then(function (text) {
                                                    console.log('error ',text);
                                                });
                                            }
                                        }.bind(this))
                                        .catch(function (error) {
                                            console.log('Request failed',error);
                                        });
                                });
                            }
                            var ii = 0;
                            var ll = 0;
                            if (schema.images2.filter(f => (f.id == 0 && (!f.isdelete || f.isdelete != 1)) || (f.isupdate == 1 && (!f.isdelete || f.isdelete != 1)) || f.isdelete == 1).length > 0) {
                                schema.images2.map((img,index) => {
                                    ll++;
                                    if (img.isdelete != 1) {
                                        ii++;
                                    }
                                    var formData = new FormData();
                                    formData.append("image",img.path);
                                    formData.append("id",img.id);
                                    formData.append("type","2");
                                    formData.append("name",img.name);
                                    formData.append("desc",img.desc);
                                    formData.append("path",img.path);
                                    formData.append("isdelete",img.isdelete);
                                    formData.append("isupdate",1);
                                    formData.append("prog_id",json.desc);
                                    formData.append("index",ii);
                                    formData.append("row",ll);
                                    fetch('upload/Programimage',{
                                        method: 'POST',
                                        body: formData,
                                        headers: {
                                        }
                                    })
                                        .then(function (response) {
                                            if (response.ok) {
                                                response.json().then(function (j) {
                                                    if (j.row == schema.images2.length) {
                                                        this.GetImages2(json.desc);
                                                    }
                                                }.bind(this));
                                            }
                                            else {
                                                response.text().then(function (text) {
                                                    console.log('error ',text);

                                                });
                                            }
                                        }.bind(this))
                                        .catch(function (error) {
                                            console.log('Request failed',error);
                                        });
                                });
                            }
                            this.setState({popupsuccess: true});
                        }
                        if(json.id ==1){
                            this.setState({popupdanger: true,mdErrorMessage: "บันทึกข้อมูลไม่สำเร็จรหัสนี้มีการใช้งานแล้ว"})
                        }
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });

    }
    SaveDelete() {

        var schema = {...this.state};
        schema.popup = false;
        var data = JSON.stringify({
            preview: false,
            delete:true,
            data: {
                id: schema.data.proG_ID,
            }
         
        });
        fetch('api/ManagementProgram',{
            method: 'post',
            body: data,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {

                        if (json.id == 0) {
                            this.setState({popupsuccess: true});
                        }
                    }.bind(this))
                }
                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });

    }
    SaveData(stateadd) {
        if (stateadd == 1) {
            if (this.state.addCountry) {
                fetch('DataMng/AddCountry?name=' + this.state.addCountry,{
                    method: 'get',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                    .then(function (resp) {
                        if (resp.ok) {
                            resp.json().then(function (resp_json) {
                                if (resp_json.id == 0) {
                                    this.setState({popupsuccess: true,stateAdd: 0},this.GetCountry());
                                }

                            }.bind(this))
                        }

                        else {
                            resp.text().then(function (text) {
                                console.log('error ',text);

                            });
                        }
                    }.bind(this))
                    .catch(function (error) {
                        console.log('Request failed',error);
                    });
            }
        }
        if (stateadd == 2 && this.state.data.countrY_ID) {
            if (this.state.addProvince) {
                fetch('DataMng/AddProvince?coun_id=' + this.state.data.countrY_ID + '&name=' + this.state.addProvince,{
                    method: 'get',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                    .then(function (resp) {
                        if (resp.ok) {
                            resp.json().then(function (resp_json) {
                                var coun_id = this.state.data.countrY_ID;
                                this.setState({popupsuccess: true,stateAdd: 0},this.GetProvince(coun_id));
                            }.bind(this))
                        }

                        else {
                            resp.text().then(function (text) {
                                console.log('error ',text);

                            });
                        }
                    }.bind(this))
                    .catch(function (error) {
                        console.log('Request failed',error);
                    });
            }
        }
        else if (stateadd == 2 && !this.state.data.countrY_ID) {
            this.setState({popupdanger: true,mdErrorMessage: "กรุณาระบุประเทศที่ต้องการเพิ่มจังหวัดก่อน"})
        }
        if (stateadd == 0) {
            this.SaveProgram();
        }
        if (stateadd == 3) {
            this.SaveDelete();
        }
        

    }
    handleScroll = () => {
        if (this.state.data && this.state.PageState != "L") {
            lastScrollY = window.scrollY;
            var highlight = this.offsetsDiv(document.getElementById("highlight")).top;
            var information = this.offsetsDiv(document.getElementById("information")).top;
            var benefits = this.offsetsDiv(document.getElementById("benefits")).top;
            var period = this.offsetsDiv(document.getElementById("period")).top;
            var nev = 200;
            if (lastScrollY < information - nev) {
                this.setState({navposition: 1});
            }
            else if (lastScrollY > information - nev && lastScrollY < benefits - nev) {
                this.setState({navposition: 2});
            }
            else if (lastScrollY > benefits - nev && lastScrollY < period - nev) {
                this.setState({navposition: 3});
            }
            else if (lastScrollY > period - nev) {
                this.setState({navposition: 4});
            }
        }
    }
    _handleImageChange(e) {
        e.preventDefault();
        let ID = this.props.value && this.props.value.length !== undefined ? this.props.value.length : 0;
        let reader = new FileReader();
        let file = e.target.files[0];
        let status = 1;
        let f = new Array()

        reader.onloadend = () => {
            if (reader.result) {
                var schema = {...this.state}

                if (schema.imgtype == 3) {
                    schema.coverdisplay = reader.result;
                    schema.coverpath = file;
                }
                if(schema.imgtype==4){
                    schema.pdfFile = file;
                }
                else {
                    if (schema.imgid != -1) {

                        schema[schema.imgtype == 1 ? "images" : "images2"][schema.imgid].display = reader.result;
                        schema[schema.imgtype == 1 ? "images" : "images2"][schema.imgid].path = file;
                        schema[schema.imgtype == 1 ? "images" : "images2"][schema.imgid].isupdate = 1;
                    }
                    else {
                        schema[schema.imgtype == 1 ? "images" : "images2"].push({id: 0,path: file,display: reader.result,name: "",desc: "",index: 0});
                    }
                }
                this.setState(schema);
            }
        }
        if (file) {
            reader.readAsDataURL(file)
        }

    }
    _handleSubmit(e,id,type) {
        e.preventDefault();
        // TODO: do something with -> this.state.file
        this.setState({imgid: id,imgtype: type},this.upload.click());


    }
    _handleImgDelete(e,id,type) {
        e.preventDefault();
        var schema = {...this.state}
        if (schema[type == 1 ? "images" : "images2"][id].id == 0) {
            schema[type == 1 ? "images" : "images2"].splice(id,1);
        } else {
            schema[type == 1 ? "images" : "images2"][id].isdelete = 1
        }

        this.setState(schema)
    }
    AddDayClick(e) {
        e.preventDefault();
        var schema = {...this.state}
        schema.timesdetail.push({days: 0,id: 0,places: "",timeDetails: [{id: 0,times: 1,detail: ""}]});
        this.setState(schema)
    }
    AddTimeClick(e,i) {
        e.preventDefault();
        var schema = {...this.state}
        schema.timesdetail[i].timeDetails.push({id: 0,times: 1,detail: ""});
        this.setState(schema)
    }
    AddDayMealClick(e) {
        e.preventDefault();
        var schema = {...this.state}
        schema.meals.push({days: 0,id: 0,breakfast: 0,lunch: 0,dinner: 0});
        this.setState(schema)
    }
    OnSetDeleteMeal(index,v) {

        var schema = {...this.state}
        schema.meals[index].isdelete = v;
        this.setState(schema)
    }
    OnSetDeleteHotel(index,v) {

        var schema = {...this.state}
        schema.hotels[index].isdelete = v;
        this.setState(schema)
    }
    OnSetDeleteCountry(index,v) {

        var schema = {...this.state}
        schema.countries[index].isdelete = v;
        this.setState(schema)
    }
    OnSetDeletePrice(index,v) {

        var schema = {...this.state}
        schema.prices[index].isdelete = v;
        this.setState(schema)
    }
    OnAddData(type) {

        if (type == 1) {
            this.setState({stateAdd: 1,addCountry: ""});
        }
        if (type == 2) {
            this.setState({stateAdd: 2,addProvince: ""});
        }
    }
    OnSaveData(type) {

        this.setState({popup: true,stateAdd: type,mdMessage: "ต้องการบันทึกใช่หรือไม่?",mdTitle: "ยืนยัน",mdIcon: "confirm"})
    }
    OnSaveDelete(type) {

        this.setState({popup: true,stateAdd: 3,mdMessage: "ต้องการลบโปรแกรมใช่หรือไม่?",mdTitle: "ยืนยัน",mdIcon: "confirm"})
    }
    OnCancelData(type) {
        this.setState({stateAdd: 0});
    }
    AddDayHotelClick(e) {
        e.preventDefault();
        var schema = {...this.state}
        schema.hotels.push({
            days: 1,
            desc: "",
            hotel_id: 0,
            id: 0,
            name: "",
            rate: 0
        });
        this.setState(schema)
    }
    AddCountryClick(e) {
        e.preventDefault();
        var schema = {...this.state}
        schema.countries.push({
            id: 0,
            continent: 0,
            continent_name: "",
            country: 0,
            country_name: "",
            province: 0,
            province_name: "",
            district: 0,
            district_name: ""
        });
        this.setState(schema)
    }
    AddPricesClick(e) {
        e.preventDefault();
        var schema = {...this.state}
        schema.prices.push({
            adult_price: "0",
            children_price: "0",
            single_bedded: "0",
            children_not_bed: "0",
            adult_price_noticket: "0",
            children_price_noticket: "0",
            children_not_bed_noticket: "0",
            single_bedded_noticket: "0",
            limit: "0",
            book: "0",
            description: "",
            end: new Date(),
            id: 0,
            start: new Date()
        });
        this.setState(schema)
    }
    goTo(event,e) {
        var elmnt = document.getElementById(e);
        elmnt.scrollIntoView(200);
        event.preventDefault();
    }
    handleChangeSearch(e) {
        var schema = {...this.state}
        var pList = e.currentTarget.name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        schema[pList[len - 1]] = e.currentTarget.value;
        if (e.currentTarget.name == "s_country_id") {
            this.setState({provinceSearchList: []});

            if (e.currentTarget.value > 0) {
                this.GetProvince(e.currentTarget.value,true);
            }
        }
        this.setState(schema,() => {
            const {s_country_id,s_province_id,s_code,s_name} = this.state;
            this.GetDataList(s_code,s_name,s_country_id,s_province_id);
        });

    }
    render() {
        const {windowWidth,timesdetail,meals,hotels,properties,propertiesList,prices,prop_include,prop_uninclude,images,images2,preview,cover,coverdisplay,tourlist,PageState,addCountry,addProvince,stateAdd,s_code,s_name,s_country_id,s_province_id,loading,edit,countries} = this.state;
        let data = this.state.data;

        const pg_list = <div style={{minHeight: "100vh"}} className="pg_tours-detail">
            <section className="panal-package-list">

                <div className="panal-search-tool">
                    <div className="row align-items-center">
                        <div className="col-1">
                            <div className="input-group">
                                <input type="text" id="txt_search_code" className="form-control pg_input" placeholder="CODE" value={s_code ? s_code : ""} name="s_code" onChange={e => this.handleChangeSearch(e)} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="input-group">
                                <input type="text" id="txt_search_name" className="form-control pg_input" placeholder="NAME" value={s_name ? s_name : ""} name="s_name" onChange={e => this.handleChangeSearch(e)} />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="input-group">
                                <select id="ddl_search_country" value={s_country_id ? s_country_id : "0"} name="s_country_id" onChange={e => this.handleChangeSearch(e)} className="form-control pg_input">
                                    <option key={0} value={"0"} >Search by Country</option>
                                    {
                                        this.state.countryList.map((item) => (
                                            <option key={item.id} value={item.id} >{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div></div>
                        <div className="col-2">
                            <div className="input-group">
                                <select id="ddl_search_province" value={s_province_id ? s_province_id : "0"} name="s_province_id" onChange={e => this.handleChangeSearch(e)} className="form-control pg_input">
                                    <option key={0} value={"0"} >Search by Province</option>
                                    {
                                        this.state.provinceSearchList.map((item) => (
                                            <option key={item.id} value={item.id} >{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-2">
                            <button data-v-0348ea44="" onClick={e => this.NewData(e)} className="pg_button--color-secondary pg_button--size-md is-type-text">
                                <span className="pg_button__ripple-container"></span>
                                <span className="pg_button__container">
                                    <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-plus" ></i></span>
                                    <span className="pg_button__text">
                                        เพิ่ม
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="panal-list">

                    {

                        !loading ?
                            tourlist.map((p,index) =>
                                <CardPackage key={index} onClick={(e) => this.ChooseData(e,p.proG_ID)}
                                    id={p.proG_ID}
                                    code={p.proG_CODE}
                                    img={p.proG_COVER_IMG ? p.proG_COVER_IMG : "https://antstoragedata.blob.core.windows.net/webimage/importClick.png"}
                                    name={p.proG_CAPTION}
                                    start={p.sooN_START ? moment(p.sooN_START).format("Do MMM YY") : ""}
                                    end={p.sooN_END ? moment(p.sooN_END).format("Do MMM YY") : ""}
                                    pricestart={p.proG_START_PRICE ? p.proG_START_PRICE : ""}
                                    hotelscore={p.proG_TOTAL_HOTEL_RATE}
                                    airlineimg={p.airL_LOGO.replace("/Airline","/Airline/icon")} />

                            ) : (<div style={{position: "absolute",width: "100%",zIndex: "100",textAlign: "center"}}> <ReactLoading type={"spin"} color={"#bb2d24"} height={100} width={100} className="loadding"></ReactLoading></div>)
                    }
                </div>
            </section>
        </div>
        const content =
            <div className="pg_tours-detail">
                {
                    loading ? <div style={{position: "absolute",width: "100%",zIndex: "100",textAlign: "center",height: "100%",background: "#f2f1ed"}}> <ReactLoading type={"bars"} color={"#bb2d24"} height={100} width={100} className="loadding"></ReactLoading></div>
                        : <div></div>
                }
                <Modal icon={this.state.mdIcon} show={this.state.popup} title={this.state.mdTitle} message={this.state.mdMessage} onClickCancel={e => {this.setState({popup: false})}} onClickConfirm={e => {this.setState({popup: false},this.SaveData(stateAdd))}} />
                <Modal icon={"success"} show={this.state.popupsuccess} title={"ผลการทำงาน"} message={"บันทึกข้อมูลสำเร็จ"} onClickConfirm={e => {this.setState({popupsuccess: false});if(stateAdd==3) this.GetDataList(s_code,s_name,s_country_id,s_province_id); }} />
                <Modal icon={"danger"} show={this.state.popupdanger} title={"พบข้อผิดพลาด"} message={this.state.mdErrorMessage} onClickConfirm={e => {this.setState({popupdanger: false})}} />
                <section data-v-0348ea44="" className="container">
                    <nav data-v-72117e1e="" data-v-0348ea44="" aria-label="breadcrumb" className="mt-3 pg_breadcrumb py-3-fix pg_breadcrumb--border">
                        <div data-v-72117e1e="" className="container">
                            <ul data-v-72117e1e="" className="pg_breadcrumb__container">
                                <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item"><a data-v-72117e1e="" href="/" className="is-link-active is-link-prefetched">หน้าแรก</a></li>
                                <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item"><a data-v-72117e1e="" href="/hongkong-tour" className="is-link-prefetched">{data.proG_COUNTRY}</a></li>
                                <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item"><a data-v-72117e1e="" href="/hongkong-tour" className="is-link-prefetched">{data.proG_PLACE}</a></li>
                                <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item active">
                                    #{data.proG_CODE}
                                </li>
                            </ul>
                        </div>
                    </nav>
                </section>
                <section className="mt-2" data-v-0348ea44="">
                    <div className="container" data-v-0348ea44="">
                        <div className="row mt-1" data-v-0348ea44="">
                            <div className="col-12 col-md-8" data-v-0348ea44="">
                            </div>
                            <div data-v-0348ea44="" className="col-12 col-md-4 justify-content-end align-items-end d-flex">
                                <div data-v-0348ea44="" className="d-flex">
                                    <span data-v-595a154c="" data-v-0348ea44="" className="pg_shared">
                                        <button data-v-595a154c="" onClick={() => this.setState({cover: !this.state.cover})} className="pg_box-shadow-4 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text mr-1 mb-1 el-popover__reference" aria-describedby="el-popover-6443" >
                                            <span className="pg_button__ripple-container"></span>
                                            <span className="pg_button__container">
                                                <span className="pg_button__icon-wrapper" style={{color: cover ? "#bb2d23" : ""}}><i className="pg_button__icon  fas fa-images"></i></span>
                                                <span className="pg_button__text" style={{color: cover ? "#bb2d23" : ""}}>
                                                    แสดงหน้าปก
                                                </span>
                                            </span>
                                        </button>
                                    </span>
                                    <span data-v-595a154c="" data-v-0348ea44="" className="pg_shared">
                                        <button data-v-595a154c="" onClick={() => this.setState({preview: !this.state.preview})} className="pg_box-shadow-4 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text mr-1 mb-1 el-popover__reference" aria-describedby="el-popover-6443" >
                                            <span className="pg_button__ripple-container"></span>
                                            <span className="pg_button__container">
                                                <span className="pg_button__icon-wrapper" style={{color: preview ? "#bb2d23" : ""}}><i className="pg_button__icon fas fa-eye" ></i></span>
                                                <span className="pg_button__text" style={{color: preview ? "#bb2d23" : ""}}>
                                                    preview
                                                </span>
                                            </span>
                                        </button>
                                    </span>
                                    <span data-v-595a154c="" data-v-0348ea44="" className="pg_shared">
                                        <button data-v-595a154c="" onClick={e => this.OnSaveDelete(0)} className="pg_box-shadow-4 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text mr-1 mb-1 el-popover__reference" aria-describedby="el-popover-6443" >
                                            <span className="pg_button__ripple-container"></span>
                                            <span className="pg_button__container">
                                                <span className="pg_button__icon-wrapper" style={{color: preview ? "#bb2d23" : ""}}><i className="pg_button__icon fas fa-trash" ></i></span>
                                                <span className="pg_button__text" style={{color: preview ? "#bb2d23" : ""}}>
                                                    ลบโปรแกรม
                                                </span>
                                            </span>
                                        </button>
                                    </span>
                                    <button data-v-0348ea44="" onClick={e => this.OnSaveData(0)} className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text">
                                        <span className="pg_button__ripple-container"></span>
                                        <span className="pg_button__container">
                                            <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-save" ></i></span>
                                            <span className="pg_button__text">
                                                บันทึก
                                            </span>
                                        </span>
                                    </button>

                                    <button data-v-0348ea44="" onClick={() => this.GetDataList(s_code,s_name,s_country_id,s_province_id)} className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--colo-danger-light-shadow pg_button--size-sm is-type-text ">
                                        <span className="pg_button__ripple-container"></span>
                                        <span className="pg_button__container">
                                            <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-times" ></i></span>
                                            <span className="pg_button__text">
                                                ยกเลิก
                                            </span>
                                        </span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        {!preview ?
                            <div className="row mt-1" data-v-0348ea44="">
                                <div className="col-12" data-v-0348ea44="">
                                    <div data-v-0348ea44="">
                                        <div data-v-00e2d612="" data-v-0348ea44="" className="pg_card-tour" type="vertical">
                                            <div data-v-00e2d612="" className="pg_card-tour__card pg_card-tour__card--vertical">
                                                <div className="container">
                                                    <div className="row">
                                                        {/* Header */}
                                                        <div data-v-00e2d612="" title={data.proG_DESC} className="col-12 p-0 pg_card-tour__header">
                                                            <div data-v-d8309bf2="" data-v-00e2d612="" className="pg_card-tour-header d-lg-flex">
                                                                <div data-v-d8309bf2="" className="pg_card-tour-header__tag-content">
                                                                    <div data-v-d8309bf2="" className="pg_card-tour-header__tour-type">
                                                                        Program tour
                                                                    </div>
                                                                </div>
                                                                <div data-v-d8309bf2="" className="pg_card-tour-header__tour-id pg_card-tour-header__tour-id--grey">
                                                                    รหัส {data.proG_CODE
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Gallery */}
                                                        <div data-v-3a1a6b1d="" data-v-0348ea44="" className="col-12 col-lg-8 p-0 pg_gallery">
                                                            <div data-v-3a1a6b1d="" className="pg_gallery__card">
                                                                <div data-v-3a1a6b1d="" className="pg_gallery__header">
                                                                    <div data-v-3a1a6b1d="" className="pg_gallery__image-container" >
                                                                        <div data-v-3a1a6b1d="" className="swiper-container-initialized swiper-container-horizontal">
                                                                            {
                                                                                <Swiper style={{height: "100%",width: "100%"}}
                                                                                    spaceBetween={50}
                                                                                    slidesPerView={1}
                                                                                >
                                                                                    {
                                                                                        images.map((img,index) => {
                                                                                            return !img.isdelete ? (
                                                                                                <SwiperSlide key={index} style={{height: "100%",width: "100%"}}>
                                                                                                    <picture data-v-713ff273="" onClick={(e) => this._handleSubmit(e,index,1)} data-v-3a1a6b1d="" className="pg_picture pg_picture--ratio-4x3" style={{width: "100%",height: "100%",overflow: "hidden"}} >
                                                                                                        <img
                                                                                                            src={img.display ? img.display : img.path}
                                                                                                            className="v-lazy-image v-lazy-image-loaded"
                                                                                                            alt={img.desc}
                                                                                                        />
                                                                                                    </picture>
                                                                                                    <div data-v-b8c390fa="" className="form-row col-12" style={{position: "absolute",bottom: "0px"}}>
                                                                                                        <div className="form-group col-12">
                                                                                                            <input type="text" className="form-control pg_input" placeholder="description" value={img.desc ? img.desc : ""} name="desc" onChange={e => this.handleChangeImgInfo(e,index,1)} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <button type="button" className="btn btn-outline-secondary" style={{position: "absolute",top: "0px",margin: "2px",right: "0"}} onClick={(e) => this._handleImgDelete(e,index,1)}><i className="fas fa-trash"></i></button>
                                                                                                </SwiperSlide>) : (<div key={index}></div>)
                                                                                        })
                                                                                    }
                                                                                    <SwiperSlide style={{height: "100%",width: "100%"}} onClick={(e) => this._handleSubmit(e,-1,1)}>
                                                                                        <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture pg_picture--ratio-4x3" style={{width: "100%",height: "100%",overflow: "hidden"}} >
                                                                                            <img
                                                                                                src="https://antstoragedata.blob.core.windows.net/webimage/importClick.png"
                                                                                                className="v-lazy-image v-lazy-image-loaded"
                                                                                                alt=""
                                                                                            />
                                                                                        </picture>
                                                                                    </SwiperSlide>
                                                                                </Swiper>
                                                                            }
                                                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="UploadBox">
                                                                        <input ref={(ref) => this.upload = ref} className="hidden" type="file" onChange={(e) => this._handleImageChange(e)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Info */}
                                                        <div data-v-00e2d612="" className="col-12 col-lg-4 pg_card-tour__content pt-1 pb-0">
                                                            {!cover ?
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <label className="form-label pg_lable">รหัสโปรแกรม</label>
                                                                        <input type="text" id="txt_Code" className="form-control pg_input" placeholder="CODE" value={data.proG_CODE ? data.proG_CODE : ""} name="data.proG_CODE" onChange={e => this.handleChange(e)} />
                                                                    </div>
                                                                    <div className="form-group col-md-12">
                                                                        <label className="form-label pg_lable">ชื่อโปรแกรม</label>
                                                                        <input type="text" id="txt_Name" className="form-control pg_input" placeholder="NAME" value={data.proG_NAME ? data.proG_NAME : ""} name="data.proG_NAME" onChange={e => this.handleChange(e)} />
                                                                    </div>
                                                                    <div className="form-group col-md-12">
                                                                        <label className="form-label pg_lable">คำอธิบายเบื้องต้น</label>
                                                                        <input type="text" id="txt_Caption" className="form-control pg_input" placeholder="DESCRIPTION" value={data.proG_CAPTION ? data.proG_CAPTION : ""} name="data.proG_CAPTION" onChange={e => this.handleChange(e)} />
                                                                    </div>
                                                                    <div className="form-group col-md-12">
                                                                        <label className="form-label pg_lable">ประเทศ</label>
                                                                        {
                                                                            countries && countries.length > 0 ?
                                                                                countries.map((m,index) => {

                                                                                    return !m.isdelete ?
                                                                                        <div key={index} className="row mt-1">
                                                                                            <div className="col-8">
                                                                                                <CountryViewer
                                                                                                    continent_id={m.continent}
                                                                                                    continent_name={m.continent_name}
                                                                                                    country_id={m.country}
                                                                                                    country_name={m.country_name}
                                                                                                    province_id={m.province}
                                                                                                    province_name={m.province_name}
                                                                                                    district_id={m.district}
                                                                                                    district_name={m.district_name}
                                                                                                    OnChangeContinent={(co,co_name) => this.OnChangeCountries(index,co,co_name,0,"",0,"",0,"")}
                                                                                                    OnChangeCountry={(co,co_name,c,c_name) => this.OnChangeCountries(index,co,co_name,c,c_name,0,"",0,"")}
                                                                                                    OnChangeProvince={(co,co_name,c,c_name,p,p_name) => this.OnChangeCountries(index,co,co_name,c,c_name,p,p_name,0,"")}
                                                                                                    OnChangeDistrict={(co,co_name,c,c_name,p,p_name,d,d_name) => this.OnChangeCountries(index,co,co_name,c,c_name,p,p_name,d,d_name)}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                <button type="button" className="btn btn-danger mr-1" onClick={e => this.OnSetDeleteCountry(index,1)} ><i className="fas fa-trash"></i></button>
                                                                                                {countries.length - 1 == index ?

                                                                                                    <button type="button" className="btn btn-info" onClick={e => this.AddCountryClick(e)} >
                                                                                                        <i className="fas fa-plus"></i>
                                                                                                    </button>
                                                                                                    : null}
                                                                                            </div>
                                                                                        </div> : null
                                                                                }) : null
                                                                        }  </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label className="form-label pg_lable">วัน</label>
                                                                        <input type="number" min="0" id="txt_Days" className="form-control pg_input text-right" placeholder="DAYS" value={data.proG_DAYS ? data.proG_DAYS : ""} name="data.proG_DAYS" onChange={e => this.handleChange(e)} />

                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label className="form-label pg_lable">คืน</label>
                                                                        <input type="number" min="0" id="txt_Night" className="form-control pg_input text-right" placeholder="NIGHT" value={data.proG_NIGHT ? data.proG_NIGHT : ""} name="data.proG_NIGHT" onChange={e => this.handleChange(e)} />
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label className="form-label pg_lable">เดือน</label>
                                                                        <input type="text" id="txt_Month" className="form-control pg_input" placeholder="MONTH" value={data.proG_MONTH ? data.proG_MONTH : ""} name="data.proG_MONTH" onChange={e => this.handleChange(e)} />
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label className="form-label pg_lable">สายการบิน</label>
                                                                        <select id="ddl_airlines" value={data.airL_ID ? data.airL_ID : "0"} name="data.airL_ID" onChange={e => this.handleChangeAirline(e)} className="form-control pg_input">
                                                                            <option key={0} value={"0"} >โปรดระบุ</option>
                                                                            {
                                                                                this.state.airlineList.map((item) => (
                                                                                    <option key={item.id} value={item.id} >{item.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group col-md-6">
                                                                        <label className="form-label pg_lable">ราคาเริ่มต้น</label>
                                                                        <input type="text" id="txt_Start_Price" className="form-control pg_input" placeholder="START PRICE" value={data.proG_START_PRICE ? data.proG_START_PRICE : ""} name="data.proG_START_PRICE" onChange={e => this.handleChange(e)} />
                                                                    </div>

                                                                </div>
                                                                : <div onClick={(e) => this._handleSubmit(e,-1,3)} > <CardPackage
                                                                    id={-1}
                                                                    code={data.proG_CODE}
                                                                    img={coverdisplay ? coverdisplay : (data.proG_COVER_IMG ? data.proG_COVER_IMG : "https://antstoragedata.blob.core.windows.net/webimage/importClick.png")}
                                                                    name={data.proG_CAPTION}
                                                                    start={prices[0] ? moment(prices[0].start).format("Do MMM YY") : ""}
                                                                    end={prices[0] ? moment(prices[0].end).format("Do MMM YY") : ""}
                                                                    pricestart={data.proG_START_PRICE ? data.proG_START_PRICE : ""}
                                                                    hotelscore={data.proG_TOTAL_HOTEL_RATE}
                                                                    airlineimg={data.airL_LOGO ? data.airL_LOGO.replace("/Airline","/Airline/icon") : ""} /></div>

                                                            }
                                                        </div>
                                                        {/* Button  */}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            // --- Preview Header --- \\
                            <Header images={images}
                                name={data.proG_NAME}
                                code={data.proG_CODE}
                                caption={data.proG_CAPTION}
                                countries={countries}
                                country={data.countrY_NAME}
                                province={data.provincE_NAME}
                                day={data.proG_DAYS}
                                night={data.proG_NIGHT}
                                month={data.proG_MONTH}
                                airlineName={data.airL_NAME}
                                airlineLogo={data.airL_LOGO}
                                startPrice={data.proG_START_PRICE}
                            />

                        }
                    </div>
                </section>
                {!preview ?
                    <section className=" pt-3" data-v-0348ea44="">
                        <div className="pg_tour-detail-content container" data-v-0348ea44="">
                            {/* <div data-v-0348ea44="" style={{position: "sticky",top: "84px",zIndex: 15}}>
                                <div data-v-0348ea44="" className="pg_tour-detail-content__menu-tour-detail d-none d-lg-block">
                                    <nav id="nav-pg" data-v-65396502="" data-v-0348ea44="" className="scrollactive-nav pg_menu-tour-detail">
                                        <a data-v-65396502="" onClick={e => this.goTo(e,"highlight")} href="#highlight" data-target-id="highlight" className={"pg_menu-tour-detail__menu scrollactive-item " + (this.state.navposition == 1 ? "is-active" : "")}>
                                            ทัวร์นี้มีอะไร
                                        </a>
                                        <a data-v-65396502="" onClick={e => this.goTo(e,"information")} href="#information" data-target-id="information" className={"pg_menu-tour-detail__menu scrollactive-item " + (this.state.navposition == 2 ? "is-active" : "")}>
                                            ทัวร์นี้เป็นอย่างไร
                                        </a>
                                        <a data-v-65396502="" onClick={e => this.goTo(e,"benefits")} href="#benefits" data-target-id="benefits" className={"pg_menu-tour-detail__menu scrollactive-item " + (this.state.navposition == 3 ? "is-active" : "")}>
                                            ทัวร์นี้รวมอะไร
                                        </a>
                                        <a data-v-65396502="" onClick={e => this.goTo(e,"period")} href="#period" data-target-id="period" className={"pg_menu-tour-detail__menu scrollactive-item " + (this.state.navposition == 4 ? "is-active" : "")}>
                                            ทัวร์นี้ไปเมื่อไร
                                        </a>
                                    </nav>
                                </div>
                            </div> */}
                            <div data-v-0348ea44="" className="row">
                                <div data-v-0348ea44="" className="col-12">
                                    <div data-v-0348ea44="" id="period" className="pg_tour-detail-content__section mb-3">
                                        <div data-v-e5314ee4="" data-v-0348ea44="" className="pg_section-period">
                                            <div data-v-e5314ee4="" className="my-3 swiper-container swiper-container-initialized swiper-container-horizontal">
                                                <div data-v-e5314ee4="" className="swiper-wrapper" style={{transform: "translate3d(0px, 0px, 0px)"}}>
                                                <a onClick={(e) => this._handleSubmit(e,-1,4)}
                                        data-v-e5314ee4=""
                                        className="pg_section-period__pdf-btn-link mt-4 pg_button pg_button--color-secondary-outline pg_button--size-md is-type-text"
                                    >
                                        <span className="pg_button__ripple-container"></span>
                                        <span className="pg_button__container">
                                            <span className="pg_button__text">
                                                อัพโหลดรายละเอียดทัวร์นี้ (PDF)
                                            </span>
                                        </span>
                                    </a>
                                    
                                                </div>
                                                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                            </div>
                                            <div data-v-e5314ee4="" className="animated faster fadeIn" >
                                                <div data-v-fa446876="" data-v-e5314ee4="" className="pg_table-detail">
                                                    <table data-v-fa446876="">
                                                        <thead data-v-fa446876="">
                                                            <tr data-v-fa446876="">
                                                                <th data-v-e5314ee4="" data-v-fa446876="">ช่วงเดินทาง</th>
                                                                <th data-v-e5314ee4="" data-v-fa446876="" >ผู้ใหญ<br />(ห้องละ 2-3 ท่าน)</th>
                                                                <th data-v-e5314ee4="" data-v-fa446876="" >เด็กต่ำกว่า 12 ปี <br />พร้อมเตียง<br />พักรวมกับผู้ใหญ่</th>
                                                                <th data-v-e5314ee4="" data-v-fa446876="" >เด็กต่ำกว่า 12 ปี <br />ไม่มีเตียง<br />พักรวมกับผู้ใหญ่</th>
                                                                <th data-v-e5314ee4="" data-v-fa446876="" >พักเตียงเดี่ยว</th>
                                                                <th data-v-e5314ee4="" data-v-fa446876=""></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody data-v-fa446876="">
                                                            {
                                                                prices.map((item,index) => {
                                                                    return item.isdelete && item.isdelete == 1 ?
                                                                        <tr key={index} data-v-27b20e16="" data-v-fa446876="">
                                                                            <td colSpan="5">
                                                                                <button data-v-00e2d612="" className="btn btn-light  mb-2 btn-lg btn-block" onClick={e => this.OnSetDeletePrice(index,0)}>
                                                                                    <span className="pg_button__container">
                                                                                        <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-undo" ></i></span>
                                                                                        <span className="pg_button__text"> UNDO </span>
                                                                                    </span>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        <tr key={index} data-v-e5314ee4="" data-v-fa446876="">
                                                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                                                <div className="form-row">
                                                                                    <div className="form-group col-md-3">
                                                                                        <label className="form-label pg_lable text-left">  เริ่มเดินทาง</label>
                                                                                        <DatePicker peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                            className="form-control pg_input"
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            selected={new Date(item.start)}
                                                                                            onChange={e => this.OnChangeDatePrice(e,"start",index)} />
                                                                                    </div>
                                                                                    <div className="form-group col-md-3">
                                                                                        <label className="form-label pg_lable text-left">  ถึง</label>
                                                                                        <DatePicker
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select" className="form-control pg_input" dateFormat="dd/MM/yyyy" selected={new Date(item.end)} onChange={e => this.OnChangeDatePrice(e,"end",index)} />
                                                                                    </div>
                                                                                    <div className="form-group col-md-6">
                                                                                        <label className="form-label pg_lable text-left">  หมายเหตุ</label>
                                                                                        <input type="text" className="form-control pg_input" placeholder="DESCRIPTION" value={item.description ? item.description : ""} name="description" onChange={e => this.OnChangePrice(e,index)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <div className="form-group col-md-6">
                                                                                        <label className="form-label pg_lable text-left">จำนวนที่ีเปิด</label>
                                                                                        <input type="number" className="form-control pg_input text-right"
                                                                                            placeholder="0" value={item.limit ? item.limit : ""} name="limit" onChange={e => this.OnChangePrice(e,index)} />
                                                                                    </div>
                                                                                    <div className="form-group col-md-6">
                                                                                        <label className="form-label pg_lable text-left">จำนวนที่จอง</label>
                                                                                        <input type="number" className="form-control pg_input text-right"
                                                                                            placeholder="0" value={item.book ? item.book : ""} name="book" onChange={e => this.OnChangePrice(e,index)} />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                                                <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                                    {

                                                                                        <div className="form-group col-md-12">
                                                                                            <label className="form-label pg_lable text-left">  รวมตั๋ว</label>
                                                                                            <input type="number" className="form-control pg_input text-right"
                                                                                                placeholder="0" value={item.adult_price ? item.adult_price : ""} name="adult_price" onChange={e => this.OnChangePrice(e,index)} />
                                                                                        </div>
                                                                                    }                                                 {

                                                                                        <div className="form-group col-md-12">
                                                                                            <label className="form-label pg_lable text-left">  ไม่รวมตั๋ว</label>
                                                                                            <input type="number" className="form-control pg_input text-right"
                                                                                                placeholder="0" value={item.adult_price_noticket ? item.adult_price_noticket : ""} name="adult_price_noticket" onChange={e => this.OnChangePrice(e,index)} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                                                <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                                    {

                                                                                        <div className="form-group col-md-12">
                                                                                            <label className="form-label pg_lable text-left">   รวมตั๋ว</label>
                                                                                            <input type="number" className="form-control pg_input text-right"
                                                                                                placeholder="0" value={item.children_price ? item.children_price : ""} name="children_price" onChange={e => this.OnChangePrice(e,index)} />
                                                                                        </div>
                                                                                    }                                                                        {

                                                                                        <div className="form-group col-md-12">
                                                                                            <label className="form-label pg_lable text-left">   ไม่รวมตั๋ว</label>
                                                                                            <input type="number" className="form-control pg_input text-right"
                                                                                                placeholder="0" value={item.children_price_noticket ? item.children_price_noticket : ""} name="children_price_noticket" onChange={e => this.OnChangePrice(e,index)} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                                                <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                                    {

                                                                                        <div className="form-group col-md-12">
                                                                                            <label className="form-label pg_lable text-left">   รวมตั๋ว</label>
                                                                                            <input type="number" className="form-control pg_input text-right"
                                                                                                placeholder="0" value={item.children_not_bed ? item.children_not_bed : ""} name="children_not_bed" onChange={e => this.OnChangePrice(e,index)} />
                                                                                        </div>
                                                                                    }                                                                        {

                                                                                        <div className="form-group col-md-12">
                                                                                            <label className="form-label pg_lable text-left">   ไม่รวมตั๋ว</label>
                                                                                            <input type="number" className="form-control pg_input text-right"
                                                                                                placeholder="0" value={item.children_not_bed_noticket ? item.children_not_bed_noticket : ""} name="children_not_bed_noticket" onChange={e => this.OnChangePrice(e,index)} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                                                <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                                    {

                                                                                        <div className="form-group col-md-12">
                                                                                            <label className="form-label pg_lable text-left">   รวมตั๋ว</label>
                                                                                            <input type="number" className="form-control pg_input text-right"
                                                                                                placeholder="0" value={item.single_bedded ? item.single_bedded : ""} name="single_bedded" onChange={e => this.OnChangePrice(e,index)} />
                                                                                        </div>
                                                                                    }                                                                        {

                                                                                        <div className="form-group col-md-12">
                                                                                            <label className="form-label pg_lable text-left">   ไม่รวมตั๋ว</label>
                                                                                            <input type="number" className="form-control pg_input text-right"
                                                                                                placeholder="0" value={item.single_bedded_noticket ? item.single_bedded_noticket : ""} name="single_bedded_noticket" onChange={e => this.OnChangePrice(e,index)} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                                                <button type="button" className="btn btn-danger" onClick={e => this.OnSetDeletePrice(index,1)} ><i className="fas fa-trash"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                })
                                                            }
                                                            <tr>
                                                                <td colSpan="5">
                                                                    <button data-v-00e2d612="" className="btn btn-info ml-1   btn-lg btn-block" onClick={e => this.AddPricesClick(e)}>
                                                                        <span className="pg_button__container">
                                                                            <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-plus" ></i></span>
                                                                            <span className="pg_button__text"> เพิ่มรายการ </span>
                                                                        </span>
                                                                    </button>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-0348ea44="" className="col-lg-4">

                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    // --- Preview Detail --- \\
                    <Detail category={data.touR_CAT_NAME}
                        totalMeal={data.proG_TOTAL_MEAL}
                        totalHotelRate={data.proG_TOTAL_HOTEL_RATE}
                        night={data.proG_NIGHT}
                        totalFilghtRate={data.proG_TOTAL_FLIGHT_RATE}
                        totalPlace={data.proG_TOTAL_PLACE}
                        totalActivity={data.proG_TOTAL_ACTIVITY}
                        totalShopping={data.proG_TOTAL_SHOPPING}
                        totalFreeday={data.proG_TOTAL_FREEDAYS}
                        images2={images2}
                        day={data.proG_DAYS}
                        timesdetail={timesdetail}
                        meals={meals}
                        hotels={hotels}
                        include={prop_include}
                        uninclude={prop_uninclude}
                        prices={prices}
                        condition={data.proG_CONDITION}
                        pdf_file={data.proG_PDF_FILE} />
                }

            </div>
        return (
            PageState == "L" ? pg_list :
                content
        );

    }

}