import React,{Component} from 'react';
import {ArticleCard} from './ArticleCard';
import {ImgCard} from './ImgCard';
import {PackageCard} from './PackageCard';
import {TimeLineCard} from './TimeLineCard';
import {Modal} from './Modal';
import SwiperCore,{Navigation,Pagination,Scrollbar,A11y} from 'swiper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Swiper,SwiperSlide} from 'swiper/react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import DatePicker from "react-datepicker";
import {Header,Detail} from "./ProgramViewer"
import {CountryViewer} from './CountryViewer';

import SunEditor,{buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/th';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// install Swiper components
SwiperCore.use([Navigation,Pagination,Scrollbar,A11y]);

let lastScrollY = 0;
let ticking = false;
moment().locale('th');
export class HotelManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: 0,
                name: "",
                rate: 0,
                continent_id: 0,
                continent_name: "",
                country_id: 0,
                conntry_name: "",
                province_id: 0,
                province_name: "",
                district_id:0,
                district_name:"",
                desc: "",
                desc_Edit:"",
            },
            hotellist: [],
            images: [],
            stateAdd: 0,
            countryList: [],
            provinceList: [],
            provinceSearchList: [],
            popupsuccess: false,
            mdTitle: "ยืนยัน",
            mdMessage: "ต้องการบันทึกใช้หรือไม่",
            mdIcon: "confirm",
            PageState: "L",
            s_country_id: 0,
            s_province_id: 0,
            s_district_id:0,
            s_rate: 0,
            s_name: ""
        };
        this.GetDataList = this.GetDataList.bind(this);
    }
    componentDidMount() {
        this.GetCountry();
        const {s_country_id,s_province_id,s_rate,s_name} = this.state;
        this.GetDataList(s_country_id,s_province_id,s_rate,s_name)
    }
    GetData(id) {
        fetch('api/GetHotelslist?hotel_id=' + id,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({data: json[0],loading: false},
                            () => {
                                if (json[0].country_id > 0) {
                                    this.GetProvince(json[0].country_id)
                                }
                            }

                        );
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
        // const response = await fetch('api/program');
        // const data = await response.json();
        // this.setState({ data: data, loading: false });
    }
    GetDataList(country_id,province_id,rate,name) {
        fetch(`api/GetHotelslist?country_id=${country_id}&province_id=${province_id}&rate=${rate}&name=${name}`,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({hotellist: json,loading: false,PageState: "L"});
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetCountry() {
        fetch('api/GetCountrylist',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({countryList: json,loading: false});
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    GetProvince(id,search) {
        fetch('api/GetProvincelist?countryid=' + id,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        if (search) {
                            this.setState({provinceSearchList: json,loading: false});
                        }
                        else {
                            this.setState({provinceList: json,loading: false});
                        }
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    handleChange(e) {
        var schema = {...this.state}
        var pList = e.currentTarget.name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        console.log(pList[len - 1])
        schema[pList[len - 1]] = e.currentTarget.value;
        this.setState(schema)

    }
    handleChangeSearch(e) {
        var schema = {...this.state}
        var pList = e.currentTarget.name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        schema[pList[len - 1]] = e.currentTarget.value;
        if (e.currentTarget.name == "s_country_id") {
            this.setState({provinceSearchList: []});

            if (e.currentTarget.value > 0) {
                this.GetProvince(e.currentTarget.value,true);
            }
        }
        this.setState(schema,() => {
            const {s_country_id,s_province_id,s_rate,s_name} = this.state;
            this.GetDataList(s_country_id,s_province_id,s_rate,s_name)
        });

    }
    handleChangeDesc(e) {
        var schema = {...this.state}
        schema.data.desc_Edit = e;
        this.setState(schema);

    }
    handleChangeCountry(co,co_name,c,c_name,p,p_name) {
        var schema = {...this.state}
        schema.data.continent_id = co;
        schema.data.continent_name = co_name;
        schema.data.country_id = c;
        schema.data.country_name = c_name;
        schema.data.province_name = p_name;
        schema.data.province_id = p;
        this.setState(schema)
    }   
    handleChangeDistric(co,co_name,c,c_name,p,p_name,d,d_name){
        var schema = {...this.state}
        schema.data.continent_id = co;
        schema.data.continent_name = co_name;
        schema.data.country_id = c;
        schema.data.country_name = c_name;
        schema.data.province_name = p_name;
        schema.data.province_id = p;
        schema.data.district_id = d;
        schema.data.district_name = d_name;
        this.setState(schema)
    }
    OnAddData(type) {

        if (type == 1) {
            this.setState({stateAdd: 1,addCountry: ""});
        }
        if (type == 2) {
            this.setState({stateAdd: 2,addProvince: ""});
        }
    }
    OnSaveData(type) {

        this.setState({popup: true,stateAdd: type,mdMessage: "ต้องการบันทึกใช่หรือไม่?",mdTitle: "ยืนยัน",mdIcon: "confirm"})
    }
    OnCancelData(type) {
        this.setState({stateAdd: 0});
    }
    NewData(e){
        e.preventDefault();
        this.setState({
            data: {
                id: 0,
                name: "",
                rate: 0,
                continent_id: 0,
                continent_name: "",
                country_id: 0,
                conntry_name: "",
                province_id: 0,
                province_name: "",
                district_id: 0,
                district_name: "",
                desc: "",
                desc_Edit:"",
            },
            provinceList: [],
            images:[],
            PageState: "N",
        });
    }
    SaveData(stateadd) {
        if (stateadd == 1) {
            if (this.state.addCountry) {
                fetch('DataMng/AddCountry?name=' + this.state.addCountry,{
                    method: 'get',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                    .then(function (resp) {
                        if (resp.ok) {
                            resp.json().then(function (resp_json) {
                                if (resp_json.id == 0) {
                                    this.setState({popupsuccess: true,stateAdd: 0},this.GetCountry());
                                }

                            }.bind(this))
                        }

                        else {
                            resp.text().then(function (text) {
                                console.log('error ',text);

                            });
                        }
                    }.bind(this))
                    .catch(function (error) {
                        console.log('Request failed',error);
                    });
            }
        }
        if (stateadd == 2 && this.state.data.country_id) {
            if (this.state.addProvince) {
                fetch('DataMng/AddProvince?coun_id=' + this.state.data.country_id + '&name=' + this.state.addProvince,{
                    method: 'get',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                    .then(function (resp) {
                        if (resp.ok) {
                            resp.json().then(function (resp_json) {
                                var coun_id = this.state.data.country_id;
                                this.setState({popupsuccess: true,stateAdd: 0},this.GetProvince(coun_id));
                            }.bind(this))
                        }

                        else {
                            resp.text().then(function (text) {
                                console.log('error ',text);

                            });
                        }
                    }.bind(this))
                    .catch(function (error) {
                        console.log('Request failed',error);
                    });
            }
        }
        else if (stateadd == 2 && !this.state.data.country_id) {
            this.setState({popupdanger: true,mdErrorMessage: "กรุณาระบุประเทศทัี่ต้องการเพิ่มจังหวัดก่อน"})
        }
        if (stateadd == 0) {
            this.SaveHotel();
        }
    }
    SaveHotel() {
        var schema = {...this.state}
        var data = JSON.stringify(
            schema.data
        );
        fetch('DataMng/ManagementHotel',{
            method: 'post',
            body: data,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        if (json.id == 0) {
                            var i = 0;
                            var l = 0;
                            if (schema.images.filter(f => (f.id == 0 && (!f.isdelete || f.isdelete != 1)) || (f.isupdate == 1 && (!f.isdelete || f.isdelete != 1)) || f.isdelete == 1).length > 0) {
                                schema.images.map((img,index) => {
                                    l++;
                                    if (img.isdelete != 1) {
                                        i++;
                                    }
                                    var formData = new FormData();
                                    formData.append("image",img.path);
                                    formData.append("id",img.id);
                                    formData.append("type","2");
                                    formData.append("name",img.name);
                                    formData.append("desc",img.desc);
                                    formData.append("path",img.path);
                                    formData.append("isdelete",img.isdelete);
                                    formData.append("isupdate",1);
                                    formData.append("hotel_id",json.desc);
                                    formData.append("index",i);
                                    formData.append("row",l);
                                    fetch('upload/Hotelimage',{
                                        method: 'POST',
                                        body: formData,
                                        headers: {
                                        }
                                    })
                                        .then(function (response) {
                                            if (response.ok) {

                                                response.json().then(function (j) {
                                                    //   console.log(j.row+" 1:"+schema.images.length)
                                                    if (j.row == schema.images.length) {
                                                          console.log('get1');
                                                        this.GetImages(json.desc);
                                                    }
                                                }.bind(this));
                                            }
                                            else {
                                                response.text().then(function (text) {
                                                    console.log('error ',text);
                                                });
                                            }
                                        }.bind(this))
                                        .catch(function (error) {
                                            console.log('Request failed',error);
                                        });
                                });
                            }
                            this.setState({popupsuccess: true});
                        }

                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }

    ChooseData(e,id) {
        e.preventDefault();
        this.GetData(id);
        this.GetImages(id);
        this.setState({PageState: "E"})
    }
    GetImages(id) {

        fetch('api/HotelImages?id=' + id + '&type=1',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({images: json,loading: false});
                    }.bind(this))
                }
                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });

    }
    ChangeRate(name,value) {
        var schema = {...this.state}
        var pList = name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        schema[pList[len - 1]] = value;
        this.setState(schema)
    }
    _handleSubmit(e,id) {
        e.preventDefault();
        this.setState({imgid: id},this.upload.click());
    }
    _handleImgDelete(e,id,type) {
        e.preventDefault();
        var schema = {...this.state}
        if (schema["images"][id].id == 0) {
            schema["images"].splice(id,1);
        } else {
            schema["images"][id].isdelete = 1
        }

        this.setState(schema)
    }
    _handleImageChange(e) {
        e.preventDefault();
        let ID = this.props.value && this.props.value.length !== undefined ? this.props.value.length:0;
        let reader = new FileReader();
        let file = e.target.files[0];
        let status = 1;
        let f = new Array();
        reader.onloadend = () => {
            if (reader.result) {
                var schema = {...this.state}
                    if (schema.imgid != -1) {
                        schema["images"][schema.imgid].display = reader.result;
                        schema["images"][schema.imgid].path = file;
                        schema["images"][schema.imgid].isupdate = 1;
                    }
                    else {
                        schema["images"].push({id: 0,path: file,display: reader.result,name: "",desc: "",index:0});
                    }
                this.setState(schema);
            }
        }
        if (file) {
            reader.readAsDataURL(file)
        }
    }
    handleChangeImgInfo(e,id) {
        var schema = {...this.state}
        schema["images"][id][e.currentTarget.name] = e.currentTarget.value
        schema["images"][id].isupdate = 1;
        this.setState(schema)
    }
    render() {
        const {images,data,stateAdd,addCountry,addProvince,hotellist,PageState} = this.state;
        
        const {s_name,s_rate,s_country_id,s_province_id,s_district_id} = this.state;
        const listHotel = <div style={{minHeight: "100vh"}} className="pg_tours-detail">

            <section className="panal-package-list">
                <div className="panal-search-tool">
                    <div className="row align-items-center">
                        <div className="col-4">
                            <div className="input-group">
                                <input type="text" id="txt_search_name" className="form-control pg_input" placeholder="NAME" value={s_name ? s_name : ""} name="s_name" onChange={e => this.handleChangeSearch(e)} />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="input-group">
                                <select id="ddl_search_country" value={s_country_id ? s_country_id : "0"} name="s_country_id" onChange={e => this.handleChangeSearch(e)} className="form-control pg_input">
                                    <option key={0} value={"0"} >Search by Country</option>
                                    {
                                        this.state.countryList.map((item) => (
                                            <option key={item.id} value={item.id} >{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div></div>
                        <div className="col-2">
                            <div className="input-group">
                                <select id="ddl_search_province" value={s_province_id ? s_province_id : "0"} name="s_province_id" onChange={e => this.handleChangeSearch(e)} className="form-control pg_input">
                                    <option key={0} value={"0"} >Search by Province</option>
                                    {
                                        this.state.provinceSearchList.map((item) => (
                                            <option key={item.id} value={item.id} >{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="input-group">
                                <select id="ddl_search_rate" value={s_rate ? s_rate : "0"} name="s_rate" onChange={e => this.handleChangeSearch(e)} className="form-control pg_input">
                                    <option value={"0"} >Search by Rate</option>
                                    <option value={"1"} >1</option>
                                    <option value={"2"} >2</option>
                                    <option value={"3"} >3</option>
                                    <option value={"4"} >4</option>
                                    <option value={"5"} >5</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-2">
                            <button data-v-0348ea44="" onClick={e => this.NewData(e)} className="pg_button--color-secondary pg_button--size-md is-type-text">
                                <span className="pg_button__ripple-container"></span>
                                <span className="pg_button__container">
                                    <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-plus" ></i></span>
                                    <span className="pg_button__text">
                                        เพิ่ม
                                        </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="panal-list">
                    {
                        hotellist.map((p,index) =>
                            <div key={index} className="hotel-card pg_app" onClick={(e) => this.ChooseData(e,p.id)} >
                                <div className="hotel-card-img" >
                                    <img src={p.cover_img ? p.cover_img : "https://antstoragedata.blob.core.windows.net/webimage/emptyIMG.png"} alt="" />
                                </div>
                                <div className="hotel-card-container-name">
                                    <div className="hotel-card-position">{p.district_name ? p.district_name : " - "}</div>
                                    <div>
                                    </div>
                                    ...
                                </div>
                                <div className="hotel-card-info">
                                    <div className=" col-12 hotel-card-name"> {(p.name ? p.name : "")} </div>
                                    <div className="row">
                                        <div className="col-4 hotel-card-lable">ประเทศ :</div>
                                        <div className="col-8 hotel-card-text"> {p.country_name ? p.country_name : ""}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 hotel-card-lable">rate :</div>
                                        <div className="col-8 hotel-card-text">
                                            <div data-v-2466db0a="" data-v-32f156cd="" style={{textAlign: "left"}} className="pg_rating pg_section-highlight__rating">
                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (p.rate > 0 ? "pg_rating__star--yellow" : "")} ></i>
                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (p.rate > 1 ? "pg_rating__star--yellow" : "")}></i>
                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (p.rate > 2 ? "pg_rating__star--yellow" : "")}></i>
                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (p.rate > 3 ? "pg_rating__star--yellow" : "")}></i>
                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (p.rate > 4 ? "pg_rating__star--yellow" : "")}></i>
                                            </div></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
        </div>
        const content =
            <div className="pg_tours-detail">
                <Modal icon={this.state.mdIcon} show={this.state.popup} title={this.state.mdTitle} message={this.state.mdMessage} onClickCancel={e => {this.setState({popup: false})}} onClickConfirm={e => {this.setState({popup: false},this.SaveData(stateAdd))}} />
                <Modal icon={"success"} show={this.state.popupsuccess} title={"ผลการทำงาน"} message={"บันทึกข้อมูลสำเร็จ"} onClickConfirm={e => {this.setState({popupsuccess: false})}} />
                <Modal icon={"danger"} show={this.state.popupdanger} title={"พบข้อผิดพลาด"} message={this.state.mdErrorMessage} onClickConfirm={e => {this.setState({popupdanger: false})}} />

                <section className="mt-2" data-v-0348ea44="">
                    <div className="container" data-v-0348ea44="">
                        <div className="row mt-1" data-v-0348ea44="">
                            <div className="col-12 col-md-8" data-v-0348ea44="">
                            </div>
                            <div data-v-0348ea44="" className="col-12 col-md-4 justify-content-end align-items-end d-flex">
                                <div data-v-0348ea44="" className="d-flex">
                                    <button data-v-0348ea44="" onClick={e => this.OnSaveData(0)} className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text">
                                        <span className="pg_button__ripple-container"></span>
                                        <span className="pg_button__container">
                                            <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-save" ></i></span>
                                            <span className="pg_button__text">
                                                บันทึก
                                        </span>
                                        </span>
                                    </button>

                                    <button data-v-0348ea44="" onClick={() => this.GetDataList(s_country_id,s_province_id,s_rate,s_name)} className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--colo-danger-light-shadow pg_button--size-sm is-type-text ">
                                        <span className="pg_button__ripple-container"></span>
                                        <span className="pg_button__container">
                                            <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-times" ></i></span>
                                            <span className="pg_button__text">
                                                ยกเลิก
                                        </span>
                                        </span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div className="row mt-1" data-v-0348ea44="">
                            <div className="col-12" data-v-0348ea44="">
                                <div data-v-0348ea44="">
                                    <div data-v-00e2d612="" data-v-0348ea44="" className="pg_card-tour" type="vertical">
                                        <div data-v-00e2d612="" className="pg_card-tour__card pg_card-tour__card--vertical">
                                            <div className="container">
                                                <div className="row">
                                                    {/* Header */}
                                                    <div data-v-00e2d612="" className="col-12 p-0 pg_card-tour__header">
                                                        <div data-v-d8309bf2="" data-v-00e2d612="" className="pg_card-tour-header d-lg-flex">
                                                            <div data-v-d8309bf2="" className="pg_card-tour-header__tag-content">
                                                                <div data-v-d8309bf2="" className="pg_card-tour-header__tour-type">
                                                                    Hotel
                                                            </div>
                                                            </div>
                                                            <div data-v-d8309bf2="" className="pg_card-tour-header__tour-id pg_card-tour-header__tour-id--grey">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Gallery */}
                                                    <div data-v-3a1a6b1d="" data-v-0348ea44="" className="col-12 col-lg-8 p-0 pg_gallery">
                                                        <div data-v-3a1a6b1d="" className="pg_gallery__card">
                                                            <div data-v-3a1a6b1d="" className="pg_gallery__header">
                                                                <div data-v-3a1a6b1d="" className="pg_gallery__image-container" >
                                                                    <div data-v-3a1a6b1d="" className="swiper-container-initialized swiper-container-horizontal">
                                                                        {
                                                                            <Swiper style={{height: "100%",width: "100%"}}
                                                                                spaceBetween={50}
                                                                                slidesPerView={1}
                                                                            >
                                                                                {
                                                                                    images.map((img,index) => {
                                                                                        return !img.isdelete ? (
                                                                                            <SwiperSlide key={index} style={{height: "100%",width: "100%"}}>
                                                                                                <picture data-v-713ff273="" onClick={(e) => this._handleSubmit(e,index)} data-v-3a1a6b1d="" className="pg_picture pg_picture--ratio-4x3" style={{width: "100%",height: "100%",overflow: "hidden"}} >
                                                                                                    <img
                                                                                                        src={img.display ? img.display : img.path}
                                                                                                        className="v-lazy-image v-lazy-image-loaded"
                                                                                                        alt={img.desc}
                                                                                                    />
                                                                                                </picture>
                                                                                                <div data-v-b8c390fa="" className="form-row col-12" style={{position: "absolute",bottom: "0px"}}>
                                                                                                    <div className="form-group col-12">
                                                                                                        <input type="text" className="form-control pg_input" placeholder="description" value={img.desc ? img.desc : ""} name="desc" onChange={e => this.handleChangeImgInfo(e,index)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <button type="button" className="btn btn-outline-secondary" style={{position: "absolute",top: "0px",margin: "2px",right: "0"}} onClick={(e) => this._handleImgDelete(e,index)}><i className="fas fa-trash"></i></button>
                                                                                            </SwiperSlide>) : (<div key={index}></div>)
                                                                                    })
                                                                                }
                                                                                <SwiperSlide style={{height: "100%",width: "100%"}} onClick={(e) => this._handleSubmit(e,-1)}>
                                                                                    <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture pg_picture--ratio-4x3" style={{width: "100%",height: "100%",overflow: "hidden"}} >
                                                                                        <img
                                                                                            src="https://antstoragedata.blob.core.windows.net/webimage/importClick.png"
                                                                                            className="v-lazy-image v-lazy-image-loaded"
                                                                                            alt=""
                                                                                        />
                                                                                    </picture>
                                                                                </SwiperSlide>
                                                                            </Swiper>
                                                                        }
                                                                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                                    </div>
                                                                </div>
                                                                <div className="UploadBox">
                                                                    <input ref={(ref) => this.upload = ref} className="hidden" type="file" onChange={(e) => this._handleImageChange(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Info */}
                                                    <div data-v-00e2d612="" className="col-12 col-lg-4 pg_card-tour__content pt-1 pb-0">
                                                        <div className="form-row">

                                                            <div className="form-group col-md-12">
                                                                <label className="form-label pg_lable">ชื่อโรงแรม</label>
                                                                <input type="text" id="txt_Name" className="form-control pg_input" placeholder="NAME" value={data.name ? data.name : ""} name="data.name" onChange={e => this.handleChange(e)} />
                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                        <label className="form-label pg_lable">ประเทศ</label>
                                                                        <div  className="row mt-1">
                                                                                        <div className="col-8">
                                                                                            <CountryViewer 
                                                                                                continent_id={data.continent_id} 
                                                                                                continent_name={data.continent_name}
                                                                                                country_id={data.country_id} 
                                                                                                country_name={data.country_name}
                                                                                                province_id={data.province_id} 
                                                                                                province_name={data.province_name}
                                                                                                district_id={data.district_id}
                                                                                                district_name={data.district_name}
                                                                                                OnChangeCountry={(co,co_name,c,c_name) => this.handleChangeCountry(co,co_name,c,c_name,0,"")}
                                                                                                OnChangeProvince={(co,co_name,c,c_name,p,p_name) =>this.handleChangeCountry(co,co_name,c,c_name,p,p_name)}
                                                                                                OnChangeDistrict={(co,co_name,c,c_name,p,p_name,d,d_name)=>this.handleChangeDistric(co,co_name,c,c_name,p,p_name,d,d_name)}
                                                                                            />
                                                                                        </div>
                                                            </div> </div>
                                                            <div className="form-group col-md-12">
                                                                <label className="form-label pg_lable">Rate</label>
                                                                <div data-v-32f156cd="">
                                                                    <div data-v-2466db0a="" data-v-32f156cd="" style={{textAlign: "left"}} className="pg_rating pg_section-highlight__rating">
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (data.rate > 0 ? "pg_rating__star--yellow" : "")} onClick={e => this.ChangeRate("data.rate",1)}></i>
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (data.rate > 1 ? "pg_rating__star--yellow" : "")} onClick={e => this.ChangeRate("data.rate",2)}></i>
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (data.rate > 2 ? "pg_rating__star--yellow" : "")} onClick={e => this.ChangeRate("data.rate",3)}></i>
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (data.rate > 3 ? "pg_rating__star--yellow" : "")} onClick={e => this.ChangeRate("data.rate",4)}></i>
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star pg_app " + (data.rate > 4 ? "pg_rating__star--yellow" : "")} onClick={e => this.ChangeRate("data.rate",5)}></i>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/* Button  */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pt-3" data-v-0348ea44="">
                    <div className="pg_tour-detail-content container" data-v-0348ea44="">
                        <div data-v-0348ea44="" className="row">
                            <div data-v-0348ea44="" className="col-12">
                                <div data-v-0348ea44="" id="information" className="pg_tour-detail-content__section mb-3">
                                    <div data-v-27b20e16="" style={{height: "500px"}} data-v-0348ea44="" className="pg_section-information">
                                        <SunEditor height="400px"
                                            name="data.desc_Edit"
                                            setOptions={{
                                                buttonList: buttonList.complex
                                            }}
                                            setContents={data.desc}
                                            onChange={e => this.handleChangeDesc(e)}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        return (PageState == "L" ? listHotel :
            content
        );

    }

}