import React,{Component} from 'react';
import  MapContainer from './Maps';

export class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {forecasts: [],loading: true};
  }

  componentDidMount() {
  }


  render() {
    const mapStyles = {
      width: '100%',
      height: '100%',
    };
    return (
      <div className="container_home">
        <div className="container_aboutus">
          <div className="col-12  container_sub_aboutus">
            <div className="col-8 mt-3 mb-3  mt-5">
              <div className="aboutUs-title mt-2 mb-3">
                <img style={{width: "15rem"}} src="https://antstoragedata.blob.core.windows.net/webimage/about_us_text.png" />
              </div>
              <div className="aboutUs-Content mt-2">
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; บริษัทแอนท์ เจอร์นีย์ เฮาส์ จำกัด ประกอบธุรกิจท่องเที่ยว มีวัตถุประสงค์ในการจัดการและบริการ
                 การท่องเที่ยวทั้งในประเทศและต่างประเทศ เพื่อตอบสนองความต้องการของลูกค้ามากที่สุด</p>
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; แอนท์ เจอร์นีย์ เฮาส์ จำกัด ได้รวบรวมบุคลากรที่มีศักย์ภาพและประสบการณ์ในการทำธรุกิจการท่องเที่ยว
               พร้อมให้คำปรึกษา จัดการปัญหาเพื่อสร้างความเชื่อมั่นให้กับลุกค้า ซึ่งเป็นสิ่งที่สำคัญที่สุดในการดำเนินธุรกิจท่องเที่ยว</p>
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ทางบริษัท  "เรา" ยินดีที่จะให้บริการลูกค้าอย่างเต็มที่ เพื่อสร้างประสบการณ์และความประทับใจในการท่องเที่ยว 
              ให้ตอบสนองความต้องของลูกค้าอย่างมากที่สุด <br /></p>
              </div>
              <div className="col-12 maps-panal" style={{position:"relative" ,height:"500px"}}> 
                  <MapContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
