import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import '../custom.css'
import '../main.scss'
import '../fontawesome-free-5.15.1-web/css/fontawesome.css'
import '../fontawesome-free-5.15.1-web/css/solid.css'
import '../fontawesome-free-5.15.1-web/css/brands.css'

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      showSecond:0
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }
handleScroll(event) {
 // let scrollTop = event.srcElement.body.scrollTop,
      
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      var elmnt = document.getElementById("navbar-main");
      if(elmnt){
      let menuH = elmnt.offsetHeight;

      if(scrollTop>menuH)
      {
        this.setState({
          showSecond:  1
      });
      }
      else{
        this.setState({
          showSecond:  0
      });
      }
    }
};

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        <div className="navbar-header">
          <div className="slogan"> 
         
          <img  src="https://antstoragedata.blob.core.windows.net/webimage/slocan.png" ></img>
          </div>
          <div className="logo"> 
           <img  src="https://antstoragedata.blob.core.windows.net/webimage/ANT_LOGO.png" ></img>
          </div>
        </div>
        <Navbar id="navbar-main" className={"navbar-expand-md navbar-toggleable-md ng-white box-shadow mb-3 navbar-main "+(this.state.showSecond?"invisible":"") } light>
          <Container>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row justify-content-center" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                
              <NavItem>
                  <NavLink tag={Link} className="NavItemLink" style={{paddingTop:"1px", fontSize:"1.5em"}}  to="">
            <div className="static">
            <i className="fas fa-home"></i>
            </div>
            </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink"  to="">ทัวร์ต่างประเทศ</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">ทัวร์ในประเทศ</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="/insentive">จัดกรุ๊ปส่วนตัว</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">ท่องเที่ยวด้วยตัวเอง</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">วีซ่าท่องเที่ยว</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">ประกันภัยการเดินทาง</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">โปรโมชั่นพิเศษ</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="/aboutUs">เกี่ยวกับเรา</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
        <Navbar className={"navbar-expand-md navbar-toggleable-md ng-white box-shadow mb-3 navbar-second "+(!this.state.showSecond?"invisible":"")}  light>
        <Container>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row justify-content-center" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                 
              <NavItem>
                  <NavLink tag={Link} className="NavItemLink" style={{paddingTop:"1px", fontSize:"1.5em"}}  to="">
            <div className="static">
            <i className="fas fa-home"></i>
            </div>
            </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink"  to="">ทัวร์ต่างประเทศ</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">ทัวร์ในประเทศ</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="/insentive">จัดกรุ๊ปส่วนตัว</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">ท่องเที่ยวด้วยตัวเอง</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">วีซ่าท่องเที่ยว</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">ประกันภัยการเดินทาง</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="">โปรโมชั่นพิเศษ</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="NavItemLink" to="/aboutUs">เกี่ยวกับเรา</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
