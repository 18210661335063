import React,{Component} from 'react';
import MapContainer from './Maps';

export class Sendbox extends Component {
  constructor(props) {
    super(props);
    this.state = {forecasts: [],loading: true};
  }

  componentDidMount() {
  }


  render() {
    const mapStyles = {
      width: '100%',
      height: '100%',
    };
    return (
      <div className="container_home">
        <div className="container_aboutus">
          <div className="col-12  container_sub_aboutus">
            <div className="col-md-8 col-sm-12 mt-3 mb-3  mt-5">
              <div className="sendbox-title mt-2 mb-3">
                Phuket Sendbox
                <div style={{width: "60px",float: "right"}}>
                  <select name="data.countrY_ID" name="times" className="form-control pg_input">
                    <option key={1} value={"1"} >TH</option>
                    <option key={2} value={"2"} >EN</option>
                    <option key={3} value={"3"} >JN</option>
                    <option key={4} value={"4"} >CHN</option>
                  </select>
                </div>
              </div>
              <div className="sendbox-Content mt-2">
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; บริษัทแอนท์ เจอร์นีย์ เฮาส์ จำกัด ประกอบธุรกิจท่องเที่ยว มีวัตถุประสงค์ในการจัดการและบริการ
                  การท่องเที่ยวทั้งในประเทศและต่างประเทศ เพื่อตอบสนองความต้องการของลูกค้ามากที่สุด</p>
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; แอนท์ เจอร์นีย์ เฮาส์ จำกัด ได้รวบรวมบุคลากรที่มีศักย์ภาพและประสบการณ์ในการทำธรุกิจการท่องเที่ยว
                  พร้อมให้คำปรึกษา จัดการปัญหาเพื่อสร้างความเชื่อมั่นให้กับลุกค้า ซึ่งเป็นสิ่งที่สำคัญที่สุดในการดำเนินธุรกิจท่องเที่ยว</p>
                <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ทางบริษัท  "เรา" ยินดีที่จะให้บริการลูกค้าอย่างเต็มที่ เพื่อสร้างประสบการณ์และความประทับใจในการท่องเที่ยว
                  ให้ตอบสนองความต้องของลูกค้าอย่างมากที่สุด <br /></p>
              </div>
              <div className="sendbox-title mt-2 mb-3">
                บริการของเรา
              </div>
              <div className="col-12 " style={{position: "relative"}}>
                <div className="row">
                  <div className="col-md-4 pr-1 pl-1 mb-2">
                    <div className="box-panel">
                      <div className="detail-tital"> <div className="icon-circle"> <i className="fas fa-plane"></i></div> Booking Flight</div>
                      <div className="detail-content">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.
                        Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4  pr-1 pl-1 mb-2">
                    <div className="box-panel">
                      <div className="detail-tital"><div className="icon-circle"> <i className="fas fa-hotel"></i></div> Hotel + Transfer</div>
                      <div className="detail-content">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.
                        Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4  pr-1 pl-1 mb-2">
                    <div className="box-panel">
                      <div className="detail-tital"><div className="icon-circle"> <i className="fas fa-umbrella-beach"></i></div> Trip</div>
                      <div className="detail-content">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.
                        Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.Abc defgh ijaklm beach ksii maik lsiiikski ksikn. ksik i9woll eiepp. sslsls abs query maris isplwl ksieks ssslaosdf.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ant-shadow-panal" style={{position: "relative"}}>
                <div className="detail-tital mt-2"><div className="icon-circle"> <i className="fas fa-paper-plane"></i></div> Contact Us</div>
                <div className="detail-content">
                  <div className="form form-contact col-12 mt-1 mb-3">
                    <div className="mb-2">
                      <label className="form-label">ชื่อ - นามสกุล</label>
                      <input className="form-control" id="txt-name" placeholder="ชื่อ - นามสกุล" />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">เบอร์ติดต่อ</label>
                      <input className="form-control" id="txt-tel" placeholder="เบอร์ติดต่อ" />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">อีเมล์</label>
                      <input className="form-control" id="txt-email" placeholder="อีเมล์" />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">รายละเอียด</label>
                      <textarea className="form-control" id="txt-detial" rows="10" placeholder="รายละเอียด" />
                    </div>
                    <div className="col-12 text-center ">
                      <button type="submit" className="btn btn-lg btn-success mb-3"> SEND</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
