import React,{Component} from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/th';
var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();
// install Swiper components
moment().locale('th');
export class PlaceViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      step:0,
      continent_id: 0,
      continent_name: "",
      country_id: 0,
      country_name: "",
      province_id: 0,
      province_name: "",
      district_id:0,
      district_name:"",
      place_id:0,
      place_name:"",
      continentList: [],
      countryList:[],
      provinceList:[],
      districtList:[],
      placeList:[],
      place:{images:[]},
      index:0
    }
  }
  
  componentDidMount() {
    this.GetContinent()
    this.setState({index:this.props.index})
    if(this.props.place){
        let p = {
            name:this.props.place_name,
            desc:this.props.place_desc,
            images:[{
                path:this.props.img?this.props.img:""
            }]
        }
        this.setState({place_id:this.props.place,
            place_name:this.props.place_name,
            place:p,
            district_id:this.props.district,
            province_id:this.props.province,
            country_id:this.props.country,
            continent_id:this.props.continent,
            district_name:this.props.district_name,
            province_name:this.props.province_name,
            country_name:this.props.country_name,
            continent_name:this.props.continent_name,
            step:5
        })

    }
}
componentDidUpdate(prevProps) {
  
    if(prevProps.place!= this.props.place)
    {
    this.GetContinent()
    let p = {
        name:this.props.place_name,
        desc:this.props.place_desc,
        images:[{
            path:this.props.img?this.props.img:""
        }]
    }
    this.setState({place_id:this.props.place,
        place_name:this.props.place_name,
        place:p,
        district_id:this.props.district,
        province_id:this.props.province,
        country_id:this.props.country,
        continent_id:this.props.continent,
        district_name:this.props.district_name,
        province_name:this.props.province_name,
        country_name:this.props.country_name,
        continent_name:this.props.continent_name,
        step:5
    })
}
}
  GetContinent() {
    fetch('DataMng/GetDataLookup?LookupType=CONTINANT',{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    this.setState({continentList: json.data}
                    );

                }.bind(this))
            }

            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                    this.setState({loading: false});
                });
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        });
}
GetCountry(name,continent) {
    fetch('DataMng/GetCountryData?name=' + name + '&continent_id=' + continent,{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    this.setState({countryList: json});
                }.bind(this))
            }
            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                }.bind(this));
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        }.bind(this));
}
GetProvince(country, name,catagory) {
    fetch('DataMng/GetProvinceData?country_id='+country+'&name=' + name + '&type=' + catagory,{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    
                    this.setState({provinceList: json});
                }.bind(this))
            }
            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                }.bind(this));
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        }.bind(this));
}

GetDistricts(province, name,catagory) {
    fetch('DataMng/GetDistrictsData?province_id='+province+'&name=' + name + '&type=' + catagory,{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    this.setState({districtList: json});
                }.bind(this))
            }
            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                }.bind(this));
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        }.bind(this));
}

GetPlaces(district,name,catagory) {
        fetch('DataMng/GetPlacesData?district_id=' + district + '&name=' + name + '&type=' + catagory,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({placeList: json});
                    }.bind(this))
                }
                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                    }.bind(this));
                }
            }.bind(this))
            .catch(function (error) {
                this.setState({loading: false});
                console.log('Request failed',error);
            }.bind(this));
}

GetPlace(placeId) {
    fetch('DataMng/GetPlace?id=' + placeId,{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    this.setState({place: json});
                    this.props.onChangePlace(placeId,json.name,json.desc,json.images&&json.images.length>0?json.images[0].path:"",this.state.continent_id,this.state.continent_name,this.state.country_id,this.state.country_name,this.state.province_id,this.state.province_name,this.state.district_id,this.state.district_name)
                }.bind(this))
            }
            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                }.bind(this));
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        }.bind(this));

}
  handleChangeContinent(e){
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({continent_id: e.currentTarget.value,continent_name:e.nativeEvent.target[index].text,step:1},this.GetCountry('',e.currentTarget.value));
  }

  handleChangeCountry(e){
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({country_id: e.currentTarget.value,country_name:e.nativeEvent.target[index].text,step:2},this.GetProvince(e.currentTarget.value,'',''));
  }

  handleChangeProvince(e){
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({province_id: e.currentTarget.value,province_name:e.nativeEvent.target[index].text,step:3},this.GetDistricts(e.currentTarget.value,'',''));
  }

  handleChangeDistricts(e){
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({district_id: e.currentTarget.value,district_name:e.nativeEvent.target[index].text,step:4},this.GetPlaces(e.currentTarget.value,'',''));
  }

  handleChangePlace(e){
    var index = e.nativeEvent.target.selectedIndex;

    this.setState({place_id: e.currentTarget.value,place_name:e.nativeEvent.target[index].text,step:5},this.GetPlace(e.currentTarget.value));
  }
  render() {
    const {continent_id,continent_name,country_id,country_name,province_id,province_name,district_id,district_name,place_id,place_name,step,place,index} = this.state
    return <div>
        <section data-v-0348ea44="" className="">
              <div className="place-container">
               {step>=0? 
               <div className="place-item"> 
                  {step>0?<div className='text-btn mt-2' onClick={()=>{this.setState({step:0,place_id:0,district_id:0,province_id:0,country_id:0})}}>{continent_name}&nbsp;&nbsp;&nbsp;&nbsp;| </div> 
                  :
                    <select id="ddl_search_country" value={continent_id} name="continent" onChange={e => this.handleChangeContinent(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select continent</option>
                        {
                            this.state.continentList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null}
                {
               step>=1? 
                <div className="place-item"> 
                  {step>1?<div className='text-btn mt-2' onClick={()=>{this.setState({step:1,place_id:0,district_id:0,province_id:0});this.GetCountry('',continent_id)}}>{country_name}&nbsp;&nbsp;&nbsp;&nbsp;| </div> 
                  :
                    <select id="ddl_search_country" value={country_id} name="country" onChange={e => this.handleChangeCountry(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select country</option>
                        {
                            this.state.countryList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null}
                {
               step>=2? 
                <div className="place-item"> 
                  {step>2?<div className='text-btn mt-2' onClick={()=>{this.setState({step:2,place_id:0,district_id:0});this.GetProvince(country_id,'','')}}>{province_name}&nbsp;&nbsp;&nbsp;&nbsp;| </div> 
                  :
                    <select id="ddl_search_province" value={province_id} name="province" onChange={e => this.handleChangeProvince(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select province</option>
                        {
                            this.state.provinceList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null}
                {
               step>=3? 
                <div className="place-item"> 
                 {step>3?<div className='text-btn mt-2' onClick={()=>{this.setState({step:3,place_id:0});this.GetDistricts(province_id,'','')}}>{district_name}&nbsp;&nbsp;&nbsp;&nbsp;| </div> 
                  :
                    <select id="ddl_search_districts" value={district_id}  name="districts" onChange={e => this.handleChangeDistricts(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select districts</option>
                        {
                            this.state.districtList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null}
                {
               step>=4? 
                <div className="place-item"> 
                  {step>4?<div className='text-btn mt-2' onClick={()=>{this.setState({step:4});this.GetPlaces(district_id,'','')}}>{place_name} </div> 
                  :
                    <select id="ddl_search_place" value={place_id} name="place" onChange={e => this.handleChangePlace(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select place</option>
                        {
                            this.state.placeList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null
  }
  
              </div>
              {
    step==5?
    ((index % 2 == 0 )?
    (<div  className="place-card pg_app col-12 mb-4" style={{padding: "0"}}  >

        <div className="place-card-img  col-4 p-1" >
            { <img src={place.images.length > 0 && place.images[0].path ? place.images[0].path : "https://antstoragedata.blob.core.windows.net/webimage/emptyIMG.png"} alt="" /> }
        </div>
        <div className="place-card-container col-8 pl-1" >
            <div className="place-card-content p-0">
                <div className="place-card-container-info name" >{place.name ? place.name : ""}</div>
                <div className="place-card-container-info desc" >{
                    htmlToReactParser.parse(place.desc)
                }</div>
            </div>
        </div>
    </div> ): 
    (<div  className="place-card pg_app col-12 mb-4" style={{padding: "0",textAlign:"right"}}  >
        <div className="place-card-container col-8 pr-1" >
            <div className="place-card-content p-0">
                <div className="place-card-container-info name" >{place.name ? place.name : ""}</div>
                <div className="place-card-container-info desc" >{
                    htmlToReactParser.parse(place.desc)
                }</div>
            </div>
        </div>
        <div className="place-card-img col-4 p-1" >
            { <img src={place.images.length > 0 && place.images[0].path ? place.images[0].path : "https://antstoragedata.blob.core.windows.net/webimage/emptyIMG.png"} alt="" /> }
        </div>
    </div>))
    :null
  }
        </section>
    </div>
  }
}