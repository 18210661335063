import React,{Component} from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/th';
var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();
// install Swiper components
moment().locale('th');
export class CountryViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      step:0,
      continent_id: 0,
      continent_name: "",
      country_id: 0,
      country_name: "",
      province_id: 0,
      province_name: "",
      district_id:0,
      district_name:"",
      continentList: [],
      countryList:[],
      provinceList:[],
      districtList:[]
    }
  }
  
  componentDidMount() {
    this.GetContinent()
    if(this.props.continent_id&&!this.props.country_id)this.GetCountry('',this.props.continent_id);
    if(this.props.country_id&&!this.props.province)this.GetProvince(this.props.country_id,'','');
    if(this.props.province_id&&!this.props.district_id)this.GetDistricts(this.props.province_id,'','');

        this.setState({
            province_id:this.props.province_id,
            country_id:this.props.country_id,
            continent_id:this.props.continent_id,
            district_id:this.props.district_id,
            province_name:this.props.province_name,
            country_name:this.props.country_name,
            continent_name:this.props.continent_name,
            district_name:this.props.district_name,
            step:this.props.district_id>0?4:(this.props.province_id>0? 3:(this.props.country_id>0?2:this.props.continent_id>0?1:0))
        })
}

  componentWillReceiveProps(nextProps) {
    if (nextProps.district_id !== this.state.district_id || nextProps.province_id !== this.state.province_id||nextProps.country_id !== this.state.country_id||nextProps.continent_id !== this.state.continent_id) {

        this.setState({
            province_id:nextProps.province_id,
            country_id:nextProps.country_id,
            continent_id:nextProps.continent_id,
            province_name:nextProps.province_name,
            country_name:nextProps.country_name,
            continent_name:nextProps.continent_name,
            district_id:nextProps.district_id,
            district_name:nextProps.district_name,
            step:nextProps.district_id>0?4:( nextProps.province_id>0? 3:(nextProps.country_id>0?2:nextProps.continent_id>0?1:0))
        })
    }
  }
  GetContinent() {
    fetch('DataMng/GetDataLookup?LookupType=CONTINANT',{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    this.setState({continentList: json.data}
                    );

                }.bind(this))
            }

            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                    this.setState({loading: false});
                });
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        });
}
GetCountry(name,continent) {
    fetch('DataMng/GetCountryData?name=' + name + '&continent_id=' + continent,{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    this.setState({countryList: json});
                }.bind(this))
            }
            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                }.bind(this));
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        }.bind(this));
}
GetProvince(country, name,catagory) {
    fetch('DataMng/GetProvinceData?country_id='+country+'&name=' + name + '&type=' + catagory,{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    
                    this.setState({provinceList: json});
                }.bind(this))
            }
            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                }.bind(this));
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        }.bind(this));
}

GetDistricts(province, name,catagory) {
    fetch('DataMng/GetDistrictsData?province_id='+province+'&name=' + name + '&type=' + catagory,{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    this.setState({districtList: json});
                }.bind(this))
            }
            else {
                response.text().then(function (text) {
                    console.log('error ',text);
                }.bind(this));
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({loading: false});
            console.log('Request failed',error);
        }.bind(this));
}
  handleChangeContinent(e){
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({continent_id: e.currentTarget.value,continent_name:e.nativeEvent.target[index].text,step:1},this.GetCountry('',e.currentTarget.value));
   if(this.props.OnChangeContinent) this.props.OnChangeContinent(e.currentTarget.value,e.nativeEvent.target[index].text);
}

  handleChangeCountry(e){
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({country_id: e.currentTarget.value,country_name:e.nativeEvent.target[index].text,step:2},this.GetProvince(e.currentTarget.value,'',''));
    if(this.props.OnChangeCountry) this.props.OnChangeCountry(this.state.continent_id,this.state.continent_name,e.currentTarget.value,e.nativeEvent.target[index].text);
}

  handleChangeProvince(e){
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({province_id: e.currentTarget.value,province_name:e.nativeEvent.target[index].text,step:3},this.GetDistricts(e.currentTarget.value,'',''));
    if(this.props.OnChangeProvince) this.props.OnChangeProvince(this.state.continent_id,this.state.continent_name,this.state.country_id,this.state.country_name,e.currentTarget.value,e.nativeEvent.target[index].text);
  }

  handleChangeDistricts(e){
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({district_id: e.currentTarget.value,district_name:e.nativeEvent.target[index].text,step:4});
    if(this.props.OnChangeDistrict) this.props.OnChangeDistrict(this.state.continent_id,this.state.continent_name,this.state.country_id,this.state.country_name,this.state.province_id,this.state.province_name,e.currentTarget.value,e.nativeEvent.target[index].text);
  }
  render() {
    const {continent_id,continent_name,country_id,country_name,province_id,province_name,district_id,district_name,place_id,place_name,step,place,index} = this.state
    return <div>
        
        <section data-v-0348ea44="" className="">
              <div className="place-container">
               {step>=0? 
               <div className="place-item"> 
                  {step>0?<div className='text-btn mt-2' onClick={()=>{this.setState({step:0,place_id:0,district_id:0,province_id:0,country_id:0,continent_id:0})}}>{continent_name}&nbsp;&nbsp;&nbsp;&nbsp;| </div> 
                  :
                    <select id="ddl_search_country" value={continent_id} name="continent" onChange={e => this.handleChangeContinent(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select continent</option>
                        {
                            this.state.continentList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null}
                {
               step>=1? 
                <div className="place-item"> 
                  {step>1?<div className='text-btn mt-2' onClick={()=>{this.setState({step:1,place_id:0,district_id:0,province_id:0,country_id:0});this.GetCountry('',continent_id)}}>{country_name}&nbsp;&nbsp;&nbsp;&nbsp;| </div> 
                  :
                    <select id="ddl_search_country" value={country_id} name="country" onChange={e => this.handleChangeCountry(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select country</option>
                        {
                            this.state.countryList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null}
                {
               step>=2? 
                <div className="place-item"> 
                  {step>2?<div className='text-btn mt-2' onClick={()=>{this.setState({step:2,place_id:0,district_id:0,province_id:0});this.GetProvince(country_id,'','')}}>{province_name}&nbsp;&nbsp;&nbsp;&nbsp;| </div> 
                  :
                    <select id="ddl_search_province" value={province_id} name="province" onChange={e => this.handleChangeProvince(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select province</option>
                        {
                            this.state.provinceList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null}
                {
               step>=3? 
                <div className="place-item"> 
                 {step>3?<div className='text-btn mt-2' onClick={()=>{this.setState({step:3,district_id:0,place_id:0});this.GetDistricts(province_id,'','')}}>{district_name} </div> 
                  :
                    <select id="ddl_search_districts" value={district_id}  name="districts" onChange={e => this.handleChangeDistricts(e)} className="form-control pg_input">
                        <option key={0} value={"0"} >select districts</option>
                        {
                            this.state.districtList.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))
                        }
                    </select>
                  }
                </div>:null}
              </div>
        </section>
    </div>
  }
}