import React,{Component} from 'react';
import {Route} from 'react-router';
import {NavMenuLeft} from './NavMenuLeft';
import {ProgramManagement} from './ProgramManagement';
import {HotelManagement} from './HotelManagement';
import { PlacesManagement } from './PlacesManagement';
export class Console extends Component {
  render() {
    return (
      <ConsoleLayout>
        <Route exact path='/console' />
        <Route path='/console/programmanagement' component={ProgramManagement} />
        <Route path='/console/hotelmanagement' component={HotelManagement} />
        <Route path='/console/Places' component={PlacesManagement} />
      </ConsoleLayout>
    )
  }
}
export class ConsoleLayout extends Component {

  render() {
    return (
      <div className="row m-0">
        <div className="col-md-3 col-xl-2 db-sidebar nav-site ">
          <NavMenuLeft />
        </div>
        <main className="col-md-9 col-xl-10 pymd-3 db-content p-0">
           <div className="container-fulid">
          {this.props.children}
        </div>
        </main>
       
      </div>
    );
  }
}
