import React,{Component} from 'react';
import {Map,GoogleApiWrapper,InfoWindow,Marker} from 'google-maps-react';

export class MapContainer extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
  };
  componentDidMount() {
  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  render() {
    const mapStyles = {
      position: 'ralative',
      width: '97%',
      height: '93%',
      overFlow: "hidden"
    };
  
    return (
      <div className="container_map">
        <Map
          google={this.props.google}
          zoom={15}
          style={mapStyles}
          initialCenter={{lat: 13.826806012576457,lng: 100.50445913103347}}

        >

          <Marker
            onClick={this.onMarkerClick}
            name={'Kenyatta International Convention Centre'}
          />
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onClose}
          >
            <div style={{textAlign:"center"}}>
              <img style={{width:"100px"}}  src="https://antstoragedata.blob.core.windows.net/webimage/antLogo.png" ></img>
            </div>
          </InfoWindow>

        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: ("AIzaSyBQ5cjCK592KSW20afaObYoFmxEQX9GG3E")
})(MapContainer)