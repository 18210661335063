import React,{Component} from 'react';

import ReactLoading from 'react-loading';
export class CardPackage extends Component {
  render() {
    return (
      <div className="package-card col-md-10" onClick={e => this.props.onClick ? this.props.onClick(e) : null}>
        <div className="package-card-container-code">
          <div className="package-card-item-code">รหัส {this.props.code}</div>
        </div>
        <div className="package-card-img">
          {!this.props.loading ?
            <img height={!this.props.id ? "100%" : ""} src={this.props.id ? (this.props.img ? this.props.img : "https://antstoragedata.blob.core.windows.net/webimage/importClick.png") : "https://antstoragedata.blob.core.windows.net/webimage/commingsoon.png"} alt="" />
            : null}
        </div>
       
          <div className="package-card-info"> 
          {!this.props.loading ? (
            <div>
            <div className=" col-12 package-card-name"> {this.props.id ? this.props.name : "comimg soon"}</div>
            <div className="package-card-time-panel d-flex flex-row ">
              <div className="package-card-time-lable">{this.props.id ? "เริ่มเดินทางตั้งแต่ : " : <br />}</div>
              <div className="package-card-time-text">{this.props.id ? this.props.start : null}</div>
            </div>

            <div className="package-card-time-panel d-flex flex-row ">
              <div className="package-card-time-lable">{this.props.id ? "เดินทางจนถึง : " : <br />}</div>
              <div className="package-card-time-text">{this.props.id ? this.props.end : null}</div>
            </div>
            <div className="package-card-price-panel">
              <div className="package-card-price-text">{this.props.id ? "เริ่มต้น " + this.props.pricestart + " บาท" : <br />}</div>
            </div>
            <div className="package-card-item-panel d-flex flex-row justify-content-around">
              <div className="package-card-item-info">
                <div className="item-icon"> <i className="fas fa-hotel"></i></div>
                <div className="item-detail">
                  <div data-v-2466db0a="" data-v-32f156cd="" className="pg_rating pg_section-highlight__rating">
                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.hotelscore > 0 ? "pg_rating__star--yellow" : "")} ></i>
                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.hotelscore > 1 ? "pg_rating__star--yellow" : "")}></i>
                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.hotelscore > 2 ? "pg_rating__star--yellow" : "")}></i>
                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.hotelscore > 3 ? "pg_rating__star--yellow" : "")}></i>
                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.hotelscore > 4 ? "pg_rating__star--yellow" : "")}></i>
                  </div>
                </div>
              </div>
              <div className="package-card-item-info">
                <div className="item-icon"> <i className="fas fa-utensils"></i></div>
                <div className="item-detail">
                  ครบทุกมื้อ
                </div>
              </div>
              <div className="package-card-item-info">
                <div className="item-icon"><i className="fas fa-wifi"></i></div>
                <div className="item-detail" style={{fontSize: "10px"}}>FREE WIFI ON BUS</div>
              </div>
              <div className="package-card-item-info airline">
                <div className="item-icon">
                  <img src={this.props.airlineimg}></img>

                </div>

                <div className="item-detail" style={{fontSize: "10px"}}>FULL SERVICE</div>
              </div>
            </div></div>) : <ReactLoading type={"cylon"} color={"#e1e5eb"} height={100} width={100} className="loadding"></ReactLoading>}
          </div>
      </div>
    );
  }
}

