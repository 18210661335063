import React,{Component} from 'react';
import ReactLoading from 'react-loading';
import {Modal} from './Modal';
import SwiperCore,{Navigation,Pagination,Scrollbar,A11y} from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import {Header,Detail} from "./ProgramViewer"
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/th';
import SunEditor,{buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {PlaceViewer} from './PlaceViewer';
import {CountryViewer} from './CountryViewer';
import {CardPackage} from './CardPackage';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// install Swiper components
SwiperCore.use([Navigation,Pagination,Scrollbar,A11y]);

var HtmlToReactParser = require('html-to-react').Parser;

var htmlInput = '<div><h1>Title</h1><p>A paragraph</p></div>';
var htmlToReactParser = new HtmlToReactParser();
var reactElement = htmlToReactParser.parse(htmlInput);
let lastScrollY = 0;
let ticking = false;
moment().locale('th');
export class Program extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true,
            id: 0,
            timesdetail: [],
            meals: [],
            properties: [],
            prices: [],
            hotels: [],
            countries: [],
            prop_include: [],
            prop_uninclude: [],
            hotelList: [],
            countriesList: [],
            images: [],
            images2: [],
        };
        this.GetData = this.GetData.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({id: id});
        
        this.GetData(id);
        this.GetImages(id);
        this.GetImages2(id);
    }
    GetData(id) {
        window.scrollTo(0,0)
        this.setState({loading: true});
        fetch('api/ProgramById?id=' + id,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({data: json[0]},
                            () => {

                                fetch('api/GetCountries?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                if (resp_json.length == 0) {
                                                    this.setState({
                                                        countries: [
                                                            {
                                                                id: 0,
                                                                continent: 0,
                                                                continent_name: "",
                                                                country: 0,
                                                                country_name: "",
                                                                province: 0,
                                                                province_name: ""
                                                            }
                                                        ]
                                                    });
                                                }
                                                else {
                                                    this.setState({countries: resp_json});
                                                }
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });
                                fetch('api/GetTimesDetail?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({timesdetail: resp_json});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });

                                fetch('api/GetMeals?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({meals: resp_json});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });

                                fetch('api/GetHotels?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({hotels: resp_json});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });

                                fetch('api/GetProperties?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({properties: resp_json,prop_include: resp_json.filter(({include}) => include == 1),prop_uninclude: resp_json.filter(({include}) => include == 0)});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });

                                fetch('api/GetPrices?id=' + json[0].proG_ID,{
                                    method: 'get',
                                    headers: {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                    .then(function (resp) {
                                        if (resp.ok) {
                                            resp.json().then(function (resp_json) {
                                                this.setState({prices: resp_json});
                                            }.bind(this))
                                        }

                                        else {
                                            resp.text().then(function (text) {
                                                console.log('error ',text);

                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });
                                setTimeout(this.setState({loading: false}),3000);

                            }

                        );
                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                        this.setState({loading: false});
                    });
                }
            }.bind(this))
            .catch(function (error) {
                this.setState({loading: false});
                console.log('Request failed',error);
            });
        // const response = await fetch('api/program');
        // const data = await response.json();
        // this.setState({ data: data, loading: false });
    }
    GetImages(id) {

        fetch('api/ProgramImages?id=' + id + '&type=1',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({images: json});
                    }.bind(this))
                }
                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });

    }
    GetImages2(id) {

        fetch('api/ProgramImages?id=' + id + '&type=2',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({images2: json});
                    }.bind(this))
                }
                else {
                    response.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    render() {
        const {timesdetail,meals,hotels,prices,prop_include,prop_uninclude,images,images2,loading,countries} = this.state;
        let data = this.state.data;

        const content =
            <div className="pg_tours-detail">
                {
                    loading ? <div style={{position: "absolute",width: "100%",zIndex: "100",textAlign: "center",height: "100%",background: "#f2f1ed"}}> <ReactLoading type={"bars"} color={"#bb2d24"} height={100} width={100} className="loadding"></ReactLoading></div>
                        : <div><section data-v-0348ea44="" className="container">
                            <nav data-v-72117e1e="" data-v-0348ea44="" aria-label="breadcrumb" className="mt-3 pg_breadcrumb py-3-fix pg_breadcrumb--border">
                                <div data-v-72117e1e="" className="container">
                                    <ul data-v-72117e1e="" className="pg_breadcrumb__container">
                                        <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item"><a data-v-72117e1e="" href="/" className="is-link-active is-link-prefetched">หน้าแรก</a></li>
                                        <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item"><a data-v-72117e1e="" href="/hongkong-tour" className="is-link-prefetched">{data.proG_COUNTRY}</a></li>
                                        <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item"><a data-v-72117e1e="" href="/hongkong-tour" className="is-link-prefetched">{data.proG_PLACE}</a></li>
                                        <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item active">
                                            #{data.proG_CODE}
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </section>
                            <section className="mt-2" data-v-0348ea44="">
                                <div className="container" data-v-0348ea44="">

                                    {/*/ --- Preview Header --- /*/
                                    }
                                    <Header images={images}
                                        name={data.proG_NAME}
                                        code={data.proG_CODE}
                                        caption={data.proG_CAPTION}
                                        countries={countries}
                                        country={data.countrY_NAME}
                                        province={data.provincE_NAME}
                                        day={data.proG_DAYS}
                                        night={data.proG_NIGHT}
                                        month={data.proG_MONTH}
                                        airlineName={data.airL_NAME}
                                        airlineLogo={data.airL_LOGO}
                                        startPrice={data.proG_START_PRICE}
                                    />
                                </div>
                            </section>
                            {  /*/ --- Preview Detail --- /*/}
                            <Detail category={data.touR_CAT_NAME}
                                totalMeal={data.proG_TOTAL_MEAL}
                                totalHotelRate={data.proG_TOTAL_HOTEL_RATE}
                                night={data.proG_NIGHT}
                                totalFlightRate={data.proG_TOTAL_FLIGHT_RATE}
                                totalPlace={data.proG_TOTAL_PLACE}
                                totalActivity={data.proG_TOTAL_ACTIVITY}
                                totalShopping={data.proG_TOTAL_SHOPPING}
                                totalFreeday={data.proG_TOTAL_FREEDAYS}
                                images2={images2}
                                day={data.proG_DAYS}
                                timesdetail={timesdetail}
                                meals={meals}
                                hotels={hotels}
                                include={prop_include}
                                uninclude={prop_uninclude}
                                prices={prices}
                                pdf_file={data.proG_PDF_FILE}  />
                        </div>
                }
            </div>
        return (
                content
        );

    }

}