import React,{Component} from 'react';
import {TimeLineCard} from './TimeLineCard';
import {Modal} from './Modal';
import SwiperCore,{Navigation,Pagination,Scrollbar,A11y} from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/th';


// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
SwiperCore.use([Navigation,Pagination,Scrollbar,A11y]);

let lastScrollY = 0;
let ticking = false;
moment().locale('th');
export class Detail extends Component {

    isMountedVal = false;
    constructor(props) {
        super(props);
        this.state = {
            navposition: 1,
            windowWidth: window.innerWidth,

        };
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.offsetsDiv = this.offsetsDiv.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {

        this.isMountedVal = true;
        window.addEventListener('scroll',this.handleScroll,true);
        window.addEventListener("resize",this.handleResize);
        this.setState({view: true});
    }

    componentWillUnmount() {
        window.removeEventListener('scroll',this.handleScroll);
        window.addEventListener("resize",this.handleResize);
        this.isMountedVal = false;
    }
    offsetsDiv(el) {
        if (this.state.PageState != "L") {
            var rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return {top: rect.top + scrollTop,left: rect.left + scrollLeft}
        }
    }
    handleResize = (e) => {
        if (this.isMountedVal) {
            this.setState({windowWidth: window.innerWidth});
        }
    };

    handleScroll = () => {
        if (this.isMountedVal) {
            lastScrollY = window.scrollY;
            //console.log(lastScrollY);
            var highlight = this.offsetsDiv(document.getElementById("highlight")).top;
            var information = this.offsetsDiv(document.getElementById("information")).top;
            var benefits = this.offsetsDiv(document.getElementById("benefits")).top;
            var period = this.offsetsDiv(document.getElementById("period")).top;
            var nev = 200;
            if (lastScrollY < information - nev) {
                this.setState({navposition: 1});
            }
            else if (lastScrollY > information - nev && lastScrollY < benefits - nev) {
                this.setState({navposition: 2});
            }
            else if (lastScrollY > benefits - nev && lastScrollY < period - nev) {
                this.setState({navposition: 3});
            }
            else if (lastScrollY > period - nev) {
                this.setState({navposition: 4});
            }
        }
    }
    goTo(event,e) {
        var elmnt = document.getElementById(e);
        elmnt.scrollIntoView(200);
        event.preventDefault();
    }
    render() {

        var HtmlToReactParser = require('html-to-react').Parser;

        var htmlToReactParser = new HtmlToReactParser();
        const {windowWidth,navposition} = this.state;
        const content =
            <section className=" pt-3" data-v-0348ea44="">
                <div className="pg_tour-detail-content container" data-v-0348ea44="">
                    {/* <div data-v-0348ea44="" style={{position: "sticky",top: "84px",zIndex: 15}}>
                        <div data-v-0348ea44="" className="pg_tour-detail-content__menu-tour-detail d-none d-lg-block">
                            <nav id="nav-pg" data-v-65396502="" data-v-0348ea44="" className="scrollactive-nav pg_menu-tour-detail">
                                <a data-v-65396502="" onClick={e => this.goTo(e,"highlight")} href="#highlight" data-target-id="highlight" className={"pg_menu-tour-detail__menu scrollactive-item " + (this.state.navposition == 1 ? "is-active" : "")}>
                                    ทัวร์นี้มีอะไร
                                </a>
                                <a data-v-65396502="" onClick={e => this.goTo(e,"information")} href="#information" data-target-id="information" className={"pg_menu-tour-detail__menu scrollactive-item " + (this.state.navposition == 2 ? "is-active" : "")}>
                                    ทัวร์นี้เป็นอย่างไร
                                </a>
                                <a data-v-65396502="" onClick={e => this.goTo(e,"benefits")} href="#benefits" data-target-id="benefits" className={"pg_menu-tour-detail__menu scrollactive-item " + (this.state.navposition == 3 ? "is-active" : "")}>
                                    ทัวร์นี้รวมอะไร
                                </a>
                                <a data-v-65396502="" onClick={e => this.goTo(e,"period")} href="#period" data-target-id="period" className={"pg_menu-tour-detail__menu scrollactive-item " + (this.state.navposition == 4 ? "is-active" : "")}>
                                    ทัวร์นี้ไปเมื่อไร
                                </a>
                            </nav>
                        </div>
                    </div> */}
                    <div data-v-0348ea44="" className="row">
                        <div data-v-0348ea44="" className="col-12">
                            {/* <div data-v-0348ea44="" id="highlight" className="pg_tour-detail-content__section mb-3">
                                <div data-v-0348ea44="" className="pg_tour-detail-content__title mb-3">
                                    ทัวร์นี้มีอะไร
                                </div>
                                <div data-v-32f156cd="" data-v-0348ea44="" className="pg_section-highlight">
                                    <div data-v-32f156cd="" className="pg_section-highlight__box-container mb-3">
                                        <div data-v-32f156cd="" className="pg_section-highlight__box">
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-compass"></i></div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                                {this.props.category}
                                            </div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                                ลักษณะการเที่ยว
                                            </div>
                                        </div>
                                        <div data-v-32f156cd="" className="pg_section-highlight__box">
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-utensils"></i></div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                                {this.props.totalMeal == 0 ? "-" : String(this.props.totalMeal)} มื้อ
                                            </div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                                มื้ออาหาร
                                            </div>
                                        </div>
                                        <div data-v-32f156cd="" className="pg_section-highlight__box">
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-hotel"></i></div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                                ระดับที่พัก
                                            </div>
                                            <div data-v-32f156cd="">
                                                <div data-v-2466db0a="" data-v-32f156cd="" className="pg_rating pg_section-highlight__rating">
                                                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalHotelRate > 0 ? "pg_rating__star--yellow" : "")} ></i>
                                                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalHotelRate > 1 ? "pg_rating__star--yellow" : "")}></i>
                                                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalHotelRate > 2 ? "pg_rating__star--yellow" : "")}></i>
                                                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalHotelRate > 3 ? "pg_rating__star--yellow" : "")}></i>
                                                    <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalHotelRate > 4 ? "pg_rating__star--yellow" : "")}></i>
                                                </div>
                                            </div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                                {this.props.night} คืน
                                            </div>
                                        </div>
                                        <div>{this.props.totalFlightRate}</div>
                                        <span data-v-375f029d="" data-v-32f156cd="" className="pg_popup-tour pg_section-highlight__box-popup">
                                            <div role="tooltip" id="el-popover-4702" aria-hidden="true" className="el-popover el-popper" style={{transformOrigin: "center bottom",zIndex: "2001",display: "none"}}>

                                                <div data-v-375f029d="" className="pg_popup-tour__popup-container">
                                                    <div data-v-375f029d="" className="pg_popup-tour__popup-header">
                                                        <div data-v-375f029d="" className="pg_popup-tour__popup-title mr-8-fix">
                                                            <div data-v-2466db0a="" data-v-32f156cd="" className="pg_rating pg_section-highlight__rating">
                                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 0 ? "pg_rating__star--yellow" : "")} ></i>
                                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 1 ? "pg_rating__star--yellow" : "")}></i>
                                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 2 ? "pg_rating__star--yellow" : "")}></i>
                                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 3 ? "pg_rating__star--yellow" : "")}></i>
                                                                <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 4 ? "pg_rating__star--yellow" : "")}></i>
                                                            </div>
                                                        </div>
                                                        <button data-v-375f029d="" className="pg_popup-tour__close-button d-block d-lg-none pg_button pg_button--color-light pg_button--size-xs is-type-icon pg_button--circle">
                                                            <span className="pg_button__ripple-container"></span>
                                                            <span className="pg_button__container">
                                                                <span className="pg_button__icon-wrapper"><i className="pg_button__icon pg_icon-st-close" style={{fontSize: "1em"}}></i></span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div data-v-32f156cd="" className="pg_section-highlight__text-score"><span data-v-32f156cd="">บินฟูลเซอร์วิส และบินตรง</span></div>
                                                </div>
                                                <div x-arrow="" className="popper__arrow" style={{left: "137.336px"}}></div>
                                            </div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box pg_section-highlight__box--in-popup el-popover__reference" aria-describedby="el-popover-4702" >
                                                <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-plane-departure"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-highlight__box-title">คะแนนเที่ยวบิน </div>
                                                <div data-v-32f156cd="">
                                                    <div data-v-2466db0a="" data-v-32f156cd="" className="pg_rating pg_section-highlight__rating">
                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 0 ? "pg_rating__star--yellow" : "")} ></i>
                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 1 ? "pg_rating__star--yellow" : "")}></i>
                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 2 ? "pg_rating__star--yellow" : "")}></i>
                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 3 ? "pg_rating__star--yellow" : "")}></i>
                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (this.props.totalFlightRate > 4 ? "pg_rating__star--yellow" : "")}></i>
                                                    </div>
                                                </div>
                                                <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1"></div>
                                            </div>
                                        </span>
                                        <div data-v-32f156cd="" className="pg_section-highlight__box">
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-camera"></i></div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                                {this.props.totalPlace}
                                            </div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                                สถานที่ท่องเที่ยว
                                            </div>
                                        </div>
                                        <div data-v-32f156cd="" className="pg_section-highlight__box">
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-hiking"></i></div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                                {this.props.totalActivity == 0 ? "ไม่มี" : String(this.props.totalActivity)}
                                            </div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                                กิจกรรม
                                            </div>
                                        </div>
                                        <div data-v-32f156cd="" className="pg_section-highlight__box">
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-shopping-bag"></i></div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                                {this.props.totalShopping == 0 ? "ไม่มี" : String(this.props.totalShopping)}
                                            </div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                                ช๊อบปิ้ง
                                            </div>
                                        </div>
                                        <div data-v-32f156cd="" className="pg_section-highlight__box">
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-grin"></i></div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                                {this.props.totalFreeday == 0 ? "ไม่มี" : String(this.props.totalFreeday)}
                                            </div>
                                            <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                                วันอิสระ
                                            </div>
                                        </div>

                                    </div>
                                    <div data-v-b8c390fa="" data-v-32f156cd="" className="pg_slide-highlight mb-4-fix">
                                        <Swiper slidesPerView={(windowWidth < 400 ? 2 : (windowWidth < 600 ? 4 : 6))} spaceBetween={20} navigation={{
                                            nextEl: ".pg_slide-highlight__next",prevEl: ".pg_slide-highlight__prev"
                                        }
                                        }
                                        >
                                            {this.props.images2.map((img,index) =>
                                                <SwiperSlide key={index} >
                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__slide">
                                                        <div data-v-b8c390fa="">
                                                            <picture data-v-713ff273="" data-v-b8c390fa="" className="pg_slide-highlight__image pg_picture pg_picture--ratio-4x3  pg_picture--border-radius-8">
                                                                <img src={img.display ? img.display : img.path}
                                                                    className="v-lazy-image v-lazy-image-loaded" alt="item.thai" />
                                                            </picture>
                                                            <div data-v-b8c390fa="" className="pg_slide-highlight__label">
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__title mt-2-fix">
                                                                    {img.name}
                                                                </div>
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__subtitle">    {img.desc}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )}
                                        </Swiper>
                                        <button data-v-b8c390fa="" className="pg_slide-highlight__prev" role="button" aria-label="Previous slide" text="<" >
                                            <i className="fas fa-angle-left"></i>
                                        </button>
                                        <button data-v-b8c390fa="" className="pg_slide-highlight__next" role="button" aria-label="Next slide" text=">">
                                            <i className="fas fa-angle-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div data-v-0348ea44="" id="information" className="pg_tour-detail-content__section mb-3">
                                <div data-v-0348ea44="" className="pg_tour-detail-content__title">
                                    ทัวร์นี้เป็นอย่างไร
                                </div>
                                <div data-v-27b20e16="" data-v-0348ea44="" className="pg_section-information">
                                    <div data-v-27b20e16="" className="pg_section-information__label-container mb-4-fix">
                                        <div data-v-27b20e16="" className="pg_section-information__label">
                                            เดินทาง {this.props.day} วัน {this.props.night} คืน
                                        </div>
                                    </div>
                                    <div data-v-27b20e16="" className="pg_section-information__itinerary-container mb-5">
                                        <div data-v-00202ae5="" data-v-27b20e16="" className="pg_section-itinerary">
                                            <div data-v-00202ae5="" className="pg_section-itinerary__collapse-container">
                                                {this.props.timesdetail.map((item,index) => {
                                                    let detail = "<div key=" + index + ">";
                                                    item.timeDetails.map((times,i) => {

                                                        detail += times.type == 1 ? times.detail
                                                            : ((i % 2 == 0) ?
                                                                (`<div  class="place-card-view pg_app col-12 mb-4" style="padding:0"  >
                                                    
                                                            <div class="place-card-img  col-4 p-1" >
                                                                 <img src="${times.img ? times.img : "https://antstoragedata.blob.core.windows.net/webimage/emptyIMG.png"}" alt="" /> 
                                                            </div>
                                                            <div class="place-card-container col-8 pl-1" >
                                                                <div class="place-card-content p-0">
                                                                    <div class="place-card-container-info name" >${times.place_name ? times.place_name : ""}</div>
                                                                    <div class="place-card-container-info desc" >${times.place_desc}</div>
                                                                </div>
                                                            </div>
                                                        </div>` ) :
                                                                (`<div  class="place-card-view pg_app col-12 mb-4" style="padding:0;text-align:right;"  >
                                                            <div class="place-card-container col-8 pr-1" >
                                                                <div class="place-card-content p-0">
                                                                    <div class="place-card-container-info name" >${times.place_name ? times.place_name : ""}</div>
                                                                    <div class="place-card-container-info desc" >${times.place_desc}</div>
                                                                </div>
                                                            </div>
                                                            <div class="place-card-img col-4 p-1" >
                                                                 <img src="${times.img ? times.img : "https://antstoragedata.blob.core.windows.net/webimage/emptyIMG.png"}" alt="" /> 
                                                            </div>
                                                        </div>`))
                                                    });
                                                    detail += "</div>";
                                                    return <TimeLineCard key={index} day={item.days} places={item.places} detail={detail} />;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-27b20e16="" className="pg_section-information__meal-container mb-5">
                                        <div data-v-27b20e16="" className="pg_section-information__title mb-1">มื้ออาหาร</div>
                                        <div data-v-fa446876="" data-v-27b20e16="" className="pg_table-detail pg_section-information__table-meal">
                                            <table data-v-fa446876="">
                                                <thead data-v-fa446876="">
                                                    <tr data-v-fa446876="">
                                                        <th data-v-27b20e16="" data-v-fa446876="" style={{width: "10%"}}>วันที่</th>
                                                        <th data-v-27b20e16="" data-v-fa446876="" style={{width: "30%"}}>เช้า</th>
                                                        <th data-v-27b20e16="" data-v-fa446876="" style={{width: "30%"}}>เที่ยง</th>
                                                        <th data-v-27b20e16="" data-v-fa446876="" style={{width: "30%"}}>เย็น</th>
                                                    </tr>
                                                </thead>
                                                <tbody data-v-fa446876="">
                                                    {this.props.meals.map(item => {

                                                        return <tr key={item.id} data-v-27b20e16="" data-v-fa446876="">
                                                            <td data-v-27b20e16="" data-v-fa446876="">{item.days}</td>
                                                            <td data-v-27b20e16="" data-v-fa446876="" className={
                                                                (item.breakfast == 1 ? "pg_section-information__meal-icon--normal" :
                                                                    (item.breakfast == 2 ? "pg_section-information__meal-icon--free" : "pg_section-information__meal-icon--none")
                                                                )}>
                                                                <i className="fas fa-utensils "></i>

                                                            </td>
                                                            <td data-v-27b20e16="" data-v-fa446876="" className={(item.lunch == 1 ? "pg_section-information__meal-icon--normal" : (item.lunch == 2 ? "pg_section-information__meal-icon--free" : "pg_section-information__meal-icon--none"))}>
                                                                <i className="fas fa-utensils "></i>
                                                            </td>
                                                            <td data-v-27b20e16="" data-v-fa446876="" className={(item.dinner == 1 ? "pg_section-information__meal-icon--normal" : (item.dinner == 2 ? "pg_section-information__meal-icon--free" : "pg_section-information__meal-icon--none"))}>
                                                                <i className="fas fa-utensils "></i>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div data-v-27b20e16="" className="pg_section-information__meal-description">
                                            <div data-v-27b20e16="" className="pg_section-information__meal-description-item">
                                                <div data-v-27b20e16="" className="pg_section-information__meal-icon--normal">
                                                    <i className="fas fa-utensils "></i>
                                                </div>
                                                <div data-v-27b20e16="" className="pg_section-information__meal-description-content">
                                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-title">
                                                        มื้อเพื่อคุณ
                                                    </div>
                                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-subtitle">
                                                        มื้อนี้รวมในค่าทัวร์แล้ว
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-27b20e16="" className="pg_section-information__meal-description-item">
                                                <div data-v-27b20e16="" className="pg_section-information__meal-icon--free">
                                                    <i className="fas fa-utensils "></i>
                                                </div>
                                                <div data-v-27b20e16="" className="pg_section-information__meal-description-content">
                                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-title">
                                                        มื้ออิสระ
                                                    </div>
                                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-subtitle">
                                                        หาทานได้ตามใจคุณ
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-27b20e16="" className="pg_section-information__meal-description-item">
                                                <div data-v-27b20e16="" className="pg_section-information__meal-icon--none">
                                                    <i className="fas fa-utensils "></i>
                                                </div>
                                                <div data-v-27b20e16="" className="pg_section-information__meal-description-content">
                                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-title">
                                                        ไม่มีมื้ออาหาร
                                                    </div>
                                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-subtitle">
                                                        มื้อนี้อยู่นอกเวลาทัวร์
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-27b20e16="" className="pg_section-information__hotel-container">
                                        <div data-v-27b20e16="" className="pg_section-information__title mb-1">โรงแรมและที่พัก</div>
                                        <div data-v-fa446876="" data-v-27b20e16="" className="pg_table-detail pg_section-information__table-hotel">
                                            <table data-v-fa446876="">
                                                <thead data-v-fa446876="">
                                                    <tr data-v-fa446876="">
                                                        <th data-v-27b20e16="" data-v-fa446876="" style={{width: "10%"}}>วันที่</th>
                                                        <th data-v-27b20e16="" data-v-fa446876="" style={{width: "60%"}}>โรงแรมและที่พัก</th>
                                                        <th data-v-27b20e16="" data-v-fa446876="" style={{width: "30%"}}>ระดับ</th>
                                                    </tr>
                                                </thead>
                                                <tbody data-v-fa446876="">
                                                    {
                                                        this.props.hotels.map(item => {

                                                            return <tr key={item.id} data-v-27b20e16="" data-v-fa446876="">
                                                                <td data-v-27b20e16="" data-v-fa446876="">{item.days}</td>
                                                                <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__hotel-name">
                                                                    {item.name + " " + item.desc}
                                                                </td>
                                                                <td data-v-27b20e16="" data-v-fa446876="">
                                                                    <div data-v-2466db0a="" data-v-27b20e16="" className="pg_rating pg_section-information__rating" data-v-fa446876="">
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (item.rate > 0 ? "pg_rating__star--yellow" : "")} ></i>
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (item.rate > 1 ? "pg_rating__star--yellow" : "")}></i>
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (item.rate > 2 ? "pg_rating__star--yellow" : "")}></i>
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (item.rate > 3 ? "pg_rating__star--yellow" : "")}></i>
                                                                        <i data-v-2466db0a="" className={"pg_rating__star fas fa-star " + (item.rate > 4 ? "pg_rating__star--yellow" : "")}></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-v-0348ea44="" id="benefits" className="pg_tour-detail-content__section mb-3">
                                <div data-v-0348ea44="" className="pg_tour-detail-content__title">ทัวร์นี้รวมอะไร</div>
                                <div data-v-27b20e16="" data-v-0348ea44="" className="pg_section-information">
                                    <div data-v-27b20e16="" className="pg_section-information__benefits-container container">
                                        <div data-v-fa446876="" data-v-27b20e16="" className="pg_tour-benefits ">
                                            <div data-v-668c122e="" className="row">
                                                <div data-v-668c122e="" className="pg_benefits-incould col-12 col-lg-6" >
                                                    <div data-v-668c122e="" className="row no-gutters pg_tour-benefits__slide-container pg_section-benefits">
                                                        <div data-v-27b20e16="" className="pg_section-benefits__title mb-1 col-12">รวมอยู่ในทัวร์นี้</div>

                                                        {
                                                            this.props.include.map(((item,index) => {

                                                                return <div key={index} data-v-32f156cd="" className="pg_section-benefits__box">
                                                                    <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className={item.icon}></i></div>
                                                                    <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                                        {item.name}
                                                                    </div>
                                                                </div>
                                                            }))
                                                        }
                                                    </div>
                                                </div>
                                                <div data-v-668c122e="" className="pg_benefits-excould col-12 col-lg-6">
                                                    <div data-v-668c122e="" className="row no-gutters pg_tour-benefits__slide-container pg_section-benefits">
                                                        <div data-v-27b20e16="" className="pg_section-benefits__title mb-1 col-12">ไม่ได้รวมอยู่ในทัวร์นี้</div>
                                                        {
                                                            this.props.uninclude.map(((item,index) => {

                                                                return <div key={index} data-v-32f156cd="" className="pg_section-benefits__box">
                                                                    <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className={item.icon}></i></div>
                                                                    <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                                        {item.name}
                                                                    </div>
                                                                </div>
                                                            }))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-v-0348ea44="" id="condition" className="pg_tour-detail-content__section mb-3">
                                <div data-v-0348ea44="" className="pg_tour-detail-content__title">เงื่อนไขทัวร์</div>
                                <div data-v-e5314ee4="" data-v-0348ea44="" className="pg_section-condition">
                                    {htmlToReactParser.parse(this.props.condition)}
                                </div>
                            </div> */}
                            <div data-v-0348ea44="" id="period" className="pg_tour-detail-content__section mb-3">
                                {/* <div data-v-0348ea44="" className="pg_tour-detail-content__title">ทัวร์นี้ไปเมื่อไร</div> */}
                                <div data-v-e5314ee4="" data-v-0348ea44="" className="pg_section-period">
                                    <div data-v-e5314ee4="" className="my-3 swiper-container swiper-container-initialized swiper-container-horizontal">
                                        <div data-v-e5314ee4="" className="swiper-wrapper" style={{transform: "translate3d(0px, 0px, 0px)"}}>

                                        </div>
                                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                    </div>
                                    <div data-v-e5314ee4="" className="animated faster fadeIn" >
                                        <div data-v-fa446876="" data-v-e5314ee4="" className="pg_table-detail">
                                            <table data-v-fa446876="">
                                                <thead data-v-fa446876="">
                                                    <tr data-v-fa446876="">
                                                        <th data-v-e5314ee4="" data-v-fa446876="" style={{width: "38%"}}>เดินทางวันที่</th>
                                                        <th data-v-e5314ee4="" data-v-fa446876="" style={{width: "10%"}}>AVBL</th>
                                                        <th data-v-e5314ee4="" data-v-fa446876="" style={{width: "13%"}}>ผู้ใหญ<br />(ห้องละ 2-3 ท่าน)</th>
                                                        <th data-v-e5314ee4="" data-v-fa446876="" style={{width: "13%"}}>เด็กต่ำกว่า 12 ปี <br />พร้อมเตียง<br />พักรวมกับผู้ใหญ่</th>
                                                        <th data-v-e5314ee4="" data-v-fa446876="" style={{width: "13%"}}>เด็กต่ำกว่า 12 ปี <br />ไม่มีเตียง<br />พักรวมกับผู้ใหญ่</th>
                                                        <th data-v-e5314ee4="" data-v-fa446876="" style={{width: "13%"}}>พักเตียงเดี่ยว</th>
                                                    </tr>
                                                </thead>
                                                <tbody data-v-fa446876="">
                                                    {
                                                        this.props.prices.map((item) => {
                                                            return <tr key={item.id} data-v-e5314ee4="" data-v-fa446876="">
                                                                <td data-v-e5314ee4="" data-v-fa446876="" >
                                                                    <div data-v-c49495fc="" data-v-e5314ee4="" data-variant="2022-07-13" className="pg_period-date" data-v-fa446876="">
                                                                        <div data-v-c49495fc="" className="pg_period-date__long-holiday">
                                                                            <span data-v-c49495fc="" className="pg_text-black-3">{item.description}</span>
                                                                        </div>
                                                                        <div data-v-c49495fc="" className="pg_period-date__date-container">

                                                                            <div data-v-c49495fc="" className="pg_period-date__date pg_period-date__date--left">
                                                                                <i className="fas fa-plane-departure"></i>{moment(item.start).format("dd Do MMMM YY")}
                                                                            </div>
                                                                            <div data-v-c49495fc="" className="pg_period-date__date pg_period-date__date--right">
                                                                                <i className="fas fa-plane-arrival"></i>{moment(item.end).format("dd Do MMMM YY")}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div data-v-521ec37a="" data-v-e5314ee4="" className="pg_remaining-seat d-flex" data-v-fa446876=""></div>
                                                                </td>  
                                                                <td data-v-e5314ee4="" data-v-fa446876="">
                                                                    <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                        <div data-v-155a0a98="" className={`pg_period-AVBL ${item.limit-item.book>0?'':'full'}`}>
                                                                            {item.limit-item.book>0? <NumberFormat value={item.limit-item.book} displayType={'text'}  />:"เต็ม"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-v-e5314ee4="" data-v-fa446876="">
                                                                    <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                        <label className="form-label pg_lable text-left">รวมตั๋ว</label>
                                                                        <div data-v-155a0a98="" className="pg_period-price__price">
                                                                            {<NumberFormat value={item.adult_price} displayType={'text'} thousandSeparator={true} />}

                                                                        </div>
                                                                        <label className="form-label pg_lable text-left">ไม่รวมตั๋ว</label>
                                                                        <div data-v-155a0a98="" className="pg_period-price__price">
                                                                            {<NumberFormat value={item.adult_price_noticket} displayType={'text'} thousandSeparator={true} />}

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-v-e5314ee4="" data-v-fa446876="">
                                                                    <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                        <label className="form-label pg_lable text-left">รวมตั๋ว</label>
                                                                        <div data-v-155a0a98="" className="pg_period-price__price">
                                                                            {
                                                                                <NumberFormat value={item.children_price} displayType={'text'} thousandSeparator={true} />
                                                                                //   (item.children_price).toFixed(2).replace('/\d(?=(\d{3})+\.)/g', '$&,')
                                                                            }

                                                                        </div>
                                                                        <label className="form-label pg_lable text-left">ไม่รวมตั๋ว</label>
                                                                        <div data-v-155a0a98="" className="pg_period-price__price">
                                                                            {
                                                                                <NumberFormat value={item.children_price_noticket} displayType={'text'} thousandSeparator={true} />
                                                                                //   (item.children_price).toFixed(2).replace('/\d(?=(\d{3})+\.)/g', '$&,')
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-v-e5314ee4="" data-v-fa446876="">
                                                                    <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                        <label className="form-label pg_lable text-left">รวมตั๋ว</label>
                                                                        <div data-v-155a0a98="" className="pg_period-price__price">
                                                                            {
                                                                                <NumberFormat value={item.children_not_bed} displayType={'text'} thousandSeparator={true} />
                                                                                //   (item.children_price).toFixed(2).replace('/\d(?=(\d{3})+\.)/g', '$&,')
                                                                            }

                                                                        </div>
                                                                        <label className="form-label pg_lable text-left">ไม่รวมตั๋ว</label>
                                                                        <div data-v-155a0a98="" className="pg_period-price__price">
                                                                            {
                                                                                <NumberFormat value={item.children_not_bed_noticket} displayType={'text'} thousandSeparator={true} />
                                                                                //   (item.children_price).toFixed(2).replace('/\d(?=(\d{3})+\.)/g', '$&,')
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-v-e5314ee4="" data-v-fa446876="">
                                                                    <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                                        <label className="form-label pg_lable text-left">รวมตั๋ว</label>
                                                                        <div data-v-155a0a98="" className="pg_period-price__add">
                                                                            {
                                                                                <NumberFormat value={item.single_bedded} displayType={'text'} thousandSeparator={true} />
                                                                                //   (item.children_price).toFixed(2).replace('/\d(?=(\d{3})+\.)/g', '$&,')
                                                                            }
                                                                        </div>
                                                                        <label className="form-label pg_lable text-left">ไม่รวมตั๋ว</label>
                                                                        <div data-v-155a0a98="" className="pg_period-price__add">
                                                                            {
                                                                                <NumberFormat value={item.single_bedded_noticket} displayType={'text'} thousandSeparator={true} />
                                                                                //   (item.children_price).toFixed(2).replace('/\d(?=(\d{3})+\.)/g', '$&,')
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <a
                                        data-v-e5314ee4=""
                                        // href="https://tourdoc.s3.amazonaws.com/uploads/tour/additional_attachment/12893/tourkrub_12893.pdf?v=1597740865"
                                        href={this.props.pdf_file}
                                        className="pg_section-period__pdf-btn-link mt-4 pg_button pg_button--color-secondary-outline pg_button--size-md is-type-text"
                                        target="_blank"
                                    >
                                        <span className="pg_button__ripple-container"></span>
                                        <span className="pg_button__container">
                                            <span className="pg_button__text">
                                                ดาวน์โหลดรายละเอียดทัวร์นี้ (PDF)
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div data-v-0348ea44="" className="col-lg-4"></div>
                    </div>
                </div>
            </section>
        return (
            content
        );

    }

}
export class Header extends Component {
    render() {
        const groupByCategory = this.props.countries.reduce((group,country) => {
            const {country_name} = country;
            group[country_name] = group[country_name] ?? [];
            group[country_name].push(country);
            return group;
        },{});
        let info = "";

        const content =
            <div className="row mt-1" data-v-0348ea44="">
                <div className="col-12" data-v-0348ea44="">
                    <div data-v-0348ea44="">
                        <div data-v-00e2d612="" data-v-0348ea44="" className="pg_card-tour" type="vertical">
                            <div data-v-00e2d612="" className="pg_card-tour__card pg_card-tour__card--vertical">
                                <div className="container">
                                    <div className="row">
                                        {/* Header */}
                                        <div data-v-00e2d612="" title={this.props.description} className="col-12 p-0 pg_card-tour__header">
                                            <div data-v-d8309bf2="" data-v-00e2d612="" className="pg_card-tour-header d-lg-flex">
                                                <div data-v-d8309bf2="" className="pg_card-tour-header__tag-content">
                                                    <div data-v-d8309bf2="" className="pg_card-tour-header__tour-type">
                                                        {
                                                            this.props.name
                                                        }
                                                    </div>
                                                </div>
                                                <div data-v-d8309bf2="" className="pg_card-tour-header__tour-id pg_card-tour-header__tour-id--grey">
                                                    รหัส {this.props.code
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* Gallery */}
                                        <div data-v-3a1a6b1d="" data-v-0348ea44="" className="col-12 col-lg-8 p-0 pg_gallery">
                                            <div data-v-3a1a6b1d="" className="pg_gallery__card">
                                                <div data-v-3a1a6b1d="" className="pg_gallery__header">
                                                    <div data-v-3a1a6b1d="" className="pg_gallery__image-container">
                                                        <div data-v-3a1a6b1d="" className="swiper-container-initialized swiper-container-horizontal">
                                                            {
                                                                <Swiper style={{height: "100%",width: "100%"}}
                                                                    spaceBetween={50}
                                                                    slidesPerView={1}
                                                                >
                                                                    {
                                                                        this.props.images.map((img,index) =>
                                                                            <SwiperSlide key={index} style={{height: "100%",width: "100%"}}>
                                                                                <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture pg_picture--ratio-4x3" style={{width: "100%",height: "100%",overflow: "hidden"}} >
                                                                                    <img
                                                                                        src={img.display ? img.display : img.path}
                                                                                        className="v-lazy-image v-lazy-image-loaded"
                                                                                        alt={img.desc}
                                                                                    />
                                                                                </picture>
                                                                            </SwiperSlide>
                                                                        )
                                                                    }

                                                                </Swiper>
                                                            }
                                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Info */}
                                        <div data-v-00e2d612="" className="col-12 col-lg-4 pg_card-tour__content">
                                            <div data-v-03700961="" data-v-00e2d612="" className="pg_card-tour-scoring-tag pg_card-tour__main-tag-container">
                                            </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-item" style={{alignItems:"start"}}>
                                                 <div data-v-00e2d612="" className="pg_card-tour__detail-icon">
                                                    <i data-v-00e2d612="" className="fas fa-map-marker-alt"></i>
                                                </div>
                                                <span data-v-00e2d612="" className="pg_card-tour__name-text">
                                                    {this.props.caption}
                                                </span>
                                            </div>
                                            {/* <div data-v-00e2d612="" className="pg_card-tour__detail-item">
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-icon" >
                                                </div>
                                                <span data-v-00e2d612="" id="tour-route-name" className="pg_card-tour__detail-text">
                                                    {Object.keys(groupByCategory).map(c => {
                                                        info += c + ' : '
                                                        groupByCategory[c].map((p,i) => {
                                                            info += p.district_name + (i != groupByCategory[c].length - 1 ? ',' : ' ')
                                                        });

                                                    })}
                                                    {info}
                                                </span>
                                            </div> */}
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-item">
                                                <div data-v-00e2d612="" className="pg_card-tour__detail-icon">
                                                    <i className="fas fa-clock"></i>
                                                </div>
                                                <div data-v-00e2d612="" className="pg_card-tour__detail-text">
                                                    {this.props.day + " วัน " + this.props.night + " คืน"}
                                                </div>
                                            </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-item pg_card-tour__detail-item--airline">
                                                <div data-v-00e2d612="" className="pg_card-tour__detail-icon pg_card-tour__detail-icon--airline">
                                                    <i className="fas fa-calendar-alt"></i>
                                                </div>
                                                <div data-v-00e2d612="" className="pg_card-tour__detail-text pg_card-tour__detail-text--month vue-line-clamp" >
                                                    <span data-v-fa3a8992="" data-v-00e2d612="" className="pg_month">
                                                        <div data-v-fa3a8992="" className="d-flex">

                                                            <span data-v-fa3a8992="" className="pg_month__item">
                                                                {this.props.month}
                                                            </span>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__scoring-container d-flex justify-content-between">
                                                <div data-v-00e2d612="" className="pg_card-tour__detail-item pg_card-tour__detail-item--airline">
                                                    <div data-v-00e2d612="" className="pg_card-tour__detail-icon pg_card-tour__detail-icon--airline">
                                                        <i className="fas fa-plane-departure"></i>
                                                    </div>
                                                    <div data-v-00e2d612="" className="pg_card-tour__detail-text">
                                                        {this.props.airlineName}
                                                    </div>
                                                </div>
                                                <div data-v-00e2d612="" className="pg_card-tour__scoring-item">
                                                    <picture data-v-713ff273="" data-v-00e2d612="" className="pg_card-tour__scoring-airline-image pg_picture">
                                                        <img data-v-713ff273="" src={this.props.airlineLogo} alt="Thai Smile" />
                                                    </picture>
                                                </div>
                                            </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__blank d-none d-lg-flex"></div>
                                            <div data-v-00e2d612="" className="pg_card-tour__price-container mb-2">
                                                <div data-v-00e2d612="" className="d-inline float-right">
                                                    <div data-v-5c9d92ab="" data-v-00e2d612="" className="pg_price">
                                                        <div data-v-5c9d92ab="" className="pg_price__original-outer">
                                                            <div data-v-5c9d92ab="" className="pg_price__original"></div>
                                                        </div>
                                                        <div data-v-5c9d92ab="" className="pg_price__final">
                                                            {/* <span data-v-5c9d92ab="" className="pg_price__prefix">เริ่มต้น</span> */}
                                                            {this.props.startPrice}.-
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__btn-contack">
                                                <div data-v-00e2d612="" className="d-flex justify-content-center col-12">
                                                    <div data-v-00e2d612="" className="pg_card-tour__button-section">
                                                        <button data-v-00e2d612="" className="pg_box-shadow-4 pg_card-tour__button pg_button pg_button--color-primary pg_button--size-lg is-type-text">
                                                            <span className="pg_button__ripple-container"></span>
                                                            <span className="pg_button__container">
                                                                <span className="pg_button__text">
                                                                    จองผ่านเว็บ
                                                                </span>
                                                            </span>
                                                        </button>
                                                        <div data-v-00e2d612="" className="d-flex justify-content-between mt-2">
                                                            <button data-v-00e2d612="" className="pg_box-shadow-4 pg_card-tour__button mr-1 pg_button pg_button--color-secondary-outline pg_button--size-md is-type-text">
                                                                <span className="pg_button__ripple-container"></span>
                                                                <span className="pg_button__container">
                                                                    <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-phone" ></i></span>
                                                                    <span className="pg_button__text">
                                                                        โทรจอง
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <button data-v-00e2d612="" className="pg_box-shadow-4 pg_card-tour__button ml-1 pg_button pg_button--color-line-outline pg_button--size-md is-type-text">
                                                                <span className="pg_button__ripple-container"></span>
                                                                <span className="pg_button__container">
                                                                    <span className="pg_button__icon-wrapper"><i className="pg_button__icon fab fa-line" ></i></span>
                                                                    <span className="pg_button__text">
                                                                        จองไลน์
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Button  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        return (
            content
        );

    }
}
