import React, { Component } from 'react';

export class ArticleCard extends Component {

 
  render () {
    
    return (
        this.props.type =="1"?
        <div className="article-card-1 row">
        <div className="article-card-IMG col-md-6">
          <img src={require('../img/pic.jpg')}></img>
        </div>
        <div className="article-card-info  col-md-6">
          <div className="article-card-author row">
            <div className="col-md-1 card-author-avatar">
              <div className="article-card-author-avatar"><img src={require('../img/AvatorImg.png')}></img></div>
            </div>
            <div className="col-md-4 card-author-info">
              <div className="article-card-author-name">Admin</div>
              <div className="article-card-last-update">Jul 9</div>
            </div>
          </div>
          <div className="article-card-title">เตรียมตัวให้พร้อมก่อนออกเดินทางท่องเที่ยว</div>
          <div className="article-card-detail">หากพูดถึงการเดินทางไปเที่ยวทะเลในประเทศไทย เชื่อว่าใครหลายคนคงนึกถึงเกาะสวรรค์กับหาดทรายสวยๆ ในภาคใต้บ้านเราหนึ่งในจังหวัดที่ได้รับความนิยม...</div>
          <div className="article-card-footer"><a href="http://ants.co.th"> Read mord</a></div>
        </div>
      </div>
      :
          <div className="article-card-2 row"> 
            <div className={"article-card-IMG col-md-"+(this.props.type=="2"?"6":"12")}>
            <img src={require('../img/pic_'+this.props.id+'.jpg')}></img>
            </div>
            <div className={"article-card-info  col-md-"+(this.props.type=="2"?"6":"12")}>
                <div className="article-card-author row">
                  <div className="col-md-1 card-author-avatar">
                    <div className="article-card-author-avatar"><img src={require('../img/AvatorImg.png')}></img></div>
                  </div>
                  <div className="col-md-4 card-author-info">
                    <div className="article-card-author-name">admin </div>
                    <div className="article-card-last-update">Jul9</div>
                  </div>
                </div>
                <div className="article-card-title">เตรียมตัวให้พร้อมก่อนออกเดินทางท่องเที่ยว</div>
                <div className="article-card-detail">หากพูดถึงการเดินทางไปเที่ยวทะเลในประเทศไทย เชื่อว่าใครหลายคนคงนึกถึงเกาะสวรรค์กับหาดทรายสวยๆ ในภาคใต้บ้านเราหนึ่งในจังหวัดที่ได้รับความนิยม...</div>
                <div className="article-card-footer"><a href="http://ants.co.th"> Read mord</a></div>
            </div>
          </div>
    );
  }

  async populateWeatherData() {
    const response = await fetch('Aricle');
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
