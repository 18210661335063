import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Program } from './components/Program';
import { Program2 } from './components/Program-2';
import {Insentive} from './components/Insentive';
import {AboutUs} from './components/AboutUs';
import {Sendbox} from './components/Phuketsendbox'
import {Console} from './components/Console'


import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/program/:id' component={Program} />
        <Route path='/program2' component={Program2} />
        <Route path='/insentive' component={Insentive} />
        <Route path='/AboutUs' component={AboutUs}/>
        <Route path='/phuketsendbox' component={Sendbox}/>
        <Route path='/console' component={Console}/>
      </Layout>
    )
  }
}
