import React, { Component } from 'react';

export class ImgCard extends Component {
  render () {
    return (
      <div className="article-card-3 row"> 
      <div className={"article-card-IMG col-md-12"}>
         <img src={require('../img/img_art_'+this.props.id+'.png')} ></img>
         <div className="image-card-info"> 
         <div className="article-card-title"> 
              {this.props.id=="1"?"SUMMER":this.props.id=="2"?"WINTER":this.props.id=="3"?"SPRING":"AUTUMN"}
         </div>
         <div className="article-card-detail"> 
              {this.props.id=="1"?"ความงดงามของฤดูร้อน":this.props.id=="2"?"หิมะในฤดูหนาว":this.props.id=="3"?"ใบไม้ผลิสวยงามอย่างไร":"ฤดูใบไม้ร่วง"}
         </div>
         <div className="article-card-read"> 
            <a href="">
              Read more
            </a>
         </div>
         </div>
      </div>
    </div>
    );
  }
}
