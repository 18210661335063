import React,{Component} from 'react';
import {Container} from 'reactstrap';
import {NavMenu} from './NavMenu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome' 
import { faPhone } from '@fortawesome/free-solid-svg-icons'
export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    var p = ["ทัวร์ในประเทศ","ฟิลปปินล์","เยอรมัน","สวีเดน","ญี่ปุ่น","อินเดีย","สโลวาเกีย","เดนมาร์ก","จีน","มัลดีฟล์","สาธารณรัฐเชค","ตุรกี","เวียดนาม","บรูไน","สเปน","ดูไบ","พม่า","เนปาล","อังกฤษ","จอร์เจีย","กัมพูชา","โปรตุเกส","เบลเยีียม","แอฟฟรีกาใต้","มาเลเซีย","โครเอเชีย","เนเธอร์แลนด์","สหรัฐอเมริกา","สิงค์โปร์","อิตาลี","ลักเซมเบิร์ก","แคนนาดา","ไต้หวัน","สวิตเซอร์แลนด์","รัสเซีย","อียิปต์","เกาหลี","ฝรั่งเศส","ฟินแลนด์","ออสเตรเลีย","ฮ่องกง","ออสเตรีย","ไอซ์แลนด์","นิวซีแลนด์","อินโดนีเซีย","ฮังการี","นอร์เวย์",];
    
    var d = new Date();
    var year = d.getFullYear();
    return (
      <div>
        <NavMenu />
        <div className="container-fulid">
          {this.props.children}</div>
        <div> 
          <div id="arcontactus" className="arcontactus-widget arcontactus-message right sm ui-draggable ui-draggable-handle active open" >
          
          <div className="messangers-block arcuAnimated lg has-header show-messageners-block">
            <div className="arcu-menu-header">คุณต้องการติดต่อเราอย่างไร<div className="arcu-header-close">
            
            </div>
            </div>
            <div className="messangers-list-container">
              <ul className="messangers-list rounded-items">
                <li>
                  <a className="messanger msg-item-line" id="msg-item-10" rel="nofollow noopener" href="https://line.me/R/ti/p/%40dunlopillothai" target="_blank">
                    <span>
                    
                    </span>
                    <div className="arcu-item-label">
                      <div className="arcu-item-title">LINE@</div>
                      <div className="arcu-item-subtitle">@dunlopillothai</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="messanger msg-item-facebook-messenger" id="msg-item-1" rel="nofollow noopener" href="https://m.me/dunlopillothailand" target="_blank">
                    <span >
                     
                    </span>
                    <div className="arcu-item-label">
                      <div className="arcu-item-title">Messenger</div>
                      <div className="arcu-item-subtitle">@dunlopillothailand</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="messanger msg-item-envelope" id="msg-item-6" rel="nofollow noopener" href="mailto:cs@support.dunlopillo.co.th" target="_blank">
                    <span >
                      
                    </span>
                    <div className="arcu-item-label">
                      <div className="arcu-item-title">ส่งอีเมลถึงเรา (Email us)</div>
                      <div className="arcu-item-subtitle">cs@support.dunlopillo.co.th</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a className="messanger msg-item-phone" id="msg-item-7" rel="nofollow noopener" href="tel:+66636646150" target="_blank">
                    <span >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                        </path>
                      </svg>
                    </span>
                    <div className="arcu-item-label">
                      <div className="arcu-item-title">สอบถามข้อมูลเพิ่มเติม</div>
                      <div className="arcu-item-subtitle">0636646150</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="contact" >
            <div className="static">

              <i className="fa fa-comments">
              </i>
            </div>
            
            <div className="static">
              <FontAwesomeIcon icon={faPhone} size="lg" />
            </div>
            <div className="static">
            <i className="fab fa-line"></i>
            </div>
            
            <div className="static">
            <i className="fab fa-facebook-square"></i>
            </div>
          </div>
          
          <div className="upToTop" onClick={e => {
            var elmnt = document.getElementById("navbar-main");
            elmnt.scrollIntoView(200);
          }}>
            <i className="fa fa-chevron-up" >
            </i>
          </div>
          </div>
        </div>
        <div className="container_footer">
          <div className="footer row  m-0 justify-content-md-center">
            <div className="col-md-6">
              <div className="addr-name">MAIN OFFICE</div>
              <div className="addr-detail">
                <p>ANT JOURNEY HOUSE CO., LTD.</p>
                <p>Organization type</p>
                <p>COMPANY LIMITED</p>
                <p>5/1, FLOOR 1, SOI NARADHIWAT RAJANAGARINDRA 10,</p>
                <p>NARADHIWAS RAJANAGARINDRA, THUNG WAT DON,</p>
                <p> SATHORN, BANGKOK 10120</p>
              </div>
              <div className="dbd_regis mb-3-fix mb-lg-0">
                <a style={{width: "100%"}} className="dbd_regis-btn">
                  <div className="col-6 mr-lg-2-fix mb-2 mb-lg-0">
                    <img className="dbd_regis-img" src="https://antstoragedata.blob.core.windows.net/webimage/img-regis.png">
                    </img>
                  </div>
                  <div className="dbd_regis-text col-6">
                    เลขที่ใบอนุญาต
                    <strong style={{fontSize: "1.5em"}}> 11/10538</strong>
                  </div>
                </a>
              </div>
              <div className="social">
                <ul>
                  <li>
                  <img className="img_contact" src={require('../img/line.png')}></img>
                  </li>
                  <li>
                  <img className="img_contact" src={require('../img/facebook.png')}></img>
                  </li>
                  <li>
                  <img className="img_contact" src={require('../img/instagram.png')}></img>
                  </li>
                  <li>
                  <img className="img_contact" src={require('../img/email.png')}></img>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5">
              <div className="footer-all-pack-name"> แพคเกจทัวร์ ทั้งหมด</div>
              <div className="footer-pack-list">
                <div className="col-md4">
                  <ul className="pack-list">
                    {
                      p.map((p,i) => <li key={i}>{p}</li>)
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12 copyright">Copyright © {year} ANT Ltd. All rights reserved </div>
          </div>
        </div>
      </div>
    );
  }
}
