import React, { Component } from 'react';

export class TimeLineCard extends Component {
  constructor(props) {
    super(props);
    this.state = { showdetail: false };
    this.handleClick = this.handleClick.bind(this);
  }
  // componentWillReceiveProps(nextProps) {
  //   // You don't have to do this check first, but it can help prevent an unneeded render
  //   if (nextProps.showdetail !== this.state.showdetail) {
  //     this.setState({ showdetail: nextProps.showdetail });
  //   }
  // }
  handleClick() {
    this.setState(state => ({
      showdetail: !state.showdetail
    }));
  }
  render () {
    var HtmlToReactParser = require('html-to-react').Parser;
     
    var htmlInput = '<div><h1>Title</h1><p>A paragraph</p></div>';
    var htmlToReactParser = new HtmlToReactParser();
    var reactElement = htmlToReactParser.parse(htmlInput);
    return (
      <div data-v-00202ae5="" className="mb-2">
      <div data-v-a829f288="" data-v-00202ae5="" className={"pg_collapse-itinerary "+(this.state.showdetail?"":"is-hide")} id="itinerary_1" style={{height:(this.state.showdetail?"auto":"90px")}}>
          <div data-v-a829f288="" className="pg_collapse-itinerary__wrapper"  style={{height:(this.state.showdetail?"auto":"90px")}}>
              <div data-v-a829f288="" className="pg_collapse-itinerary__header">
                  <div data-v-a829f288="" className="pg_collapse-itinerary__day"> 
                   <div className="pg_collapse-itinerary__day__text">วันที่ {this.props.day}</div>
                   <div data-v-a829f288="" onClick={this.handleClick} className="pg_collapse-itinerary__collapse-icon">
                     <i className={"fas fa-chevron-circle-"+(this.state.showdetail?"down":"up")}></i>
                   </div>
                  </div>
                  <div data-v-a829f288="" className="pg_collapse-itinerary__highlight">
                     { 
                     this.props.places
                     } 
                 </div>
                  
              </div>
              <div data-v-a829f288="" className="pg_collapse-itinerary__body">
                  <div data-v-a829f288="" className="pg_collapse-itinerary__content" suppressContentEditableWarning={true}>
                        {
                          htmlToReactParser.parse(this.props.detail)
                        }
                  </div>
                  <div data-v-a829f288="" className="pg_collapse-itinerary__footer">
                      <div data-v-a829f288="" onClick={this.handleClick} className="pg_collapse-itinerary__footer-button">
                          ย่อ
                          <i data-v-a829f288="" className="pg_collapse-itinerary__footer-button-icon pg_icon-st-arrow-up"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
  }

}
