import React,{Component,useState } from 'react';
import {Collapse,Container,Navbar,NavbarBrand,NavbarToggler,NavItem,NavLink,Dropdown,DropdownToggle,DropdownMenu,DropdownItem} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../console.scss'

export class NavMenuLeft extends Component {

  constructor(props) {
    super(props);
    console.log(Link);
    this.state = {
      collapsed: true,
      showSecond: 0,
      ToolsToggle:false
    };
  }
componentDidMount(){
  let path = window.location.pathname;
  console.log(path);
  if(path=="/console/programmanagement"||path=="/console/programmanagement"){
    this.setState({ToolsToggle:true});
  }
  else{
    this.setState({ToolsToggle:false});
    console.log("test");
  }
}
  setDropdownOpen(t){
    var schema = {...this.state}
    schema[t] = !schema[t];;
    this.setState(schema)
  }

  render() {
   
  const {ToolsToggle}= this.state;

    return (
      <Navbar id="navbar-main" className={"navbar-expand-md navbar-toggleable-md ng-white mb-3 "} >
        <Container>
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse " isOpen={!this.state.collapsed} navbar>
            <ul className="nav flex-grow" >
              <NavItem>
                <NavLink tag={Link} className="NavItemLeftLink" to="/console">Console home</NavLink>
              </NavItem>
              <Dropdown nav isOpen={ToolsToggle}  toggle={()=>true}>
                <DropdownToggle className="NavItemLeftLink" onClick={()=>this.setDropdownOpen("ToolsToggle")} nav caret>
                  Tools
                </DropdownToggle>
                <DropdownMenu className="NavDropdownMenu">
                <DropdownItem tag={Link} className="NavSubItemLeftLink" to="/console/programmanagement">Programs</DropdownItem>
                <DropdownItem tag={Link} className="NavSubItemLeftLink" to="/console/hotelmanagement">Hotel</DropdownItem>
                <DropdownItem tag={Link} className="NavSubItemLeftLink" to="/console/places">Places</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
