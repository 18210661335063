import React,{Component} from 'react';
import {ArticleCard} from './ArticleCard';
import {ImgCard} from './ImgCard';
import {PackageCard} from './PackageCard';
import {CardPackage} from './CardPackage';
import { Link } from "react-router-dom";
import datemoment from 'moment';
import {Swiper,SwiperSlide,EffectFade} from 'swiper/react';
import SwiperCore,{Autoplay} from 'swiper/core';
import { Pagination, Navigation } from "swiper";
import Clock from 'react-clock';
import moment from '../img/impressive_moment_icon.svg'
import 'react-clock/dist/Clock.css';
import 'swiper/swiper.scss';
SwiperCore.use([Autoplay]);

export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.state = {forecasts: [],loading: true,aloading:true,eloading:true,Time: new Date(),windowWidth: window.innerWidth,
            tourlist: [{},{},{}],
            asianlist:[{},{},{}],
            eurolist:[{},{},{}]};
    this.handleResize = this.handleResize.bind(this);
    this.setZoneTime = this.setZoneTime.bind(this);
  }

  componentDidMount() {
    this.populateWeatherData();
    setInterval(() => this.setState({Time: new Date()}),1000);
    window.addEventListener("resize",this.handleResize);

    this.GetDataList('','','3','');
    this.GetDataList('','','2','');
    this.GetDataList('','','1','');
  }

  componentWillUnmount() {
    window.addEventListener("resize",this.handleResize);
  }

  handleResize = (e) => {
    this.setState({windowWidth: window.innerWidth});
    console.log(window.innerWidth);
  };
  async populateWeatherData() {
    const response = await fetch('api/article');
    const data = await response.json();
    this.setState({forecasts: data,loading: false});
  }
  setZoneTime = (h) => {

    let dd = new Date();
    dd.setTime(dd.getTime() + h * 60 * 60 * 1000);
    return dd;
  }
  GetDataList(code,name,country_id,province_id) {
    this.setState({loading: true});
    this.setState({aloading: true});
    this.setState({eloading: true});
    fetch(`api/ProgramList?country_id=${country_id}&province_id=${province_id}&code=${code}&name=${name}`,{
        method: 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then(function (response) {
            if (response.ok) {
                response.json().then(function (json) {
                    if(country_id=='3') { this.setState({tourlist: json,loading: false,PageState: "L"});}
                    else if(country_id=='2'){this.setState({asianlist: json,aloading: false,PageState: "L"});}
                    else if(country_id=='1'){this.setState({eurolist: json,eloading: false,PageState: "L"});}
                }.bind(this))
            }

            else {
                response.text().then(function (text) {
                    console.log('error ',text);

                });
            }
        }.bind(this))
        .catch(function (error) {
            console.log('Request failed',error);
        });
}
  static renderForecastsTable(forecasts) {
    var HtmlToReactParser = require('html-to-react').Parser;

    var htmlInput = '<div><h1>Title</h1><p>A paragraph</p></div>';
    var htmlToReactParser = new HtmlToReactParser();
    var reactElement = htmlToReactParser.parse(htmlInput);
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>id</th>
            <th>Name</th>
            <th>Capture</th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody>
          {console.log(forecasts)}
          {forecasts.map(forecast =>
            <tr key={forecast.ART_ID}>
              <td>{forecast.arT_ID}</td>
              <td>{forecast.arT_NAME}</td>
              <td>{forecast.arT_CAPTION}</td>
              <td>{htmlToReactParser.parse(forecast.arT_DETAIL)}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
  render() {
    // let contents = this.state.loading
    // ? <p><em>Loading...</em></p>
    // : Home.renderForecastsTable(this.state.forecasts);

    const {tourlist,asianlist,eurolist,loading,aloading,eloading} = this.state;
    return (
      <div className="container_home">
        {//contents
        }

        <div className="container_home_1 mb-4">
          <div className="home-article row  m-0">
            <div className="col-md-12 col-sm-12 card-contennar">
              <div data-v-3a1a6b1d="" className="swiper-container-initialized swiper-container-horizontal">
                {
                  <Swiper
                  pagination={true} 
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 7000,
                      disableOnInteraction: false
                    }}
                  >
                    <SwiperSlide>
                      <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture " style={{width: "100%",overflow: "hidden"}} >
                        <img style={{width: "100%"}}
                          src="https://antstoragedata.blob.core.windows.net/webimage/Adv01.png"
                          className="v-lazy-image v-lazy-image-loaded"
                          alt="ฮ่องกง - ฮ่องกง"
                        />
                      </picture>

                    </SwiperSlide>
                    <SwiperSlide>
                      <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture " style={{width: "100%",overflow: "hidden"}} >
                        <img style={{width: "100%"}}
                          src="https://antstoragedata.blob.core.windows.net/webimage/Adv02.png"
                          className="v-lazy-image v-lazy-image-loaded"
                          alt="ฮ่องกง - ฮ่องกง"
                        />
                      </picture>

                    </SwiperSlide>
                    <SwiperSlide>
                      <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture " style={{width: "100%",overflow: "hidden"}} >
                        <img style={{width: "100%"}}
                          src="https://antstoragedata.blob.core.windows.net/webimage/Adv03.png"
                          className="v-lazy-image v-lazy-image-loaded"
                          alt="ฮ่องกง - ฮ่องกง"
                        />
                      </picture>

                    </SwiperSlide>
                  </Swiper>
                }
                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
              </div>
            </div>
            <div className="col-md-12 card-contenner">
              {//<ArticleCard type="1" displayImg={'../img/pic.jpg' } />
              }
            </div>

            <div className="col-md-8 card-contenner">
              {//<ArticleCard type="2" id="1"  displayImg={'../img/pic_1.jpg' } />
              }
              {//<ArticleCard type="2"  id="2"  displayImg={'../img/pic_2.jpg' } />
              }
              {//<ArticleCard type="2"  id="3"  displayImg={'../img/pic_3.jpg' } />
              }
            </div>
            <div className="col-md-4 card-contenner">
              {//<ArticleCard type="3"  id="4"  />
              }
              { /*<div className="article-card-2 row"> 
                  <div className={"article-card-IMG col-md-"+(this.props.type=="2"?"6":"12")} style={{height:'auto'}} >
                    <a href="http://ants.co.th">  <img style={{width:'100%'}} src={require('../img/pic_5.jpg')}></img> </a>
              </div>
              
          </div>*/}
            </div>
          </div>
        </div>


        <div className="container_home mb-5">

          <div className="home-package row m-0">
            <div className="tourTitle">
              <h1>ทัวร์แนะนำประเทศญี่ปุ่น </h1>
            </div><div className="container">
              <div className="row justify-content-md-center">
                <div className="panal-package-list col-12">
                  
                  {
                    <Swiper
                    
                      modules={Navigation}
                      spaceBetween={this.state.windowWidth < 700 ? 0 : 1}
                      slidesPerView={this.state.windowWidth < 700 ? 1 : this.state.windowWidth < 1000 ? 2 : this.state.windowWidth < 1400 ? 3 : 4}
                      loop={true}
                      autoplay={{
                        delay: 6000,
                        disableOnInteraction: false
                      }}
                    >
                      {
                      !loading?
                      tourlist.map((p,index) =>
                      <SwiperSlide><Link to={"/program/"+p.proG_ID} >
                                <CardPackage key={index} onClick={(e) => {} }
                                    id={p.proG_ID}
                                    code={p.proG_CODE}
                                    img={p.proG_COVER_IMG ? p.proG_COVER_IMG : "https://antstoragedata.blob.core.windows.net/webimage/importClick.png"}
                                    name={p.proG_CAPTION}
                                    start={p.sooN_START ? datemoment(p.sooN_START).format("Do MMM YY") : ""}
                                    end={p.sooN_END ? datemoment(p.sooN_END).format("Do MMM YY") : ""}
                                    pricestart={p.proG_START_PRICE ? p.proG_START_PRICE : ""}
                                    hotelscore={p.proG_TOTAL_HOTEL_RATE}
                                    airlineimg={p.airL_LOGO.replace("/Airline","/Airline/icon")} /></Link>

</SwiperSlide>
                            ):
                            tourlist.map((p,index) =><SwiperSlide> <CardPackage loading={true} /> </SwiperSlide> )
                      }
                    </Swiper>
  }
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="container_home mb-5">

          <div className="home-package row m-0">
            <div className="tourTitle">
              <h1>ทัวร์แนะนำโซนเอเชีย </h1>
            </div><div className="container">
              <div className="row justify-content-md-center">
                <div className="panal-package-list col-12">
                  {
                    <Swiper
                    
                      modules={Navigation}
                      spaceBetween={this.state.windowWidth < 700 ? 0 : 1}
                      slidesPerView={this.state.windowWidth < 700 ? 1 : this.state.windowWidth < 1000 ? 2 : this.state.windowWidth < 1400 ? 3 : 4}
                      loop={true}
                      autoplay={{
                        delay: 6000,
                        disableOnInteraction: false
                      }}
                    >
                      {
                      !aloading?
                      asianlist.map((p,index) =>
                      <SwiperSlide><Link to={"/program/"+p.proG_ID} >
                                <CardPackage key={index} onClick={(e) => {} }
                                    id={p.proG_ID}
                                    code={p.proG_CODE}
                                    img={p.proG_COVER_IMG ? p.proG_COVER_IMG : "https://antstoragedata.blob.core.windows.net/webimage/importClick.png"}
                                    name={p.proG_CAPTION}
                                    start={p.sooN_START ? datemoment(p.sooN_START).format("Do MMM YY") : ""}
                                    end={p.sooN_END ? datemoment(p.sooN_END).format("Do MMM YY") : ""}
                                    pricestart={p.proG_START_PRICE ? p.proG_START_PRICE : ""}
                                    hotelscore={p.proG_TOTAL_HOTEL_RATE}
                                    airlineimg={p.airL_LOGO.replace("/Airline","/Airline/icon")} /></Link>

</SwiperSlide>
                            ):
                            asianlist.map((p,index) =><SwiperSlide> <CardPackage loading={true} /> </SwiperSlide> )
                      }
                    </Swiper>
  }
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="container_home mb-5">

          <div className="home-package row m-0">
            <div className="tourTitle">
              <h1>ทัวร์แนะนำโซนยุโรป </h1>
            </div><div className="container">
              <div className="row justify-content-md-center">
                <div className="panal-package-list col-12">
                {
                    <Swiper
                    
                      modules={Navigation}
                      spaceBetween={this.state.windowWidth < 700 ? 0 : 1}
                      slidesPerView={this.state.windowWidth < 700 ? 1 : this.state.windowWidth < 1000 ? 2 : this.state.windowWidth < 1400 ? 3 : 4}
                      loop={true}
                      autoplay={{
                        delay: 6000,
                        disableOnInteraction: false
                      }}
                    >
                      {
                      !eloading?
                      eurolist.map((p,index) =>
                      <SwiperSlide><Link to={"/program/"+p.proG_ID} >
                                <CardPackage key={index} onClick={(e) => {} }
                                    id={p.proG_ID}
                                    code={p.proG_CODE}
                                    img={p.proG_COVER_IMG ? p.proG_COVER_IMG : "https://antstoragedata.blob.core.windows.net/webimage/importClick.png"}
                                    name={p.proG_CAPTION}
                                    start={p.sooN_START ? datemoment(p.sooN_START).format("Do MMM YY") : ""}
                                    end={p.sooN_END ? datemoment(p.sooN_END).format("Do MMM YY") : ""}
                                    pricestart={p.proG_START_PRICE ? p.proG_START_PRICE : ""}
                                    hotelscore={p.proG_TOTAL_HOTEL_RATE}
                                    airlineimg={p.airL_LOGO.replace("/Airline","/Airline/icon")} /></Link>

</SwiperSlide>
                            ):
                            eurolist.map((p,index) =><SwiperSlide> <CardPackage loading={true} /> </SwiperSlide> )
                      }
                    </Swiper>
  }
                </div>  
              </div>  
            </div>
          </div>

        </div>
        <div className="container_home_3">

          <br />
          {/* <div className="container_home">
            <div className="time-panal row m-0">
              <div className="tourTitle">
                <h1>Time Zone </h1>
              </div>
              <br />
              <div className="panal-package-list row col-12">
                <div className="col-3 text-center time-box">
                  <div className='time-name'>JAPAN</div>
                  <Clock value={this.setZoneTime(2)} size={70} />
                  <div className='time-display'>{("0" + this.setZoneTime(2).getHours()).slice(-2)}:{("0" + this.state.Time.getMinutes()).slice(-2)}:{("0" + this.state.Time.getSeconds()).slice(-2)}</div>
                </div>
                <div className="col-3 text-center time-box">
                  <div className='time-name'>THAILAND</div>
                  <Clock value={this.state.Time} size={70} />
                  <div className='time-display'>{this.state.Time.getHours()}:{("0" + this.state.Time.getMinutes()).slice(-2)}:{("0" + this.state.Time.getSeconds()).slice(-2)}</div>
                </div>
                <div className="col-3 text-center time-box">
                  <div className='time-name'>UNITED STATE</div>
                  <Clock value={this.setZoneTime(-11)} size={70} />
                  <div className='time-display'>{("0" + this.setZoneTime(-11).getHours()).slice(-2)}:{("0" + this.state.Time.getMinutes()).slice(-2)}:{("0" + this.state.Time.getSeconds()).slice(-2)}</div>
                </div>
                <div className="col-3 text-center time-box">
                  <div className='time-name'>AUSTRALIA</div>
                  <Clock value={this.setZoneTime(3)} size={70} />
                  <div className='time-display'>{("0" + this.setZoneTime(3).getHours()).slice(-2)}:{("0" +this.state.Time.getMinutes() ).slice(-2)}:{("0" +this.state.Time.getSeconds() ).slice(-2)}</div>
                </div>
              </div>
              <br />
            </div>
          </div> */}
          <div className="home-package row m-0">
            <div className="tourTitle">
              <h1>พาคุณท่องเที่ยวตามฤดูกาล </h1>
            </div>
          </div>
          <div className="home-article_2 row m-0  justify-content-md-center">
            <div className="col-md-5 card-contenner">
              {<ImgCard id="1" />}
            </div>
            <div className="col-md-5 card-contenner">
              {<ImgCard id="2" />}
            </div>
            <div className="col-md-5 card-contenner">
              {<ImgCard id="3" />}
            </div>
            <div className="col-md-5 card-contenner">
              {<ImgCard id="4" />}
            </div>

          </div>
        </div>

        <div className="ad1">
          <img src={require('../img/stripbanner_ant_home_2.jpg')} ></img>
          <div className="adinfo">
            <div className="article-card-detail">
              Intensive Group ท่องเที่ยวในแบบที่คุณเลือกได้ <br /> จัดกรุ๊บเที่ยวส่วนตัว เอ้าท์ติ้งบริษัท ศีกษาดูงานครบจบในที่เดียว
            </div>
            <div className="article-card-read mt-2">
              <a href="">
                View more
              </a>
            </div>
          </div>
        </div>
        <div className="ad2">

          <img src={require('../img/stripbanner_ant_home_1.jpg')} ></img>
          <div className="adinfo">
            <div className="article-card-detail">
              ให้ท่องเที่ยวด้วยตัวเองเป็นเรื่องง่ายๆ<br />เลือกบริการต่างๆในแบบที่คุณเป็น
            </div>
            <div className="article-card-read mt-2">
              <a href="">
                View more
              </a>
            </div>
          </div>
        </div>

        <div className="container_home mt-5 mb-5">

          <div className="home-package row m-0">
            <div className="tourTitle mb-2">
              <h1><img src={moment} alt="moment" width={40} />&nbsp;&nbsp;&nbsp;ภาพประทับใจ </h1>
            </div>
            <div className="col-md-12 col-sm-12 card-contennar">
              <div data-v-3a1a6b1d="" className="swiper-container-initialized swiper-container-horizontal">
                {
                  <Swiper
                    spaceBetween={15}
                    slidesPerView={4}
                    loop={true}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false
                    }}
                  >
                    <SwiperSlide>
                      <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture " style={{width: "100%",overflow: "hidden"}} >
                        <img style={{width: "100%"}}
                          src="https://antstoragedata.blob.core.windows.net/webimage/Impressive/mockup_impressive_1.png"
                          className="v-lazy-image v-lazy-image-loaded"
                          alt="ฮ่องกง - ฮ่องกง"
                        />
                      </picture>

                    </SwiperSlide>
                    <SwiperSlide>
                      <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture " style={{width: "100%",overflow: "hidden"}} >
                        <img style={{width: "100%"}}
                          src="https://antstoragedata.blob.core.windows.net/webimage/Impressive/mockup_impressive_2.png"
                          className="v-lazy-image v-lazy-image-loaded"
                          alt="ฮ่องกง - ฮ่องกง"
                        />
                      </picture>

                    </SwiperSlide>
                    <SwiperSlide>
                      <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture " style={{width: "100%",overflow: "hidden"}} >
                        <img style={{width: "100%"}}
                          src="https://antstoragedata.blob.core.windows.net/webimage/Impressive/mockup_impressive_3.png"
                          className="v-lazy-image v-lazy-image-loaded"
                          alt="ฮ่องกง - ฮ่องกง"
                        />
                      </picture>

                    </SwiperSlide>
                    <SwiperSlide>
                      <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture " style={{width: "100%",overflow: "hidden"}} >
                        <img style={{width: "100%"}}
                          src="https://antstoragedata.blob.core.windows.net/webimage/Impressive/mockup_impressive_4.png"
                          className="v-lazy-image v-lazy-image-loaded"
                          alt="ฮ่องกง - ฮ่องกง"
                        />
                      </picture>

                    </SwiperSlide>
                    <SwiperSlide>
                      <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture " style={{width: "100%",overflow: "hidden"}} >
                        <img style={{width: "100%"}}
                          src="https://antstoragedata.blob.core.windows.net/webimage/Impressive/mockup_impressive_5.png"
                          className="v-lazy-image v-lazy-image-loaded"
                          alt="ฮ่องกง - ฮ่องกง"
                        />
                      </picture>

                    </SwiperSlide>
                  </Swiper>
                }
                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
