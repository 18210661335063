
import React,{Component} from 'react';
export class Modal extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {
    }
    render() {
          const bg ={ width: "100%",
                     height: "100%",
                      color: "black",
                    position: "fixed",
                         top: "0",
                        left: "0",
                    zIndex: "1060",
            backgroundColor: "#0000007a",
            overFlowX:"hidden",
            overFlowY:"auto"

          }
        return (
            
           this.props.show?
            <div style={bg}>
                <div className="modal-dialog modal-pop">
                    <div className="modal-content">
                        <div className={"modal-header modal-"+this.props.icon}>
                            <h5 className="modal-title">{this.props.title}</h5>
                            </div>
                        <div className="modal-body row">
                           <div className={"col-3 modal-icon modal-icon-"+this.props.icon}>
                               {this.props.icon=="confirm"? 
                               <i className="fas fa-info-circle"></i>:
                               (this.props.icon=="success"?<i className="fas fa-check-circle"></i>
                               :<i className="fas fa-exclamation-circle"></i>)
                            }
                           </div> <div className="col-9 modal-message"> <p>{this.props.message}</p></div>
                        </div>
                        <div className="modal-footer">
                           {this.props.icon=="confirm"?<button type="button" className="btn btn-secondary" onClick={e=>this.props.onClickCancel ?this.props.onClickCancel(e):null} data-bs-dismiss="modal">ยกเลิก</button>:null}
                            <button type="button" className={"btn  modal-"+this.props.icon} onClick={e=>this.props.onClickConfirm ?this.props.onClickConfirm(e):null} >ยืนยัน</button>
                        </div>
                    </div>
                </div>
            </div>:<div></div>)
    }
}