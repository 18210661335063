import React, { Component } from 'react';
import { ArticleCard } from './ArticleCard';
import { ImgCard } from './ImgCard';
import { PackageCard } from './PackageCard';
import {TimeLineCard} from './TimeLineCard';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

let lastScrollY = 0;
let ticking = false;
export class Program2 extends Component {

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true,navposition:1, windowWidth: window.innerWidth  };
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.offsets = this.offsets.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener("resize", this.handleResize);
      }
    
      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.addEventListener("resize", this.handleResize);
      }
      offsets(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
     handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
       };
    handleScroll = () => {
        lastScrollY = window.scrollY;
    //console.log(lastScrollY);
    var highlight = this.offsets(document.getElementById("highlight")).top;
    var information = this.offsets(document.getElementById("information")).top;
    var benefits = this.offsets(document.getElementById("benefits")).top;
    var period = this.offsets(document.getElementById("period")).top;
    var nev = 200;
    if(lastScrollY< information-nev){
        this.setState({navposition:1});
    }
    else if(lastScrollY>information-nev && lastScrollY<benefits-nev){
        this.setState({navposition:2});
    }
    else if(lastScrollY>benefits-nev && lastScrollY<period-nev){
        this.setState({navposition:3});
    }
    else if(lastScrollY>period-nev ){
        this.setState({navposition:4});
    }
}
goTo(event,e) {
  var elmnt = document.getElementById(e);
  elmnt.scrollIntoView(200);
    event.preventDefault();
  }
    render() {
        const { windowWidth } = this.state; 
     
            return (
<div className="pg_tours-detail">
  <section data-v-0348ea44="" className="container">
      <nav data-v-72117e1e="" data-v-0348ea44="" aria-label="breadcrumb" className="mt-3 pg_breadcrumb py-3-fix pg_breadcrumb--border">
          <div data-v-72117e1e="" className="container">
              <ul data-v-72117e1e="" className="pg_breadcrumb__container">
                  <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item"><a data-v-72117e1e="" href="/" className="is-link-active is-link-prefetched">หน้าแรก</a></li>
                  <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item"><a data-v-72117e1e="" href="/hongkong-tour" className="is-link-prefetched">ทัวร์ฮ่องกง</a></li>
                  <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item active">
                      #12893
                  </li>
              </ul>
          </div>
      </nav>
  </section>
  <section className="mt-2" data-v-0348ea44="">
    <div className="container" data-v-0348ea44="">
        <div className="row mt-1" data-v-0348ea44="">
            <div className="col-12 col-md-8" data-v-0348ea44="">
            </div>
            <div data-v-0348ea44="" className="col-12 col-md-4 justify-content-end align-items-end d-flex">
                <div data-v-0348ea44="" className="d-flex">
                    <span data-v-595a154c="" data-v-0348ea44="" className="pg_shared">
                      <button data-v-595a154c="" className="pg_box-shadow-4 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text mr-1 mb-1 el-popover__reference" aria-describedby="el-popover-6443" >
                            <span className="pg_button__ripple-container"></span>
                            <span className="pg_button__container">
                                <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-share-alt" ></i></span>
                                <span className="pg_button__text">
                                    แชร์
                                </span>
                            </span>
                        </button>
                    </span>
                    <button data-v-0348ea44="" className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text">
                        <span className="pg_button__ripple-container"></span>
                        <span className="pg_button__container">
                            <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-heart" ></i></span>
                            <span className="pg_button__text">
                                บันทึก
                            </span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div className="row mt-1" data-v-0348ea44="">
            <div className="col-12" data-v-0348ea44="">
                <div data-v-0348ea44="">
                    <div data-v-00e2d612="" data-v-0348ea44="" className="pg_card-tour" type="vertical">
                        <div data-v-00e2d612="" className="pg_card-tour__card pg_card-tour__card--vertical">
                            <div className="container">
                                <div className="row">
                                   {/* Header */}
                                    <div data-v-00e2d612="" title="ทัวร์ฮ่องกง 3 วัน 2 คืน ชมวิวเกาะฮ่องกงเขาวิคตอเรียพีค นั่งกระเช้านองปิง บิน WE" className="col-12 p-0 pg_card-tour__header">
                                        <div data-v-d8309bf2="" data-v-00e2d612="" className="pg_card-tour-header d-lg-flex">
                                            <div data-v-d8309bf2="" className="pg_card-tour-header__tag-content">
                                                <div data-v-d8309bf2="" className="pg_card-tour-header__tour-type">
                                                ทัวร์ฮ่องกง 3 วัน 2 คืน ชมวิวเกาะฮ่องกงเขาวิคตอเรียพีค นั่งกระเช้านองปิง บิน Thai Smile
                                                </div>
                                            </div>
                                            <div data-v-d8309bf2="" className="pg_card-tour-header__tour-id pg_card-tour-header__tour-id--grey">
                                                รหัส 12893
                                            </div>
                                        </div>
                                    </div>
                                  {/* Gallery */}
                                    <div data-v-3a1a6b1d="" data-v-0348ea44="" className="col-12 col-lg-8 p-0 pg_gallery">
                                        <div data-v-3a1a6b1d="" className="pg_gallery__card">
                                            <div data-v-3a1a6b1d="" className="pg_gallery__header">
                                                <div data-v-3a1a6b1d="" className="pg_gallery__image-container">
                                                    <div data-v-3a1a6b1d="" className="swiper-container-initialized swiper-container-horizontal">
                                                    {
                                                        <Swiper
                                                        spaceBetween={50}
                                                        slidesPerView={1}
                                                        onSlideChange={() => console.log('slide change')}
                                                        onSwiper={(swiper) => console.log(swiper)}
                                                    >
                                                        <SwiperSlide> <a data-v-3a1a6b1d="">
                                                                    <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture pg_picture--ratio-4x3" style={{width:"100%",overflow:"hidden"}} >
                                                                        <img
                                                                            src="https://cloudfront.tourkrub.co/resized/0x491/uploads/tour/cover_picture/12893/Giant_Buddha_sitting_on_lotus-min.jpg"
                                                                            className="v-lazy-image v-lazy-image-loaded"
                                                                            alt="ฮ่องกง - ฮ่องกง"
                                                                        />
                                                                    </picture>
                                                                </a>
                                                                <div data-v-3a1a6b1d="" className="pg_gallery__image-name">
                                                                    <span data-v-3a1a6b1d="" className="pg_gallery__text-name">
                                                                        ฮ่องกง - ฮ่องกง
                                                                    </span>
                                                                    <span data-v-3a1a6b1d="" className="pg_gallery__text-name">
                                                                        1/3 | ดูทั้งหมด
                                                                    </span>
                                                                </div>
                                                                <div data-v-3a1a6b1d="" className="pg_gallery__shadow"></div>
                                                        </SwiperSlide>
                                                        <SwiperSlide><a data-v-3a1a6b1d="">
                                                                    <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture pg_picture--ratio-4x3"  style={{width:"100%",overflow:"hidden"}} >
                                                                        <img
                                                                            src="https://cloudfront.tourkrub.co/resized/0x491/uploads/annotation/location_image/17011/HK_Hung_Hom________Station_Lane_____Kwun_Yam_Temple_Mar-2013.JPG"
                                                                            className="v-lazy-image v-lazy-image-loaded"
                                                                            alt="วัดเจ้าแม่กวนอิมฮองฮัม"
                                                                        />
                                                                    </picture>
                                                                </a>
                                                                <div data-v-3a1a6b1d="" className="pg_gallery__image-name">
                                                                    <span data-v-3a1a6b1d="" className="pg_gallery__text-name">
                                                                        วัดเจ้าแม่กวนอิมฮองฮัม
                                                                    </span>
                                                                    <span data-v-3a1a6b1d="" className="pg_gallery__text-name">
                                                                        2/3 | ดูทั้งหมด
                                                                    </span>
                                                                </div>
                                                                <div data-v-3a1a6b1d="" className="pg_gallery__shadow"></div>
                                                            </SwiperSlide>
                                                        <SwiperSlide> 
                                                                <a data-v-3a1a6b1d="">
                                                                    <picture data-v-713ff273="" data-v-3a1a6b1d="" className="pg_picture pg_picture--ratio-4x3" style={{width:"100%",overflow:"hidden"}} >
                                                                        <img src="https://cloudfront.tourkrub.co/resized/0x491/uploads/annotation/location_image/2230/Depositphotos_73111169_original_Fotor.jpg" className="v-lazy-image v-lazy-image-loaded" alt="วัดแชกงหมิว" />
                                                                    </picture>
                                                                </a>
                                                                <div data-v-3a1a6b1d="" className="pg_gallery__image-name">
                                                                    <span data-v-3a1a6b1d="" className="pg_gallery__text-name">
                                                                        วัดแชกงหมิว
                                                                    </span>
                                                                    <span data-v-3a1a6b1d="" className="pg_gallery__text-name">
                                                                        3/3 | ดูทั้งหมด
                                                                    </span>
                                                                </div>
                                                                <div data-v-3a1a6b1d="" className="pg_gallery__shadow"></div></SwiperSlide>
                                                    </Swiper>
                                                    }
                                                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   {/* Info */}
                                    <div data-v-00e2d612="" className="col-12 col-lg-4 pg_card-tour__content">
                                    <div data-v-03700961="" data-v-00e2d612="" className="pg_card-tour-scoring-tag pg_card-tour__main-tag-container">
                                    </div>
                                        <div data-v-00e2d612="" className="pg_card-tour__detail-item">
                                            <span data-v-00e2d612=""  className="pg_card-tour__name-text">
                                                ทัวร์ฮ่องกง 3 วัน 2 คืน ชมวิวเกาะฮ่องกงเขาวิคตอเรียพีค นั่งกระเช้านองปิง บิน Thai Smile
                                            </span>
                                        </div>
                                        <div data-v-00e2d612="" className="pg_card-tour__detail-item">
                                         <div data-v-00e2d612="" className="pg_card-tour__detail-icon">
                                            <i data-v-00e2d612="" className="fas fa-map-marker-alt"></i>
                                         </div>
                                             <span data-v-00e2d612="" id="tour-route-name" className="pg_card-tour__detail-text">
                                            ทัวร์ฮ่องกง - ฮ่องกง
                                            </span>
                                        </div>
                                        <div data-v-00e2d612="" className="pg_card-tour__detail-item">
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-icon">
                                            <i className="fas fa-clock"></i>
                                            </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-text">
                                                3 วัน 2 คืน
                                            </div>
                                        </div>
                                        <div data-v-00e2d612="" className="pg_card-tour__detail-item pg_card-tour__detail-item--airline">
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-icon pg_card-tour__detail-icon--airline">
                                            <i className="fas fa-calendar-alt"></i>
                                            </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-text pg_card-tour__detail-text--month vue-line-clamp" >
                                                <span data-v-fa3a8992="" data-v-00e2d612="" className="pg_month">
                                                    <div data-v-fa3a8992="" className="d-flex">
                                                        
                                                        <span data-v-fa3a8992="" className="pg_month__item">
                                                            ก.ค.
                                                        </span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div data-v-00e2d612="" className="pg_card-tour__scoring-container d-flex justify-content-between">
                                            <div data-v-00e2d612="" className="pg_card-tour__detail-item pg_card-tour__detail-item--airline">
                                                <div data-v-00e2d612="" className="pg_card-tour__detail-icon pg_card-tour__detail-icon--airline">
                                                <i className="fas fa-plane-departure"></i>
                                                </div>
                                                <div data-v-00e2d612="" className="pg_card-tour__detail-text">
                                                    Thai Smile
                                                </div>
                                            </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__scoring-item">
                                                <picture data-v-713ff273="" data-v-00e2d612="" className="pg_card-tour__scoring-airline-image pg_picture">
                                                  
                                                    <img data-v-713ff273="" src="https://cloudfront.tourkrub.co/resized/100x0/uploads/airline/airline_logo/178/THAI_Smile_logo.png" alt="Thai Smile" />
                                                </picture>
                                            </div>
                                        </div>
                                        <div data-v-00e2d612="" className="pg_card-tour__blank d-none d-lg-flex"></div>
                                        <div data-v-00e2d612="" className="pg_card-tour__price-container mb-2">
                                            <div data-v-00e2d612="" className="d-inline float-right">
                                                <div data-v-5c9d92ab="" data-v-00e2d612="" className="pg_price">
                                                    <div data-v-5c9d92ab="" className="pg_price__original-outer">
                                                        <div data-v-5c9d92ab="" className="pg_price__original"></div>
                                                    </div>
                                                    <div data-v-5c9d92ab="" className="pg_price__final">
                                                        <span data-v-5c9d92ab="" className="pg_price__prefix">เริ่มต้น</span>
                                                        13,999
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            <div data-v-00e2d612="" className="pg_card-tour__btn-contack">
                                            <div data-v-00e2d612="" className="d-flex justify-content-center">
                                                <div data-v-00e2d612="" className="pg_card-tour__button-section">
                                                    <button data-v-00e2d612="" className="pg_box-shadow-4 pg_card-tour__button pg_button pg_button--color-primary pg_button--size-lg is-type-text">
                                                        <span className="pg_button__ripple-container"></span>
                                                        <span className="pg_button__container">
                                                            <span className="pg_button__text">
                                                                จองผ่านเว็บ
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <div data-v-00e2d612="" className="d-flex justify-content-between mt-2">
                                                        <button data-v-00e2d612="" className="pg_box-shadow-4 pg_card-tour__button mr-1 pg_button pg_button--color-secondary-outline pg_button--size-md is-type-text">
                                                            <span className="pg_button__ripple-container"></span>
                                                            <span className="pg_button__container">
                                                                <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-phone" ></i></span>
                                                                <span className="pg_button__text">
                                                                    โทรจอง
                                                                </span>
                                                            </span>
                                                        </button>
                                                        <button data-v-00e2d612="" className="pg_box-shadow-4 pg_card-tour__button ml-1 pg_button pg_button--color-line-outline pg_button--size-md is-type-text">
                                                            <span className="pg_button__ripple-container"></span>
                                                            <span className="pg_button__container">
                                                                <span className="pg_button__icon-wrapper"><i className="pg_button__icon fab fa-line" ></i></span>
                                                                <span className="pg_button__text">
                                                                    จองไลน์
                                                                </span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                             {/* Button  */}
                            
                          
                                </div>
                            </div>
                           </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section className=" pt-3" data-v-0348ea44="">
    <div className="pg_tour-detail-content container" data-v-0348ea44="">
        <div data-v-0348ea44="" style={{position: "sticky", top: "84px", zIndex: 15}}>
            <div data-v-0348ea44="" className="pg_tour-detail-content__menu-tour-detail d-none d-lg-block">
                <nav id="nav-pg" data-v-65396502="" data-v-0348ea44="" className="scrollactive-nav pg_menu-tour-detail">
                    <a data-v-65396502="" onClick={e=>this.goTo(e,"highlight")} href="#highlight" data-target-id="highlight" className={"pg_menu-tour-detail__menu scrollactive-item "+(this.state.navposition ==1?"is-active":"")}>
                        ทัวร์นี้มีอะไร
                    </a>
                    <a data-v-65396502="" onClick={e=>this.goTo(e,"information")} href="#information" data-target-id="information" className={"pg_menu-tour-detail__menu scrollactive-item "+(this.state.navposition ==2?"is-active":"")}>
                        ทัวร์นี้เป็นอย่างไร
                    </a>
                    <a data-v-65396502="" onClick={e=>this.goTo(e,"benefits")} href="#benefits" data-target-id="benefits" className={"pg_menu-tour-detail__menu scrollactive-item "+(this.state.navposition ==3?"is-active":"")}>
                        ทัวร์นี้รวมอะไร
                    </a>
                    <a data-v-65396502="" onClick={e=>this.goTo(e,"period")} href="#period" data-target-id="period" className={"pg_menu-tour-detail__menu scrollactive-item "+(this.state.navposition ==4?"is-active":"")}>
                         ทัวร์นี้ไปเมื่อไร
                    </a>
                </nav>
            </div>
        </div>
        <div data-v-0348ea44="" className="row">
            <div data-v-0348ea44="" className="col-12">
                <div data-v-0348ea44="" id="highlight" className="pg_tour-detail-content__section mb-3">
                    <div data-v-0348ea44="" className="pg_tour-detail-content__title mb-3">
                        ทัวร์นี้มีอะไร
                    </div>
                    <div data-v-32f156cd="" data-v-0348ea44="" className="pg_section-highlight">
                        <div data-v-32f156cd="" className="pg_section-highlight__box-container mb-3">
                            <div data-v-32f156cd="" className="pg_section-highlight__box">
                                <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-compass"></i></div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                    ทั่วไป
                                </div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                    ลักษณะการเที่ยว
                                </div>
                            </div>
                            <div data-v-32f156cd="" className="pg_section-highlight__box">
                                <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-utensils"></i></div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                    5 มื้อ
                                </div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                    มื้ออาหาร
                                </div>
                            </div>
                            <div data-v-32f156cd="" className="pg_section-highlight__box">
                                <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-hotel"></i></div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                    ระดับที่พัก
                                </div>
                                <div data-v-32f156cd="">
                                    <div data-v-2466db0a="" data-v-32f156cd="" className="pg_rating pg_section-highlight__rating">
                                        <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i><i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                        <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i><i data-v-2466db0a="" className="pg_rating__star fas fa-star"></i>
                                        <i data-v-2466db0a="" className="pg_rating__star fas fa-star"></i>
                                    </div>
                                </div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                    2 คืน
                                </div>
                            </div>
                            <span data-v-375f029d="" data-v-32f156cd="" className="pg_popup-tour pg_section-highlight__box-popup">
                                <div role="tooltip" id="el-popover-4702" aria-hidden="true" className="el-popover el-popper"  style={{transformOrigin: "center bottom", zIndex: "2001", display: "none"}}>
                                    
                                    <div data-v-375f029d="" className="pg_popup-tour__popup-container">
                                        <div data-v-375f029d="" className="pg_popup-tour__popup-header">
                                            <div data-v-375f029d="" className="pg_popup-tour__popup-title mr-8-fix">
                                                <div data-v-2466db0a="" data-v-32f156cd="" className="pg_rating pg_section-highlight__rating">
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                </div>
                                            </div>
                                            <button data-v-375f029d="" className="pg_popup-tour__close-button d-block d-lg-none pg_button pg_button--color-light pg_button--size-xs is-type-icon pg_button--circle">
                                                <span className="pg_button__ripple-container"></span>
                                                <span className="pg_button__container">
                                                    <span className="pg_button__icon-wrapper"><i className="pg_button__icon pg_icon-st-close" style={{fontSize: "1em"}}></i></span>
                                                </span>
                                            </button>
                                        </div>
                                        <div data-v-32f156cd="" className="pg_section-highlight__text-score"><span data-v-32f156cd="">บินฟูลเซอร์วิส และบินตรง</span></div>
                                    </div>
                                    <div x-arrow="" className="popper__arrow" style={{left: "137.336px"}}></div>
                                </div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box pg_section-highlight__box--in-popup el-popover__reference" aria-describedby="el-popover-4702" >
                                    <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-plane-departure"></i></div>
                                    <div data-v-32f156cd="" className="pg_section-highlight__box-title">คะแนนเที่ยวบิน </div>
                                    <div data-v-32f156cd="">
                                        <div data-v-2466db0a="" data-v-32f156cd="" className="pg_rating pg_section-highlight__rating">
                                            <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i><i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                            <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i><i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                            <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                        </div>
                                    </div>
                                    <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1"></div>
                                </div>
                            </span>
                            <div data-v-32f156cd="" className="pg_section-highlight__box">
                                <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-camera"></i></div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                13
                                </div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                สถานที่ท่องเที่ยว
                                </div>
                            </div>
                            <div data-v-32f156cd="" className="pg_section-highlight__box">
                                <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-hiking"></i></div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                7
                                </div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                กิจกรรม
                                </div>
                            </div>
                            <div data-v-32f156cd="" className="pg_section-highlight__box">
                                <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-shopping-bag"></i></div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                3
                                </div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                ช๊อบปิ้ง
                                </div>
                            </div>
                            <div data-v-32f156cd="" className="pg_section-highlight__box">
                                <div data-v-32f156cd="" className="pg_section-highlight__box-icon"><i className="fas fa-grin"></i></div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-title">
                                ไม่มี
                                </div>
                                <div data-v-32f156cd="" className="pg_section-highlight__box-subtitle mt-1">
                                วันอิสระ
                                </div>
                            </div>
                       
                        </div>
                        <div data-v-b8c390fa="" data-v-32f156cd="" className="pg_slide-highlight mb-4-fix">
                        <Swiper slidesPerView={(windowWidth<400?2:(windowWidth<600?4:6))}  spaceBetween={20} navigation={{
                            nextEl:".pg_slide-highlight__next",prevEl:".pg_slide-highlight__prev"}
                        }
                                                    >
                                                         <SwiperSlide>
                                                         <div data-v-b8c390fa="" className="pg_slide-highlight__slide" >
                                                            <div data-v-b8c390fa="">
                                                                <picture data-v-713ff273="" data-v-b8c390fa="" className="pg_slide-highlight__image pg_picture pg_picture--ratio-4x3 pg_picture--border-radius-8">
                                                                    <img
                                                                        src="https://cloudfront.tourkrub.co/resized/162x121/uploads/annotation/location_image/2270/800px-Hk-Symphony_of_Lights_3420.jpg"
                                                                        className="v-lazy-image v-lazy-image-loaded"
                                                                        alt="item.thai"
                                                                    />
                                                                </picture>
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__label">
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__title mt-2-fix">
                                                                        A SYMPHONY OF LIGHTS
                                                                    </div>
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__subtitle">A SYMPHONY OF LIGHTS</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                         </SwiperSlide>
                                                         <SwiperSlide>
                                                         <div data-v-b8c390fa="" className="pg_slide-highlight__slide">
                                                            <div data-v-b8c390fa="">
                                                                <picture data-v-713ff273="" data-v-b8c390fa="" className="pg_slide-highlight__image pg_picture pg_picture--ratio-4x3 pg_picture--border-radius-8">
                                                                
                                                                    <img
                                                                        src="https://cloudfront.tourkrub.co/resized/162x121/uploads/annotation/location_image/2230/Depositphotos_73111169_original_Fotor.jpg"
                                                                        className="v-lazy-image v-lazy-image-loaded"
                                                                        alt="item.thai"
                                                                    />
                                                                </picture>
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__label">
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__title mt-2-fix">
                                                                        วัดแชกงหมิว
                                                                    </div>
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__subtitle">วัดแชกงหมิว</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                         </SwiperSlide>
                                                         <SwiperSlide>
                                                         <div data-v-b8c390fa="" className="pg_slide-highlight__slide">
                                                            <div data-v-b8c390fa="">
                                                                <picture data-v-713ff273="" data-v-b8c390fa="" className="pg_slide-highlight__image pg_picture pg_picture--ratio-4x3 pg_picture--border-radius-8">
                                                                    <img
                                                                        src="https://cloudfront.tourkrub.co/resized/162x121/uploads/annotation/location_image/2231/Wong_Tai_Sin_Temple_Hongkong-min.jpg"
                                                                        className="v-lazy-image v-lazy-image-loaded"
                                                                        alt="item.thai"
                                                                    />
                                                                </picture>
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__label">
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__title mt-2-fix">
                                                                        วัดหวังต้าเซียน
                                                                    </div>
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__subtitle">วัดหวังต้าเซียน</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                         </SwiperSlide>
                                                         <SwiperSlide>
                                                         <div data-v-b8c390fa="" className="pg_slide-highlight__slide">
                                                         <div data-v-b8c390fa="">
                                                            <picture data-v-713ff273="" data-v-b8c390fa="" className="pg_slide-highlight__image pg_picture pg_picture--ratio-4x3 pg_picture--border-radius-8">
                                                                <img src="https://cloudfront.tourkrub.co/resized/162x121/uploads/annotation/location_image/2239/Hong_Kong_skyline_Fotor.jpg" className="v-lazy-image v-lazy-image-loaded" alt="item.thai" />
                                                            </picture>
                                                            <div data-v-b8c390fa="" className="pg_slide-highlight__label">
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__title mt-2-fix">
                                                                    วิคตอเรียพีค
                                                                </div>
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__subtitle">วิคตอเรียพีค</div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        </SwiperSlide>
                                                         <SwiperSlide>
                                                         <div data-v-b8c390fa="" className="pg_slide-highlight__slide">
                                                            <div data-v-b8c390fa="">
                                                                <picture data-v-713ff273="" data-v-b8c390fa="" className="pg_slide-highlight__image pg_picture pg_picture--ratio-4x3  pg_picture--border-radius-8">
                                                                 <img src="https://cloudfront.tourkrub.co/resized/162x121/uploads/annotation/location_image/2251/HONGKONG-Nong_Ping_360.jpg" className="v-lazy-image v-lazy-image-loaded" alt="item.thai" />
                                                               </picture> 
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__label">
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__title mt-2-fix">
                                                                        กระเช้านองปิง 360 องศา
                                                                    </div> 
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__subtitle"> กระเช้านองปิง 360 องศา</div>
                                                                </div>
                                                           </div>
                                                        </div>
                                                         </SwiperSlide>
                                                         <SwiperSlide>
                                                         <div data-v-b8c390fa="" className="pg_slide-highlight__slide">
                                                         <div data-v-b8c390fa="">
                                                                <picture data-v-713ff273="" data-v-b8c390fa="" className="pg_slide-highlight__image pg_picture pg_picture--ratio-4x3  pg_picture--border-radius-8">
                                                                <img src="https://cloudfront.tourkrub.co/resized/162x121/uploads/annotation/location_image/3150/96DAD00Z_5B1_5D.jpg" className="v-lazy-image v-lazy-image-loaded" alt="item.thai"/>
                                                                </picture> 
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__label">
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__title mt-2-fix">พระพุทธรูปเทียนถาน</div> 
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__subtitle">  พระพุทธรูปเทียนถาน </div>
                                                                </div>
                                                           </div>
                                                            </div>
                                                         </SwiperSlide>
                                                         <SwiperSlide>
                                                         <div data-v-b8c390fa="" className="pg_slide-highlight__slide">
                                                         <div data-v-b8c390fa="">
                                                                <picture data-v-713ff273="" data-v-b8c390fa="" className="pg_slide-highlight__image pg_picture pg_picture--ratio-4x3  pg_picture--border-radius-8">
                                                                 <img src="https://cloudfront.tourkrub.co/resized/162x121/uploads/annotation/location_image/2251/HONGKONG-Nong_Ping_360.jpg" className="v-lazy-image v-lazy-image-loaded" alt="item.thai" />
                                                               </picture> 
                                                                <div data-v-b8c390fa="" className="pg_slide-highlight__label">
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__title mt-2-fix">
                                                                        กระเช้านองปิง 360 องศา
                                                                    </div> 
                                                                    <div data-v-b8c390fa="" className="pg_slide-highlight__subtitle"> กระเช้านองปิง 360 องศา</div>
                                                                </div>
                                                           </div>
                                                            </div>
                                  
                                                         </SwiperSlide>   
                                                        </Swiper>
                            <button data-v-b8c390fa="" className="pg_slide-highlight__prev"  role="button" aria-label="Previous slide" text="<" >
                            <i className="fas fa-angle-left"></i>
                            </button>
                            <button data-v-b8c390fa="" className="pg_slide-highlight__next"  role="button" aria-label="Next slide" text=">">
                            <i className="fas fa-angle-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div data-v-0348ea44="" id="information" className="pg_tour-detail-content__section mb-3">
                    <div data-v-0348ea44="" className="pg_tour-detail-content__title">
                       ทัวร์นี้เป็นอย่างไร
                    </div>
                    <div data-v-27b20e16="" data-v-0348ea44="" className="pg_section-information">
                        <div data-v-27b20e16="" className="pg_section-information__label-container mb-4-fix">
                            <div data-v-27b20e16="" className="pg_section-information__label">
                                เดินทาง 3 วัน 2 คืน
                            </div>
                        </div>
                        <div data-v-27b20e16="" className="pg_section-information__itinerary-container mb-5">
                            <div data-v-00202ae5="" data-v-27b20e16="" className="pg_section-itinerary">
                                <div data-v-00202ae5="" className="pg_section-itinerary__collapse-container">
                                    <TimeLineCard  day="1" places="ท่าอากาศยานสุวรรณภูมิ | ท่าอากาศยานฮ่องกงเช๊กแลปก๊ก | กระเช้านองปิง 360 องศา | พระพุทธรูปเทียนถาน | วัดโป่หลิน | หมู่บ้านวัฒนธรรมนองปิง | ซิตี้ เกท เอาท์เล็ท | สะพานแขวนซิงหมา"
                                      detail="<div >
                                      <p><strong>เช้า</strong></p>

                                      <ul>
                                          <li>
                                              <strong>06.00 น. /08.00 น.</strong> พร้อมกันที่ <strong>สนามบินสุวรรณภูมิ </strong>ชั้น 4 อาคารผู้โดยสารขาออก ประตู 3 เคาน์เตอร์ E สายการบินไทยสไมล์แอร์เวย์ (WE)
                                              พบเจ้าหน้าที่ทำการเช็คอินตั๋ว
                                          </li>
                                          <li>
                                              <strong>09.10 น. /10.05 น.</strong> บินลัดฟ้าสู่ฮ่องกง โดย<strong>เที่ยวบิน WE630</strong> บริการอาหารและเครื่องดื่มบนเครื่อง
                                              (กรุณาเช็คเที่ยวบินในตารางวันเดินทางก่อนทำการจอง เนื่องจากตั้งแต่เดือนตุลาคม ไฟล์ทมีการเปลี่ยนแปลงเวลาบิน
                                          </li>
                                      </ul>

                                      <p><strong>บ่าย</strong></p>

                                      <ul>
                                          <li><strong>12.55 น.</strong> ถึง<strong>สนามบิน CHEK LAP KOK </strong>หลังผ่านพิธีการตรวจคนเข้าเมือง นำท่านออกจากสนามบิน ณ ทางออก Exit B</li>
                                          <li><strong>บริการอาหารกลางวัน ณ ภัตตาคาร</strong></li>
                                          <li>จากนั้นนำท่านเดินทางสู่<strong> สถานีตงชุง </strong>เพื่อขึ้น <strong>กระเช้าลอยฟ้านองปิง 360</strong></li>
                                          <li>
                                              นำท่านขอพร <strong>พระใหญ่เทียนถาน</strong> ณ ลานอธิษฐาน ให้ทุกท่านได้ตั้งจิตอธิษฐานขอพรไม่ว่าจะเป็นเรื่องสุขภาพ การงาน การเงิน เนื่องจากองค์พระเป็นปรางค์ประทานพร
                                              จากลานอธิษฐาน ท่านสามารถเดินขึ้นชมฐานองค์พระได้ โดยจะมีบันไดทอดยาวจำนวน 268 ขั้น บริเวณฐานพระพุทธรูป เป็นที่ประดิษฐานของบรมสารีกธาตุ (พระเขี้ยวแก้ว) (ไม่รวมค่าเข้าชม ท่านละ
                                              60 เหรียญฮ่องกง) หรือชมวิวของเกาะลันเตาในมุมสูงได้
                                          </li>
                                          <li>
                                              นอกจากนี้ท่านยังสามารถเข้าชม <strong>วัดโป่วหลิน</strong> ภายในเป็นที่ประดิษฐานของ พระสัมมาสัมพุทธเจ้า ทั้ง 5 พระองค์ ณ อาคารหมื่นพุทธ
                                              โดยบนหลังคาวัดจะมีสัตว์มงคลตรงมุมของหลังคา ห้ามนำเครื่องเซ่นไหว้ที่ประกอบด้วยเนื้อสัตว์เข้ามาไหว้เป็นอันขาด
                                          </li>
                                          <li>
                                              จากนั้นให้ท่าน อิสระ ณ บริเวณ<strong>หมู่บ้านวัฒนธรรมนองปิง</strong> ให้ท่านได้อิสระเที่ยวชม ร้านค้า ร้านอาหาร
                                              บริเวณหมู่บ้านจำลองแห่งนี้ท่านจะได้พบความสนุกที่สามารถผสมผสานระหว่างความเจริญและวัฒนธรรมดั้งเดิม
                                          </li>
                                          <li>
                                              <strong>หมายเหตุ:</strong>
                                              <em>
                                                  กรณีกระเช้านองปิงปิดซ่อมบำรุง หรือปิดเนื่องจากสภาพอากาศ ทางบริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนเป็นรถโค้ชของอุทยาน เพื่อนำท่านขึ้นสู่ยอดเขาแทน
                                                  และจะทำการคืนเงินค่าส่วนต่างกระเช้าผู้ใหญ่ท่านละ 40 เหรียญฮ่องกง และเด็กท่านละ 20 เหรียญฮ่องกง
                                              </em>
                                          </li>
                                          <li>จากนั้นให้ท่านอิสระช้อปปิ้ง ที่ห้างดัง <strong>CITYGATE OUTLET MALL</strong> ให้ท่านช้อปปิ้งสินค้าตามอัธยาศัย กับ OUTLET สินค้าแบรนด์เนมระดับโลกมากมาย</li>
                                      </ul>

                                      <p><strong>ค่ำ</strong></p>

                                      <ul>
                                          <li><strong>บริการอาหารค่ำ ณ ภัตตาคาร </strong></li>
                                          <li>จากนั้นนำท่านเดินทาง ผ่าน<strong>สะพานชิงหม่า</strong> ติดอันดับเป็นสะพานแขวนที่มีช่วงกลางยาวที่สุดเป็นอันดับ 2 ของโลก</li>
                                          <li><strong>ที่พัก SILKA TSUEN WAN HOTEL หรือเทียบเท่า</strong></li>
                                      </ul>
                                  </div>" />
                                    <TimeLineCard day="2" places="วัดแชกงหมิว | โรงงานจิวเวอร์รี่ | ร้านหยกฮ่องกง | วัดหวังต้าเซียน | วัดหมั่นโหม่ | วิคตอเรียพีค | รีพัลส์เบย์ | องค์เจ้าแม่กวนอิมรีพัสเบย์ | สะพานต่ออายุ | ดิวตี้ฟรี | จิมซาจุ่ย | A SYMPHONY OF LIGHTS"
                                    detail="<div >
                                    <p><strong>เช้า</strong></p>

                                    <ul>
                                        <li><strong>บริการอาหารเช้า ณ ภัตตาคาร บริการท่านด้วยติ่มซำขึ้นชื่อของฮ่องกง อาทิ ฮะเก๋า, ขนมจีบกุ้ง, ก๋วยเตี๋ยวหลอด, โจ๊ก เป็นต้น</strong></li>
                                        <li>
                                            เดินทางสู่ <strong>วัดแชกงหมิว</strong> ให้ท่านได้จุดธูปขอพร สักการะเทพเจ้าแชกง ให้ท่านได้ตั้งจิตอธิษฐานด้านหน้าองค์เจ้าพ่อแชกง จากนั้นให้ท่านหมุนกังหันทองแดงจะมีกังหัน 4
                                            ใบพัด แต่ละใบหมายถึง เดินทางปลอดภัย, สุขภาพแข็งแรง, โชคลาภ และเงินทอง เชื่อกันว่าหากหมุนครบ 3 รอบและตีกลอง 3 ครั้ง จะช่วยปัดเป่าสิ่งชั่วร้ายออกไป และนำพาสิ่งดีๆเข้ามา
                                        </li>
                                        <li>นำท่านเยี่ยมชม <strong>โรงงานจิวเวอร์รี่</strong> ที่ขึ้นชื่อของฮ่องกงพบกับงานดีไชน์ที่ได้รับรางวัลอันดับเยี่ยม และใช้ในการเสริมดวงเรื่องฮวงจุ้ย</li>
                                        <li>
                                            นำท่านชม<strong> ร้านหยก</strong> มีสินค้ามากมายเกี่ยวกับหยก อาทิ กำไลหยก หรือสัตว์นำโชคอย่างปี่เสี่ยะ อิสระให้ท่านได้เลือกชื้อเป็นของฝาก หรือเป็นของที่ระลึก
                                            หรือนำโชคแด่ตัวท่านเอง
                                        </li>
                                        <li>
                                            นำท่านเดินทางสู่ <strong>วัดหวังต้าเซียน</strong> (คนจีนกวางตุ้ง จะเรียกวัดนี้ว่า หว่องไท่ซิน) เป็นวัดเก่าแก่อายุกว่าร้อยปี
                                            โดยชาวฮ่องกงส่วนใหญ่เดินทางมาวัดนี้เพื่อขอพรให้สุขภาพแข็งแรง ไร้โรคภัย ซึ่งวัดแห่งนี้เป็นที่ประดิษฐานของเทพเจ้าจีนหลายองค์อย่างเทพเจ้าหลักของวัดคือเทพหวังต้าเซียน
                                        </li>
                                    </ul>

                                    <p><strong>บ่าย</strong></p>

                                    <ul>
                                        <li><strong>บริการอาหารกลางวัน ณ ภัตตาคาร พิเศษ!! เมนูห่านย่าง</strong></li>
                                        <li>
                                            จากนั้นนำท่านเข้าสู่ <strong>วัดหมั่นโหม่</strong> เป็นวัดที่มีความสวยงามดุจภาพวาด เป็นสถานบูชาหมั่นโหม่ที่ใหญ่ที่สุดใน ฮ่องกง
                                            การได้หยุดพักอย่างสงบใต้ขดธูปขนาดใหญ่ที่แขวนเรียงราย อยู่เบื้องบนนั้น ถือเป็นความสุขในอีกมุมหนึ่ง
                                        </li>
                                        <li>
                                            จากนั้นนำท่านเดินทางขึ้นชมวิวเกาะฮ่องกง โดยรถโค้ช บน<strong>ยอดเขาวิคตอเรียพีค</strong> ทุกท่านจะได้สัมผัสบรรยากาศบริสุทธิ์สดชื่น สามารถชมทัศนียภาพอันงดงามของ
                                            เกาะฮ่องกงและเกาลูนได้ทั้งเกาะอย่างชัดเจน จุดชมวิวที่ดีที่สุดและห้ามพลาดเมื่อมา The Peak สามารถมองเห็นวิวได้รอบทิศทั้ง 360 องศา
                                            จุดนี้สามารถมาชมวิวเมืองฮ่องกงได้ทั้งวันซึ่งในแต่ละช่วงเวลาก็จะมีวิวที่สวยงามแตกต่างกันไป วิวที่มองเห็นก็จะเป็นวิวของตึกและอาคารสูงระฟ้าของฮ่องกง
                                            ที่ก่อสร้างตรงตามหลักฮวงจุ้ย
                                        </li>
                                        <li>
                                            จากนั้นนำท่านนมัสการขอพรสิ่งศักดิ์สิทธิ์เพื่อเป็นสิริมงคลที่บริเวณ<strong>ชายหาดรีพัลส์เบย์</strong> โดยเชื่อว่า ณ บริเวณหาดรีพลัสเบย์
                                            เป็นจุดที่มีฮวงจุ้ยที่ดีที่สุดของเกาะฮ่องกง นำท่านเริ่มขอพร <strong>เจ้าแม่กวนอิม</strong>
                                        </li>
                                        <li>
                                            จากนั้นนำท่านข้าม <strong>สะพานต่ออายุ </strong>ซึ่งเชื่อกันว่าข้ามหนึ่งครั้งจะมีอายุเพิ่มขึ้น 3 ปี หรือคนโสด จะเลือกขอคู่ หรือขอพรเรื่องความรัก จากเทพเจ้าแห่งความรักก็ได้
                                        </li>
                                        <li>จากนั้นขอพรเรื่องการเดินทาง ให้เดินทางปลอดภัยจาก เจ้าแม่ทับทิม จากนั้นให้ท่านได้รับ พลังจาก<strong>ศาลาแปดทิศ</strong> ซึ่งถือว่าเป็นจุดรวมรับพลังที่ดีที่สุดของฮ่องกง</li>
                                        <li>
                                            จากนั้นนำท่าน ช้อปปิ้งสินค้าปลอดภาษี ณ <strong>DUTY FREE</strong> ให้ท่านได้เลือกซื้อสินค้าแบรนเนมต่างๆ จากนั้นอิสระให้ท่านเต็มอิ่มกับการช้อปปิ้งย่านจิมซาจุ่ย
                                            และสินค้าแบบที่เป็นของพื้นเมืองฮ่องกงอยู่ด้วยและตามซอกตึกอันซับซ้อนมากมายมี SHOPPING COMPLEX ขนาดใหญ่ชื่อ
                                            <strong>OCEAN TERMINAL</strong> ซึ่งประกอบไปด้วยห้างสรรพสินค้าเรียงรายกันอยู่ และมีทางเชื่อมติดต่อกันสามารถเดินทะลุถึงกันได้ ให้แบรนด์ดังมากมายให้ท่านได้เลือกซื้อ
                                        </li>
                                    </ul>

                                    <p><strong>ค่ำ</strong></p>

                                    <ul>
                                        <li><strong>*อิสระอาหารเย็นตามอัธยาศัย*</strong></li>
                                        <li>จากนั้นนำท่านชม <strong>A SYMPHONY OF LIGHTS</strong> ความมหัศจรรย์เริ่มต้นในเวลา 20.00 น. ทุกค่ำคืน</li>
                                        <li><strong>พักที่ SILKA TSUEN WAN HOTEL หรือเทียบเท่า</strong></li>
                                    </ul>
                                </div>
                                " />
                                    <TimeLineCard day="3" places="วัดเจ้าแม่กวนอิมฮองฮัม | ถนนฮอลลีวูดฮ่องกง | ท่าอากาศยานฮ่องกงเช๊กแลปก๊ก | ท่าอากาศยานสุวรรณภูมิ"
                                detail=" <div >
                                <p><strong>เช้า</strong></p>

                                <ul>
                                    <li><strong>บริการอาหารเช้า ณ ห้องอาหารของโรงแรม</strong></li>
                                    <li>
                                        จากนั้นนำท่านไหว้ <strong>เจ้าแม่กวนอิมฮ่องฮำ (Kun Im Temple Hung Hom)</strong> เป็นวัดเจ้าแม่กวนอิมที่มีชื่อเสียงแห่งหนึ่งในฮ่องกง
                                        ขอพรเจ้าแม่กวนอิมพระโพธิสัตว์แห่งความเมตตา เป็นวัดเก่าแก่ของฮ่องกง ถึงแม้จะเป็นวัดขนาดเล็กที่ไม่ได้สวยงามมากมาย แต่เป็นวัดเจ้าแม่กวนอิมที่ชาวฮ่องกงนับถือมาก
                                        แทบทุกวันจะมีคนมาบูชาขอพรกันแน่นวัด
                                    </li>
                                    <li>
                                        นำท่านเดินทางสู่ <strong>อะเวนิว ออฟ สตาร์ส Avenue of Stars</strong> ที่มีการประทับตราลายมือดาราที่เคยประทับไว้ที่บริเวณพื้น ย้ายมาไว้บนขอบราวจับเลียบทางเดินริมน้ำตลอดแนว
                                        เพื่อให้ผู้ชมได้เห็นชัด และถ่ายรูปคู่กับรอยพิมพ์ดาราโดยมีวิวตึกริมอ่าววิคตอเรียเป็นฉากพร้อมเพลิดเพลินถ่ายรูปกับวิวสุดอลังการของอ่าววิคตอเรีย
                                    </li>
                                    <li>สมควรแก่เวลานำท่านเดินทางสู่<strong>สนามบิน</strong></li>
                                </ul>

                                <p><strong>บ่าย</strong></p>

                                <ul>
                                    <li><strong>14.10 น. </strong>ออกเดินทางกลับกรุงเทพ โดยสายการบินไทยสไมล์แอร์เวย์ <strong>เที่ยวบินที่ WE631</strong> <strong>(บริการอาหารบนเครื่อง)</strong></li>
                                    <li><strong>15.50 น. </strong>ถึง<strong>สนามบินสุวรรณภูมิ</strong>โดยสวัสดิภาพ</li>
                                </ul>

                                <p></p>

                                <p></p>
                            </div>
                        </div>"/>
                                </div>
                            </div>
                        </div>
                        <div data-v-27b20e16="" className="pg_section-information__meal-container mb-5">
                            <div data-v-27b20e16="" className="pg_section-information__title mb-1">มื้ออาหาร</div>
                            <div data-v-fa446876="" data-v-27b20e16="" className="pg_table-detail pg_section-information__table-meal">
                                <table data-v-fa446876="">
                                    <thead data-v-fa446876="">
                                        <tr data-v-fa446876="">
                                            <th data-v-27b20e16="" data-v-fa446876="">วันที่</th>
                                            <th data-v-27b20e16="" data-v-fa446876="">เช้า</th>
                                            <th data-v-27b20e16="" data-v-fa446876="">เที่ยง</th>
                                            <th data-v-27b20e16="" data-v-fa446876="">เย็น</th>
                                        </tr>
                                    </thead>
                                    <tbody data-v-fa446876="">
                                        <tr data-v-27b20e16="" data-v-fa446876="">
                                            <td data-v-27b20e16="" data-v-fa446876="">1</td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="">
                                                 <i className="fas fa-utensils pg_section-information__meal-icon--none"></i>
                                                </td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__meal-icon--normal"> <i className="fas fa-utensils"></i></td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className=""> <i className="fas fa-utensils pg_section-information__meal-icon--normal"></i></td>
                                        </tr>
                                        <tr data-v-27b20e16="" data-v-fa446876="">
                                            <td data-v-27b20e16="" data-v-fa446876="">2</td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__meal-icon--normal"> <i className="fas fa-utensils "></i></td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__meal-icon--normal"> <i className="fas fa-utensils "></i></td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__meal-icon--free"> <i className="fas fa-utensils "></i></td>
                                        </tr>
                                        <tr data-v-27b20e16="" data-v-fa446876="">
                                            <td data-v-27b20e16="" data-v-fa446876="">3</td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__meal-icon--normal"> <i className="fas fa-utensils "></i></td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__meal-icon--none"> <i className="fas fa-utensils "></i></td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__meal-icon--none"> <i className="fas fa-utensils "></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div data-v-27b20e16="" className="pg_section-information__meal-description">
                                <div data-v-27b20e16="" className="pg_section-information__meal-description-item">
                                    <div data-v-27b20e16="" className="pg_section-information__meal-icon--normal">
                                    <i className="fas fa-utensils "></i>
                                    </div>
                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-content">
                                        <div data-v-27b20e16="" className="pg_section-information__meal-description-title">
                                            มื้อเพื่อคุณ
                                        </div>
                                        <div data-v-27b20e16="" className="pg_section-information__meal-description-subtitle">
                                            มื้อนี้รวมในค่าทัวร์แล้ว
                                        </div>
                                    </div>
                                </div>
                                <div data-v-27b20e16="" className="pg_section-information__meal-description-item">
                                    <div data-v-27b20e16="" className="pg_section-information__meal-icon--free">
                                    <i className="fas fa-utensils "></i>
                                    </div>
                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-content">
                                        <div data-v-27b20e16="" className="pg_section-information__meal-description-title">
                                            มื้ออิสระ
                                        </div>
                                        <div data-v-27b20e16="" className="pg_section-information__meal-description-subtitle">
                                            หาทานได้ตามใจคุณ
                                        </div>
                                    </div>
                                </div>
                                <div data-v-27b20e16="" className="pg_section-information__meal-description-item">
                                    <div data-v-27b20e16="" className="pg_section-information__meal-icon--none">
                                      <i className="fas fa-utensils "></i>
                                    </div>
                                    <div data-v-27b20e16="" className="pg_section-information__meal-description-content">
                                        <div data-v-27b20e16="" className="pg_section-information__meal-description-title">
                                            ไม่มีมื้ออาหาร
                                        </div>
                                        <div data-v-27b20e16="" className="pg_section-information__meal-description-subtitle">
                                            มื้อนี้อยู่นอกเวลาทัวร์
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-27b20e16="" className="pg_section-information__hotel-container">
                            <div data-v-27b20e16="" className="pg_section-information__title mb-1">โรงแรมและที่พัก</div>
                            <div data-v-fa446876="" data-v-27b20e16="" className="pg_table-detail pg_section-information__table-hotel">
                                <table data-v-fa446876="">
                                    <thead data-v-fa446876="">
                                        <tr data-v-fa446876="">
                                            <th data-v-27b20e16="" data-v-fa446876="">วันที่</th>
                                            <th data-v-27b20e16="" data-v-fa446876="">โรงแรมและที่พัก</th>
                                            <th data-v-27b20e16="" data-v-fa446876="">ระดับ</th>
                                        </tr>
                                    </thead>
                                    <tbody data-v-fa446876="">
                                        <tr data-v-27b20e16="" data-v-fa446876="">
                                            <td data-v-27b20e16="" data-v-fa446876="">1</td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__hotel-name">
                                                SILKA TSUEN WAN HOTEL หรือเทียบเท่า
                                            </td>
                                            <td data-v-27b20e16="" data-v-fa446876="">
                                                <div data-v-2466db0a="" data-v-27b20e16="" className="pg_rating pg_section-information__rating" data-v-fa446876="">
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i><i data-v-2466db0a="" className="pg_rating__star fas fa-star"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star"></i>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr data-v-27b20e16="" data-v-fa446876="">
                                            <td data-v-27b20e16="" data-v-fa446876="">2</td>
                                            <td data-v-27b20e16="" data-v-fa446876="" className="pg_section-information__hotel-name">
                                                SILKA TSUEN WAN HOTEL หรือเทียบเท่า
                                            </td>
                                            <td data-v-27b20e16="" data-v-fa446876="">
                                                <div data-v-2466db0a="" data-v-27b20e16="" className="pg_rating pg_section-information__rating" data-v-fa446876="">
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star pg_rating__star--yellow"></i><i data-v-2466db0a="" className="pg_rating__star fas fa-star"></i>
                                                    <i data-v-2466db0a="" className="pg_rating__star fas fa-star"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div data-v-0348ea44="" id="benefits" className="pg_tour-detail-content__section mb-3">
                    <div data-v-0348ea44="" className="pg_tour-detail-content__title">ทัวร์นี้รวมอะไร</div>
                    <div data-v-27b20e16="" data-v-0348ea44="" className="pg_section-information">
                    <div data-v-27b20e16="" className="pg_section-information__benefits-container container">
                            <div data-v-fa446876="" data-v-27b20e16="" className="pg_tour-benefits ">
                                    <div data-v-668c122e="" className="row">
                                        <div data-v-668c122e="" className="pg_benefits-incould col-12 col-lg-6" >
                                            <div data-v-668c122e="" className="row no-gutters pg_tour-benefits__slide-container pg_section-benefits">
                                               <div data-v-27b20e16="" className="pg_section-benefits__title mb-1 col-12">รวมอยู่ในทัวร์นี้</div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-plane"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    ตั๋วเครื่องบินไป-กลับ
                                                    </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-bed"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    ห้องพัก
                                                    </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-suitcase"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    น้ำหนักกระเป๋า 20 กก
                                                    </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-utensils"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    มื้ออาหาร
                                                    </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-ticket-alt"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                ตั๋วเข้าชมสถานที่ ตามที่ระบุ
                                                    </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-bus"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                รถนำเที่ยว
                                                    </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-stamp"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                ค่าวีซ่า
                                                    </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-user-shield"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                ประกันอุบัติเหตุ
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                        <div data-v-668c122e=""  className="pg_benefits-excould col-12 col-lg-6">
                                            <div data-v-668c122e="" className="row no-gutters pg_tour-benefits__slide-container pg_section-benefits">
                                                <div data-v-27b20e16="" className="pg_section-benefits__title mb-1 col-12">ไม่ได้รวมอยู่ในทัวร์นี้</div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-ticket-alt"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                ตั๋วเข้าชมสถานที่ นอกจากที่ระบุ
                                                </div>
                                                </div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-utensils"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                มื้ออาหารอิสระ                            
                                                </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-money-bill-alt"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    ค่าใช้จ่ายส่วนตัว                               
                                                </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-passport"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    ค่าพาสปอร์ต
                                                </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-money-bill-wave"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    ทิปไกต์
                                                </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-user-md"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    ค่าประกันสุขภาพ
                                                </div>
                                                </div> 
                                                <div data-v-32f156cd="" className="pg_section-benefits__box">
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-icon"><i className="fas fa-receipt"></i></div>
                                                <div data-v-32f156cd="" className="pg_section-benefits__box-title">
                                                    Vat 7% (หากต้องการใบกำกับภาษี)
                                                </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-v-0348ea44="" id="period" className="pg_tour-detail-content__section mb-3">
                    <div data-v-0348ea44="" className="pg_tour-detail-content__title">ทัวร์นี้ไปเมื่อไร</div>
                    <div data-v-e5314ee4="" data-v-0348ea44="" className="pg_section-period">
                        <div data-v-e5314ee4="" className="my-3 swiper-container swiper-container-initialized swiper-container-horizontal">
                            <div data-v-e5314ee4="" className="swiper-wrapper" style={{transform: "translate3d(0px, 0px, 0px)"}}>
                                <div data-v-e5314ee4="" className="pg_section-period__slide-item swiper-slide swiper-slide-active" style={{marginRight: "16px"}}>
                                    <button data-v-e5314ee4="" className="pg_section-period__slide-button pg_button pg_button--color-secondary pg_button--size-md is-type-text">
                                        <span className="pg_button__ripple-container"></span>
                                        <span className="pg_button__container">
                                            <span className="pg_button__text">
                                                ก.ค.
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                        </div>
                        <div data-v-e5314ee4="" className="animated faster fadeIn" >
                            <div data-v-fa446876="" data-v-e5314ee4="" className="pg_table-detail">
                                <table data-v-fa446876="">
                                    <thead data-v-fa446876="">
                                        <tr data-v-fa446876="">
                                            <th data-v-e5314ee4="" data-v-fa446876="">ช่วงเดินทาง</th>
                                            <th data-v-e5314ee4="" data-v-fa446876="">ผู้ใหญ่</th>
                                            <th data-v-e5314ee4="" data-v-fa446876="">เด็ก</th>
                                            <th data-v-e5314ee4="" data-v-fa446876=""></th>
                                        </tr>
                                    </thead>
                                    <tbody data-v-fa446876="">
                                        <tr data-v-e5314ee4="" data-v-fa446876="">
                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                <div data-v-c49495fc="" data-v-e5314ee4="" data-variant="2022-07-13" className="pg_period-date" data-v-fa446876="">
                                                    <div data-v-c49495fc="" className="pg_period-date__long-holiday"><span data-v-c49495fc="" className="pg_text-black-3">ไม่ติดวันหยุด</span></div>
                                                    <div data-v-c49495fc="" className="pg_period-date__date-container">
                                                        <div data-v-c49495fc="" className="pg_period-date__date pg_period-date__date--left">
                                                            พ. 13 ก.ค. 65
                                                        </div>
                                                        <div data-v-c49495fc="" className="pg_period-date__next-date-icon"></div>
                                                        <div data-v-c49495fc="" className="pg_period-date__date pg_period-date__date--right">
                                                            ศ. 15 ก.ค. 65
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-v-521ec37a="" data-v-e5314ee4="" className="pg_remaining-seat d-flex" data-v-fa446876=""></div>
                                            </td>
                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                    
                                                    <div data-v-155a0a98="" className="pg_period-price__price">
                                                        13,999
                                                        
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                <div data-v-155a0a98="" data-v-e5314ee4="" className="pg_period-price" data-v-fa446876="">
                                                    
                                                    <div data-v-155a0a98="" className="pg_period-price__price">
                                                        12,999
                                                        
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-v-e5314ee4="" data-v-fa446876="">
                                                <button data-v-e5314ee4="" className="pg_section-period__select-button pg_button pg_button--color-primary pg_button--size-sm is-type-text" data-v-fa446876="">
                                                    <span className="pg_button__ripple-container"></span>
                                                    <span className="pg_button__container">
                                                        <span className="pg_button__text">
                                                            เลือก
                                                        </span>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <a
                            data-v-e5314ee4=""
                            href="https://tourdoc.s3.amazonaws.com/uploads/tour/additional_attachment/12893/tourkrub_12893.pdf?v=1597740865"
                            className="pg_section-period__pdf-btn-link mt-4 pg_button pg_button--color-secondary-outline pg_button--size-md is-type-text"
                            target="_blank"
                        >
                            <span className="pg_button__ripple-container"></span>
                            <span className="pg_button__container">
                                <span className="pg_button__text">
                                    ดาวน์โหลดรายละเอียดทัวร์นี้ (PDF)
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div data-v-0348ea44="" className="col-lg-4"></div>
        </div>
    </div>
</section>

</div>
    );

  }

}