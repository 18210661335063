import React,{Component} from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/th';
import SunEditor,{buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Country from './Pages/Country';
import Province from './Pages/Provices';
import District from './Pages/Districts';
import Place from './Pages/Places';
// install Swiper components
moment().locale('th');
export class PlacesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      country_id: 0,
      country_name: "",
      province_id: 0,
      province_name: "",
      district_id:0,
      district_name:""
    }
  }
  onSelectCountry(i,n) {
    this.setState({country_id: i,country_name: n})
  }
  onSelectProvine(i,n) {
    this.setState({province_id: i,province_name: n})
  }
  onSelectDistrice(i,n) {
    this.setState({district_id: i,district_name: n})
  }
  render() {
    const {country_id,country_name,province_id,province_name,district_id,district_name} = this.state
    return <div>
      <div className="pg_tours-detail">
        <section data-v-0348ea44="" className="">
          <nav data-v-72117e1e="" data-v-0348ea44="" aria-label="breadcrumb" className="mt-3 pg_breadcrumb py-3-fix pg_breadcrumb--border">
            <div data-v-72117e1e="" className="pl-3">
              <ul data-v-72117e1e="" className="pg_breadcrumb__container">
                <li data-v-72117e1e="" aria-current="page" className={"pg_breadcrumb__item " + (country_id == 0 ? "active" : "")}>
                  <a data-v-72117e1e="" onClick={e => this.setState({country_id: 0,province_id: 0,district_id:0})} style={{cursor: "pointer"}} className="is-link-active is-link-prefetched">ประเทศ</a>
                </li>
                {country_id ? <li data-v-72117e1e="" aria-current="page" className={"pg_breadcrumb__item " + (province_id == 0 ? "active" : "")}>
                  {province_id != 0 ?
                    <a data-v-72117e1e="" onClick={e => this.setState({province_id: 0,district_id:0})} style={{cursor: "pointer"}}  className="is-link-active is-link-prefetched">{country_name}</a> : country_name
                  }
                </li> : ""}
                {province_id ? <li data-v-72117e1e="" aria-current="page" className={"pg_breadcrumb__item " + (district_id == 0 ? "active" : "")}>
                  {district_id != 0 ?
                    <a data-v-72117e1e="" onClick={e => this.setState({district_id: 0})} style={{cursor: "pointer"}}  className="is-link-active is-link-prefetched">{province_name}</a> : province_name
                  }
                </li> : ""}
                {district_id ? <li data-v-72117e1e="" aria-current="page" className="pg_breadcrumb__item active">
                  {district_name}
                </li> : ""}
              </ul>
            </div>
          </nav>
        </section>
      </div>
      <div style={{display: country_id > 0 ? "none" : ""}}>
        <Country onSelect={(i,n) => this.onSelectCountry(i,n)} />
      </div>
      <div style={{display: country_id>0&&province_id==0 ? "" : "none"}}>
        <Province countryID={country_id} onSelect={(i,n) => this.onSelectProvine(i,n)} />
      </div>

      <div style={{display: district_id == 0 &&province_id>0 ? "" : "none"}}>
        <District countryID={country_id} provinceID={province_id} onSelect={(i,n) => this.onSelectDistrice(i,n)} />
      </div>

      <div style={{display: district_id > 0 ? "" : "none"}}>
        <Place countryID={country_id} provinceID={province_id} districtID={district_id}  />
      </div>
    </div>
  }
}