import React,{Component} from 'react';


export class Insentive extends Component {
  constructor(props) {
    super(props);
    this.state = {forecasts: [],loading: true};
  }

  componentDidMount() {
  }


  render() {
    return (
      <div className="container_home">
        <div className="container_insentive">
              <div className="form form-insentive col-6 mt-3 mb-3">
                <div className="form-title"><h3> ข้อมูลผู้จอง</h3></div>
                <div className="mb-2">
                  <label className="form-label">ชื่อ - นามสกุล</label>
                  <input className="form-control" id="txt-name" placeholder="ชื่อ - นามสกุล" />
                </div>
                <div className="mb-2">
                  <label className="form-label">เบอร์ติดต่อ</label>
                  <input className="form-control" id="txt-tel" placeholder="เบอร์ติดต่อ" />
                </div>
                <div className="mb-4">
                  <label className="form-label">อีเมล์</label>
                  <input className="form-control" id="txt-email" placeholder="อีเมล์" />
                </div>
                <div className="form-title"> <h3> รายการจัดกรุ๊ปทัวร์</h3> </div>
                <div className="mb-2">
                  <label className="form-label">เลือกประเทศ</label>
                  <input className="form-control" id="txt-country" placeholder="ประเทศ" />
                </div>
                <div className="mb-2">
                  <label className="form-label">เลือกลักษณะกรุ๊ปทัวร์</label>
                  <input className="form-control" id="txt-tel" placeholder="" />
                </div>
                <div className="mb-2">
                  <label className="form-label">งบต่อคน (บาท)</label>
                  <input className="form-control" id="txt-tel" placeholder="" />
                </div>
                <div className="mb-2">
                  <label className="form-label">จำนวนคน</label>
                  <input className="form-control" id="txt-tel" placeholder="" />
                </div>
                <div className="mb-2">
                  <label className="form-label">วันที่จะออกเดินทาง</label>
                  <input className="form-control" id="txt-tel" placeholder="" />
                </div>
                <div className="mb-2">
                  <label className="form-label">จำนวนวัน</label>
                  <input className="form-control" id="txt-tel" placeholder="" />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </div>
        </div>
      </div>
    );
  }
}
