import React,{Component} from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import {Modal} from '../../Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/th';
import SunEditor,{buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
// install Swiper components
moment().locale('th');
export default class DistrictsManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country_id:0,
            province_id:0,
            s_name: "",
            s_catagory: "",
            catagoryList: [],
            districtslist: [],
            loading: true,
            stage:"view",
            id:"",
            name:"",
            catagory:0,
            desc:"",
            imgid:0,
            imgdelete:0,
            imgupdate:0,
            popup:false,
            popupsuccess:false,
            popupdanger:false,
            mdTitle:"ยืนยัน",
            mdMessage:"ต้องการบันทึกใช้หรือไม่",
            mdIcon:"confirm",
            deleteId:""
        }
    }
    componentDidMount() {
       
    }
    componentDidUpdate(prevProps) {
        if (prevProps.provinceID !== this.props.provinceID) {
            this.Getcatagory();
            this.setState({province_id:this.props.provinceID})
            const {s_name,s_catagory} = this.state;
            this.GetDistricts(this.props.provinceID,s_name,s_catagory);
        }
        if(prevProps.countryID != this.props.countryID){
            this.setState({country_id:this.props.countryID})
        }
      }
    Getcatagory() {
        fetch('DataMng/GetDataLookup?LookupType=DISTRICE_CATEGORY',{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        this.setState({catagoryList: json.data}
                        );

                    }.bind(this))
                }

                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                        this.setState({loading: false});
                    });
                }
            }.bind(this))
            .catch(function (error) {
                this.setState({loading: false});
                console.log('Request failed',error);
            });
    }
    GetDistricts(province, name,catagory) {
        if(province>0){ 
        window.scrollTo(0,0)
        this.setState({loading: true});
        fetch('DataMng/GetDistrictsData?province_id='+province+'&name=' + name + '&type=' + catagory,{
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    response.json().then(function (json) {
                        console.log(json);
                        this.setState({districtslist: json});
                        this.setState({loading: false});
                    }.bind(this))
                }
                else {
                    response.text().then(function (text) {
                        console.log('error ',text);
                        this.setState({loading: false});
                    }.bind(this));
                }
            }.bind(this))
            .catch(function (error) {
                this.setState({loading: false});
                console.log('Request failed',error);
            }.bind(this));
        }
    }
    _handleSubmit(e) {
        e.preventDefault();
        // TODO: do something with -> this.state.file
        this.upload.click();


    }
    
    _handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            if (reader.result) {
                var schema = {...this.state}
                    schema.coverdisplay = reader.result;
                    schema.coverpath = file;
                    schema.imgupdate = schema.imgcover?1:0;
                    schema.imgdelete =0;
                this.setState(schema);
            }
        }
        if (file) {
            reader.readAsDataURL(file)
        }
    }
    _handleImgDelete(e){
        e.preventDefault();
        this.setState({coverdisplay:"",coverpath:""})
       if(this.state.imgcover != "")
       {
           this.setState({imgdelete:"1"})
       }
    }
    handleChangeSearch(e) {
        var schema = {...this.state}
        var pList = e.currentTarget.name.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }
        schema[pList[len - 1]] = e.currentTarget.value;


        this.setState(schema,() => {
            const {province_id, s_name,s_catagory} = this.state;
            this.GetDistricts(province_id,s_name,s_catagory);
        });


    }
    handleChange(e) {
        var schema = {...this.state}
        var pList = e.currentTarget.name;
        schema[e.currentTarget.name] = e.currentTarget.value;
        this.setState(schema);
    }
    NewData(e){
        e.preventDefault();
        this.setState({stage:"new",
        id:0,
        name:"",
        catagory:0,
        desc:"",
        imgcover:"",
        imgid:0,
        imgdelete:0,
        imgupdate:0,
        coverpath:"",
        coverdisplay:""})
    }
    EditData(e,id){
        e.preventDefault();
        var c = this.state.districtslist.find(f=>f.id==id)
        this.setState({stage:"edit",
                              id:id,
                            name:c.name,
                       catagory:c.type_id,
                            desc:c.desc,
                        imgcover:c.images.length>0?c.images[0].path:"",
                           imgid:c.images.length>0?c.images[0].id:0,
                        imgdelete:0,
                        imgupdate:0,
                       coverpath:"",
                       coverdisplay:""
                    })
    }
    OnSaveDelete(e,id){
        e.preventDefault();
        this.setState({popupdelete: true,mdMessage: "ต้องการลบใช่หรือไม่?",mdTitle: "ยืนยัน",mdIcon: "confirm",deleteId:id})
    }
    DeleteData(){
      fetch('DataMng/DeleteDistrict?id='+this.state.deleteId,{
        method:'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
         
      }).then(function(resp){
        if(resp.ok){
            this.setState({popupsuccess:true},this.GetDistricts( this.state.province_id,this.state.s_name,this.state.s_catagory));
        }
      }.bind(this))
      .catch(function (error) {
          console.log('Request failed',error);
      });
    }
    OnSaveData(e)
    {
        e.preventDefault();
        this.setState({popup:true,mdMessage:"ต้องการบันทึกใช่หรือไม่?",mdTitle:"ยืนยัน",mdIcon:"confirm"})
    }
    SaveData(){
        this.setState({loading:true});
        var data = JSON.stringify({
         id:this.state.id,
         name:this.state.name,
         desc:this.state.desc,
         province_id:this.state.province_id,
         type_id:this.state.catagory
        });
        fetch('DataMng/ManagementDistrict',{
            method: 'post',
            body: data,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(function (resp) {
                if (resp.ok) {
                    resp.json().then(function (resp_json) {
                        if(resp_json.id==0){
                            if (this.state.coverpath || this.state.imgdelete==1) {
                                var formData = new FormData();
                                formData.append("path",this.state.coverpath);
                                formData.append("existing_path",this.state.imgcover);
                                formData.append("place_id",this.state.country_id+"/"+this.state.province_id+"/"+resp_json.desc);
                                formData.append("id",this.state.imgid)
                                formData.append("type",3);
                                formData.append("name",this.state.name);
                                formData.append("desc",this.state.name);
                                formData.append("isdelete",this.state.imgdelete);
                                formData.append("isupdate", this.state.imgupdate);
                                formData.append("index",1);
                                fetch('upload/PlaceImage',{
                                    method: 'POST',
                                    body: formData,
                                    headers: {
                                    }
                                })
                                    .then(function (response) {
                                        if (response.ok) {
                                            this.setState({popupsuccess:true},this.GetDistricts( this.state.province_id,this.state.s_name,this.state.s_catagory));
                                        }
                                        else {
                                            response.text().then(function (text) {
                                                console.log('error ',text);
                                            });
                                        }
                                    }.bind(this))
                                    .catch(function (error) {
                                        console.log('Request failed',error);
                                    });
                            }
                            else{
                                this.setState({popupsuccess:true},this.GetDistricts( this.state.province_id,this.state.s_name,this.state.s_catagory));

                            }
                            
                        }
                        
                    }.bind(this))
                }

                else {
                    resp.text().then(function (text) {
                        console.log('error ',text);

                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.log('Request failed',error);
            });
    }
    render() {
        const {s_name,s_catagory,loading,districtslist,stage,name,catagory,desc} = this.state;
        return (
            <div style={{minHeight: "100vh"}} className="pg_tours-detail">
                
                 <Modal icon={this.state.mdIcon} show={this.state.popup} title={this.state.mdTitle} message={this.state.mdMessage} onClickCancel={e=>{this.setState({popup:false})}} onClickConfirm={e=>{this.setState({popup:false},this.SaveData())}}/>
                 <Modal icon={this.state.mdIcon} show={this.state.popupdelete} title={this.state.mdTitle} message={this.state.mdMessage} onClickCancel={e=>{this.setState({popupdelete:false})}} onClickConfirm={e=>{this.setState({popupdelete:false},this.DeleteData())}}/>
                 <Modal icon={"success"} show={this.state.popupsuccess} title={"ผลการทำงาน"} message={"บันทึกข้อมูลสำเร็จ"} onClickConfirm={e=>{this.setState({popupsuccess:false,stage:"view"})}}/>
                 <Modal icon={"danger"} show={this.state.popupdanger} title={"พบข้อผิดพลาด"} message={this.state.mdErrorMessage} onClickConfirm={e=>{this.setState({popupdanger:false})}}/>
                <section className="panal-package-list">

                    <div className="panal-search-tool">
                        <div className="row align-items-center">
                            <div className="col-4">
                                <div className="input-group">
                                    <input type="text" id="txt_search_name" className="form-control pg_input" placeholder="NAME" value={s_name ? s_name : ""} name="s_name" onChange={e => this.handleChangeSearch(e)} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="input-group">
                                    <select id="ddl_search_districts" value={s_catagory ? s_catagory : "0"} name="s_catagory" onChange={e => this.handleChangeSearch(e)} className="form-control pg_input">
                                        <option key={0} value={"0"} >Search by catagory</option>
                                        {
                                            this.state.catagoryList.map((item) => (
                                                <option key={item.id} value={item.id} >{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-2">
                                <button data-v-0348ea44="" onClick={e => this.NewData(e)} className="pg_button--color-secondary pg_button--size-md is-type-text">
                                    <span className="pg_button__ripple-container"></span>
                                    <span className="pg_button__container">
                                        <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-plus" ></i></span>
                                        <span className="pg_button__text" >
                                            เพิ่ม
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="panal-list">
                        {stage!="view"?
                        <div className="package-card pg_app col-3 mb-4" style={{padding: "0"}}  >
                             {loading? 
                            <div style={{position: "absolute",height:"100%",width: "100%",paddingTop:"60%",backgroundColor:"#f8f9fa7a",zIndex: "100",textAlign: "center"}}> 
                              <ReactLoading type={"spin"} color={"#bb2d24"} height={100} width={100} className="loadding"></ReactLoading>
                            </div>:null
                             }
                            <div className="package-card-img" onClick={(e) => this._handleSubmit(e)} >
                                <img src={this.state.imgdelete !=1?(this.state.coverdisplay ? this.state.coverdisplay 
                                                                                            : (this.state.imgcover ? this.state.imgcover 
                                                                                                                   : "https://antstoragedata.blob.core.windows.net/webimage/importClick.png"))
                                                                  :"https://antstoragedata.blob.core.windows.net/webimage/importClick.png" } alt="" />
                                
                            </div>{
                              ( this.state.imgcover && this.state.imgdelete !=1 )|| this.state.coverdisplay  ?
                                <button type="button" className="btn btn-outline-secondary pg_app" style={{position: "absolute",top: "0px",margin: "2px",right: "0"}}onClick={(e) => this._handleImgDelete(e)}><i className="fas fa-trash"></i></button> :null
                            }
                            
                                
                            <div className="UploadBox" style={{display:"none"}}>
                                 <input ref={(ref) => this.upload = ref} className="hidden" type="file" onChange={(e) => this._handleImageChange(e)} />
                            </div>
                            <div className="package-card-container-name">
                                <div className="package-card-position" >{stage=="new"?"Create":"Edit"} districts</div>
                                <div>
                                </div>
                                ...
                            </div>
                            <div className="package-card-info pb-0">
                                <div className="row ">
                                    <div className="col-12 mt-1">
                                        <div className="form-group col-md-12">
                                            <label className="form-label pg_lable text-left" >ชื่อ :</label>
                                            <input type="text" id="txt_Name" value={name} className="form-control pg_input" placeholder="NAME" name="name" onChange={e => this.handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-12">
                                        <div className="form-group col-md-12">
                                            <label className="form-label pg_lable text-left" >ประเภท :</label>
                                            <select id="ddl_search_districts" value={catagory ? catagory : "0"} name="catagory" onChange={e => this.handleChange(e)} className="form-control pg_input">
                                               <option key={0} value={"0"} >select catagory</option>
                                                {
                                                    this.state.catagoryList.map((item) => (
                                                        <option key={item.id} value={item.id} >{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-12">
                                        <div className="form-group col-md-12">
                                            <label className="form-label pg_lable text-left" >รายละเอียด :</label>
                                            <textarea type="text" id="txt_Name" className="form-control pg_input" value={desc} placeholder="DESCRIPTION" name="desc"  onChange={e => this.handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 " style={{textAlign: "right"}}>
                                        <div className="form-group col-md-12">
                                            <button data-v-0348ea44="" onClick={e => this.OnSaveData(e)} className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text">
                                                <span className="pg_button__ripple-container"></span>
                                                <span className="pg_button__container">
                                                    <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-save" ></i></span>
                                                    <span className="pg_button__text">
                                                        บันทึก
                                                    </span>
                                                </span>
                                            </button>

                                            <button data-v-0348ea44="" onClick={e => this.setState({stage:"view"})} className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--colo-danger-light-shadow pg_button--size-sm is-type-text ">
                                                <span className="pg_button__ripple-container"></span>
                                                <span className="pg_button__container">
                                                    <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-times" ></i></span>
                                                    <span className="pg_button__text">
                                                        ยกเลิก
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>:(
                            !loading ?
                                districtslist.map((p,index) =>
                                    <div key={index} className="package-card pg_app col-3 mb-4" style={{padding: "0"}}  >
                                        <div className="package-card-img" >
                                            <img src={p.images && p.images.length>0&&p.images[0].path?p.images[0].path:"https://antstoragedata.blob.core.windows.net/webimage/emptyIMG.png"} alt="" />
                                        </div>
                                        <div className="package-card-container-name">
                                            <div className="package-card-position" >{p.name ? p.name : ""}</div>
                                            <div>
                                            </div>
                                            ...
                                        </div>
                                        <div className="package-card-info pb-0 pt-2">

                                            <div className="row">
                                                <div className="col-5 package-card-time-lable text-right pr-0">ประเภท :</div>
                                                <div className="col-7 package-card-time-text text-left">{p.type_name ? p.type_name : ""}</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5 package-card-time-lable text-right pr-0">รายละเอียด :</div>
                                                <div className="col-7 package-card-time-text text-left">{p.desc}</div>
                                            </div>
                                            <div className="row pt-2">
                                                <div className="col-12 " style={{textAlign: "right"}}>
                                                    <div className="form-group col-md-12">

                                                       <button data-v-0348ea44="" onClick={e => this.OnSaveDelete(e,p.id)} className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text">
                                                                    <span className="pg_button__ripple-container"></span>
                                                                    <span className="pg_button__container">
                                                                        <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-trash" ></i></span>
                                                                        <span className="pg_button__text">
                                                                            ลบ
                                                                        </span>
                                                                    </span>
                                                        </button>
                                                        <button data-v-0348ea44="" onClick={e => this.EditData(e,p.id)} className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text">
                                                            <span className="pg_button__ripple-container"></span>
                                                            <span className="pg_button__container">
                                                                <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-edit" ></i></span>
                                                                <span className="pg_button__text">
                                                                แก้ไข
                                                                </span>
                                                            </span>
                                                        </button>
                                                        <button data-v-0348ea44="" onClick={e => this.props.onSelect(p.id,p.name) } className="pg_box-shadow-4 ml-1 mb-1 pg_button pg_button--color-primary-light-shadow pg_button--size-sm is-type-text">
                                                            <span className="pg_button__ripple-container"></span>
                                                            <span className="pg_button__container">
                                                                <span className="pg_button__icon-wrapper"><i className="pg_button__icon fas fa-layer-group" ></i></span>
                                                                <span className="pg_button__text">
                                                                จัดการ
                                                                </span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                (<div style={{position: "absolute",width: "100%",zIndex: "100",textAlign: "center"}}> <ReactLoading type={"spin"} color={"#bb2d24"} height={100} width={100} className="loadding"></ReactLoading></div>)
                        )}
                    </div>
                </section>
            </div>)
    }
}